import { format } from "date-fns";
import Swal from "sweetalert2";
import authService from "./http-service/AuthService";

class Utils {
  getReturn() {
    return "Test";
  }

  setLST(key, data) {
    localStorage.setItem(key, JSON.stringify(data));
  }

  getLST(key) {
    return JSON.parse(localStorage.getItem(key) || "{}") || null;
  }

  showWarning(message) {
    return Swal.fire({
      title: message,
      showDenyButton: true,
      confirmButtonText: "Yes",
      denyButtonText: `Cancel`,
    });
  }

  getFormatValue(value, currencyType) {
    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      // currency: 'USD',
      // currency: 'INR',
      currency: currencyType,
    });

    return formatter.format(value);
  }

  prettyPrice(value) {
    return this.getFormatValue(value, "USD");
  }

  firstLetterToCapital(string) {
    if (string) return string.charAt(0).toUpperCase() + string.slice(1);

    return null;
  }

  getHowLayouts(key) {
    let ls = {};
    if (localStorage) {
      try {
        ls = JSON.parse(localStorage.getItem("howDashboardLayouts")) || {};
      } catch (e) {}
    }
    return ls[key];
  }

  setHowLayouts(key, value) {
    if (localStorage) {
      localStorage.setItem(
        "howDashboardLayouts",
        JSON.stringify({
          [key]: value,
        })
      );
    }
  }

  getDonorLayouts(key) {
    let ls = {};
    if (localStorage) {
      try {
        ls = JSON.parse(localStorage.getItem("donorLayouts")) || {};
      } catch (e) {}
    }
    return ls[key];
  }

  setDonorLayouts(key, value) {
    if (localStorage) {
      localStorage.setItem(
        "donorLayouts",
        JSON.stringify({
          [key]: value,
        })
      );
    }
  }

  getAdminLayouts(key) {
    let ls = {};
    if (localStorage) {
      try {
        ls = JSON.parse(localStorage.getItem("adminLayouts")) || {};
      } catch (e) {}
    }
    return ls[key];
  }

  setAdminLayouts(key, value) {
    if (localStorage) {
      localStorage.setItem(
        "adminLayouts",
        JSON.stringify({
          [key]: value,
        })
      );
    }
  }

  getDDMMYYYY(dateString) {
    if (dateString) {
      const date = new Date(dateString);
      const formattedDate = date.toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      });

      return formattedDate;
    } else {
      return null;
    }
  }

  getDateFnsDDMMYYY(dateString) {
    return format(new Date(dateString), "dd/MM/yyyy");
  }

  getFullName(first, second) {
    if (first && second) {
      return first + " " + second;
    }

    return null;
  }

  removeItem(itemName) {
    localStorage.removeItem(itemName);
  }

  getCurrentUserReferCode() {
    const user = authService.getUserData();
    if (user) {
      return user.refercode;
    }
    return "";
  }
  
  isUserAdmin() {
    const userRole = authService.getUserRole();
    if (userRole === "Admin") {
      return true;
    } else {
      return false;
    }
  }
}

const utils = new Utils();
window["utils"] = utils;
export default utils;
