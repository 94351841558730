import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Filler,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from "chart.js";

import { LinearProgress } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { Bar } from "react-chartjs-2";
import apiService from "services/http-service/ApiService";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
      text: "Donations",
    },
  },
};

export function AdminDonationBarChart() {
  const q = useQuery(["getAdminDashboardDetails"], async () => {
    return await apiService.getAdminDashboardInfo();
  });

  if (!q.data?.barchart) return null;

  const chartData = {
    labels: q.data?.barchart.map((item) => item.date),
    datasets: [
      {
        label: "Donors",
        backgroundColor: "rgba(75,192,192,1)", // Customize the color of the bars
        data: q.data?.barchart.map((item) => item.donors),
      },
    ],
  };

  if (q.isLoading) return <LinearProgress />;
  return <Bar options={options} data={chartData} />;
}
