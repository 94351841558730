const initialState = {
    loader: false,
    lists: [],
    hows_lists: [],
    child_lists: [],
  };
  
  function HowReducer(state = initialState, action) {
    const { type, payload } = action;
  
    switch (type) {
      case "SET_HOW_LOADER":
        return { ...state, loader: action.loader };
      case "RETRIEVE_HOW":
        return { ...state, lists: payload };
      case "RETRIEVE_HOW_FOR_DROPDOWN":
        return { ...state, hows_lists: payload };
      case "RETRIEVE_HOWS_CHILD":
        return { ...state, child_lists: payload };
      default:
        return state;
    }
  }
  export default HowReducer;
  