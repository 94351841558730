import MainLayouts from "Common/Layouts/MainLayouts";
import HowRevenueDonor from "./HowRevenueDonor";
import RevenueFromChildHolyHouses from "./RevenueFromChildHolyHouses";

export default function HowRevenuePage() {
  return (
    <MainLayouts>
      <HowRevenueDonor />
      <RevenueFromChildHolyHouses />
    </MainLayouts>
  );
}
