import { Card, Grid, Typography } from "@mui/material";
import HowDonationCard from "./HowDonationCard";

export default function HowHouseRevenue() {
  return (
    <>
      <Typography variant="h5">House Revenue</Typography>
      <Grid container spacing={2} pt={2}>
        <Grid item xs={12} md={6}>
          <HowDonationCard title="Today Donation" amount="740k" />
        </Grid>
        <Grid item xs={12} md={6}>
          <HowDonationCard title="Current Month Donation" amount="470k" />
        </Grid>
        <Grid item xs={12} md={6}>
          <HowDonationCard title="Total Donation" amount="115k" />
        </Grid>
      </Grid>
    </>
  );
}
