import { Box, CardContent, Typography } from "@mui/material";

const HowDonationCard = (props) => {
  return (
    <CardContent
      sx={{
        textAlign: "center",
        paddingTop: "2rem",
      }}
    >
      <Typography color="inherit" gutterBottom variant="overline">
        {props.title}
      </Typography>
      <Box>
        <Typography color="inherit" variant="h4">
          {props.amount}
        </Typography>
      </Box>
    </CardContent>
  );
};

export default HowDonationCard;
