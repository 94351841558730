import { grey } from "@material-ui/core/colors";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import { useEffect } from "react";
import { getCopyRightFooter, getLoginButton } from "services/RenderUtils";
import { CommonStore } from "services/store/CommonStore/PullStore";
import Logo from "./assets/HolyDomus-Logo-3000-800-T.png";
export default function LandingPage() {
  const darkMode = CommonStore.useState((s) => s.themeMode);
  useEffect(() => {
    document.title = "Holy house dashboard";
  }, []);

  return (
    <Grid
      container
      height={"100vh"}
      sx={{
        position: "fixed",
        overflow: "hidden",
      }}
    >
      <CssBaseline />
      <Grid
        item
        xs={false}
        sm={4}
        md={7}
        sx={{
          backgroundImage:
            "url(https://images.unsplash.com/photo-1676208972443-f6ac603af62c?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8fHx8fHx8MTY3NzU2NzExOA&ixlib=rb-4.0.3&q=80&w=620)",
          height: "100% !important",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "bottom !important",
        }}
      />
      <Grid
        item
        xs={12}
        sm={8}
        md={5}
        sx={{
          backgroundColor: darkMode ? grey[900] : grey[50],
        }}
      >
        <Box
          sx={{
            my: 20,
            mx: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box sx={{ width: "100%" }}>
            <img
              style={{
                display: "block",
                margin: "auto",
                width: "auto",
              }}
              height={80}
              src={Logo}
            />
          </Box>
          <Box
            sx={{
              mt: 3,
            }}
          >
            {getLoginButton({
              link: "/admin-login",
              title: "Admin Login",
            })}
            {getLoginButton({
              link: "/donor-login",
              title: "Donor Login",
            })}
            {getLoginButton({
              link: "/how-login",
              title: "Holy House Login",
            })}
            {getCopyRightFooter()}
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}
