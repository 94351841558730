import { AddBox, Delete, Edit, Visibility } from "@mui/icons-material";
import { Dialog, DialogContent, useMediaQuery } from "@mui/material";
import { useMutation, useQuery } from "@tanstack/react-query";
import DataTable from "Common/DataTable/DataTable";
import { format } from "date-fns";
import { useState } from "react";
import { toast } from "react-toastify";
import utils from "services/Utils";
import apiService from "services/http-service/ApiService";
import { ApiCommonStore } from "services/store/ApiStates/ApiCommonStore";
import mainTheme from "styles/theme";
import AddUpdateChildHow from "./AddUpdateChildHow";
import ViewChildHowInformation from "./ViewChildHowInformation";

export default function ChildHowPage() {
  const [objItem, setObjItem] = useState(undefined);
  const [isView, setIsView] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const isSmallScreen = useMediaQuery(mainTheme.breakpoints.down("md"));

  const deleteAction = useMutation(async (childHowID) => {
    return await apiService.removeChildHow(childHowID);
  });

  const isChildHowListChanged = ApiCommonStore.useState(
    (s) => s.isChildHowListChanged
  );

  const toggle = () => {
    setIsOpen(false);
    setIsAdding(false);
    setIsEdit(false);
    setObjItem(undefined);
  };

  const q = useQuery(["getChildHowList", isChildHowListChanged], async () => {
    return await apiService.getChildHowList();
  });

  const onComplete = () => {
    q.refetch();
  };

  const deleteChildHow = async (childHowID) => {
    try {
      const isValid = await utils.showWarning(
        "Do you want to delete child how?"
      );

      if (isValid.isConfirmed) {
        const result = await deleteAction.mutateAsync(childHowID);
        if (!result.success) {
          toast.error("Error occurred while delete child how!");
          return null;
        }

        if (result.success) {
          toast.success(result.data);
          q.refetch();
        }
      }
    } catch (ex) {
      toast.error(ex.message);
    }
  };

  return (
    <>
      <DataTable
        title="Child How"
        onReload={() => {
          onComplete();
        }}
        isLoading={q.isRefetching || deleteAction.isLoading || q.isLoading}
        isError={q.isError}
        errorMessage={
          q.isError
            ? (q.error && q.error.error && q.error.error.message) ||
              "Some error occurred! Please contact admin."
            : null
        }
        columns={[
          { title: "First Name", field: "User.first_name" },
          { title: "Last Name", field: "User.last_name" },
          { title: "Email", field: "User.email" },
          { title: "House Name", field: "how_name" },
        ]}
        data={(q.data && q.data.data.rows) || []}
        actions={[
          {
            icon: () => {
              return <AddBox />;
            },
            tooltip: "Add Child How",
            isFreeAction: true,
            onClick: () => {
              setIsAdding(true);
            },
          },
          {
            icon: () => {
              return <Visibility color="info" />;
            },
            tooltip: "View More Details",
            onClick: (e, rowData) => {
              setObjItem(rowData);
              setIsView(true);
            },
          },
          {
            icon: () => {
              return <Edit color="primary" />;
            },
            tooltip: "Edit Child How",
            onClick: (e, rowData) => {
              setObjItem(rowData);
              setIsEdit(true);
              setIsAdding(true);
            },
          },
          {
            icon: () => {
              return <Delete color="error" />;
            },
            tooltip: "Delete Child How",
            onClick: (e, rowData) => {
              deleteChildHow(rowData.id);
            },
          },
        ]}
      />
      {isView && (
        <Dialog
          open={isView}
          onClose={() => {
            setIsView(false);
            setObjItem({});
          }}
          maxWidth={"sm"}
          fullWidth
          sx={{
            position: "absolute",
            zIndex: 12000,
          }}
        >
          <DialogContent>
            <ViewChildHowInformation objChildHow={objItem} />
          </DialogContent>
        </Dialog>
      )}

      {isAdding && (
        <Dialog
          open={isAdding}
          onClose={toggle}
          maxWidth={"lg"}
          sx={{
            position: "absolute",
            zIndex: 12000,
          }}
        >
          <DialogContent>
            <AddUpdateChildHow
              isEdit={isEdit}
              objItem={objItem}
              onComplete={onComplete}
              toggle={toggle}
            />
          </DialogContent>
        </Dialog>
      )}
    </>
  );
}
