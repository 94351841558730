import { Card } from "@mui/material";

export default function CardTable(props) {
  return (
    <Card
      className="data-table"
      style={{ height: "100%", overflow: "auto", position: "relative" }}
    >
      {props.children}
    </Card>
  );
}
