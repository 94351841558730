import { AddBox, Delete, Edit, Visibility } from "@mui/icons-material";
import { Dialog, DialogContent, useMediaQuery } from "@mui/material";
import { useMutation, useQuery } from "@tanstack/react-query";
import DataTable from "Common/DataTable/DataTable";
import { useState } from "react";
import { toast } from "react-toastify";
import utils from "services/Utils";
import apiService from "services/http-service/ApiService";
import mainTheme from "styles/theme";
import HowInviteChild from "../ChildHow/HowInviteChild";
import AddUpdateDonor from "./AddUpdateDonor";
import ViewHowDonorInformation from "./ViewHowDonorInformation";

export default function HowAllDonor() {
  const [isView, setIsView] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [objItem, setObjItem] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const isMediumScreen = useMediaQuery(mainTheme.breakpoints.down("md"));

  const toggle = () => {
    setIsOpen(false);
    setIsAdding(false);
    setIsEdit(false);
    setObjItem({});
  };

  const q = useQuery(["getInvitedDonorListByHow"], async () => {
    return await apiService.getInvitedDonorListByHow();
  });

  const deleteAction = useMutation(async (donorID) => {
    return await apiService.deleteHowDonor(donorID);
  });

  const deleteHowDonorRecord = async (donorID) => {
    try {
      const isValid = await utils.showWarning("Do you want to delete Donor?");
      if (isValid.isConfirmed) {
        const result = await deleteAction.mutateAsync(donorID);
        if (!result.success) {
          toast.error("Error occurred while delete donor!");
          return null;
        }

        if (result.success) {
          toast.success(result.data);
          q.refetch();
        }
      }
    } catch (ex) {
      toast.error(ex.message);
    }
  };

  const onComplete = () => {
    q.refetch();
  };

  return (
    <>
      <DataTable
        title="Donors"
        isLoading={q.isRefetching || q.isLoading}
        onReload={() => {
          q.refetch();
        }}
        columns={[
          {
            title: "First Name",
            field: "User.first_name",
          },
          {
            title: "Last Name",
            field: "User.last_name",
          },
          {
            title: "Email",
            field: "User.email",
          },
          {
            title: "Mobile",
            field: "User.mobile",
          },
        ]}
        actions={[
          // {
          //   icon: () => {
          //     return <AddBox />;
          //   },
          //   tooltip: "Invite",
          //   isFreeAction: true,
          //   onClick: () => {
          //     setIsOpen(true);
          //   },
          // },
          {
            icon: () => {
              return <AddBox />;
            },
            tooltip: "Add New",
            isFreeAction: true,
            onClick: () => {
              setIsAdding(true);
            },
          },
          {
            icon: () => {
              return <Visibility color="info" />;
            },
            tooltip: "View More Details",
            onClick: (e, rowData) => {
              setObjItem(rowData);
              setIsView(true);
            },
          },
          {
            icon: () => {
              return <Edit color="primary" />;
            },
            tooltip: "Edit Donor",
            onClick: (e, rowData) => {
              setObjItem(rowData);
              setIsEdit(true);
              setIsAdding(true);
            },
          },
          {
            icon: () => {
              return <Delete color="error" />;
            },
            tooltip: "Delete Donor",
            onClick: (e, rowData) => {
              deleteHowDonorRecord(rowData.donor_id);
            },
          },
        ]}
        data={(q.data && q.data.data) || []}
        options={{
          exportButton: true,
        }}
      />

      {isView && (
        <Dialog
          open={isView}
          onClose={() => {
            setIsView(false);
            setObjItem({});
          }}
          maxWidth={"sm"}
          fullWidth
          sx={{
            position: "absolute",
            zIndex: 12000,
          }}
        >
          <DialogContent>
            <ViewHowDonorInformation objHowDonor={objItem} />
          </DialogContent>
        </Dialog>
      )}

      {isOpen && (
        <Dialog
          open={isOpen}
          onClose={toggle}
          fullWidth
          style={{
            maxWidth: isMediumScreen ? "100%" : "30%",
            display: "block",
            margin: "auto",
            position: "absolute",
            zIndex: 12000,
          }}
        >
          <DialogContent>
            <HowInviteChild toggle={toggle} />
          </DialogContent>
        </Dialog>
      )}

      {isAdding && (
        <Dialog
          open={isAdding}
          onClose={toggle}
          fullWidth
          sx={{
            display: "block",
            margin: "auto",
            maxWidth: "lg",
            overflow: "hidden",
            position: "absolute",
            zIndex: 12000,
          }}
        >
          <DialogContent>
            <AddUpdateDonor
              objItem={objItem}
              onComplete={onComplete}
              toggle={toggle}
              isEdit={isEdit}
            />
          </DialogContent>
        </Dialog>
      )}
    </>
  );
}
