import { API_URL } from "config/Env";
import authService from "./AuthService";
import httpService from "./HttpServices";
/* eslint-disable */

class ApiService {
  async howUserLogin(data) {
    let url = `${API_URL}/how/auth/login`;
    let res = await httpService.post(url, data, {
      Authorization: `Bearer ${authService.getToken()}`,
    });
    return res;
  }

  async AdminUserLogin(data) {
    let url = `${API_URL}/admin/auth/login`;
    let res = await httpService.post(url, data, {
      Authorization: `Bearer ${authService.getToken()}`,
    });
    return res;
  }

  async donorUserLogin(data) {
    let url = `${API_URL}/donor/auth/login`;
    let res = await httpService.post(url, data, {
      Authorization: `Bearer ${authService.getToken()}`,
    });
    return res;
  }

  async adminUserLogin(data) {
    let url = `${API_URL}/admin/auth/login`;
    let res = await httpService.post(url, data, {
      Authorization: `Bearer ${authService.getToken()}`,
    });
    return res;
  }

  async getAllEventForAdmin() {
    let url = `${API_URL}/admin/admin/events`;
    let res = await httpService.get(url, {
      Authorization: `Bearer ${authService.getToken()}`,
    });
    return res;
  }

  async getHowEventList() {
    let url = `${API_URL}/how/event/all`;
    let res = await httpService.get(url, {
      Authorization: `Bearer ${authService.getToken()}`,
    });
    return res;
  }

  async createHowEvent(data) {
    let url = `${API_URL}/how/event`;
    let res = await httpService.post(url, data, {
      Authorization: `Bearer ${authService.getToken()}`,
    });
    return res;
  }

  async createAdminEvent(data) {
    let url = `${API_URL}/admin/admin/createEvent`;
    let res = await httpService.post(url, data, {
      Authorization: `Bearer ${authService.getToken()}`,
    });
    return res;
  }

  async createHowAdmin(io) {
    let url = `${API_URL}/how/create`;
    let res = await httpService.post(url, io, {
      Authorization: `Bearer ${authService.getToken()}`,
    });
    return res;
  }

  async createAdminHow(io) {
    let url = `${API_URL}/admin/how`;
    let res = await httpService.post(url, io, {
      Authorization: `Bearer ${authService.getToken()}`,
    });
    return res;
  }

  async createDonor(io) {
    let url = `${API_URL}/donor/auth/create`;
    let res = await httpService.post(url, io, {
      Authorization: `Bearer ${authService.getToken()}`,
    });
    return res;
  }

  async createAdminDonor(io) {
    let url = `${API_URL}/admin/donor/createDonor`;
    let res = await httpService.post(url, io, {
      Authorization: `Bearer ${authService.getToken()}`,
    });
    return res;
  }

  async createAdminHowDonor(io) {
    let url = `${API_URL}/admin/how/createHowDonor`;
    let res = await httpService.post(url, io, {
      Authorization: `Bearer ${authService.getToken()}`,
    });
    return res;
  }

  async createHowDonor(io) {
    let url = `${API_URL}/how/donor/create`;
    let res = await httpService.post(url, io, {
      Authorization: `Bearer ${authService.getToken()}`,
    });
    return res;
  }

  async updateHowDonor(io) {
    let url = `${API_URL}/how/donor/update/${io.id}`;
    let res = await httpService.put(
      url,
      {
        first_name: io.first_name,
        last_name: io.last_name,
        email: io.email,
        mobile: io.mobile,
      },
      {
        Authorization: `Bearer ${authService.getToken()}`,
      }
    );
    return res;
  }

  async updateEvent(io) {
    let url = `${API_URL}/admin/admin/updateEvent/${io.eventid}`;
    let res = await httpService.put(
      url,
      {
        title: io.title,
        desc: io.desc,
        dateTime: new Date(io.dateTime).toISOString() || new Date(),
        expireIn: io.expireIn,
      },
      {
        Authorization: `Bearer ${authService.getToken()}`,
      }
    );
    return res;
  }

  async updateAdminHowDonor(io) {
    let url = `${API_URL}/admin/donor/update/${io.id}`;
    let res = await httpService.put(
      url,
      {
        first_name: io.first_name,
        last_name: io.last_name,
        email: io.email,
        mobile: io.mobile,
      },
      {
        Authorization: `Bearer ${authService.getToken()}`,
      }
    );
    return res;
  }

  async createAdmin(io) {
    let url = `${API_URL}/admin/auth/register`;
    let res = await httpService.post(url, io, {
      Authorization: `Bearer ${authService.getToken()}`,
    });
    return res;
  }

  async getChildHowList() {
    let url = `${API_URL}/how/child/list`;
    let res = await httpService.get(url, {
      Authorization: `Bearer ${authService.getToken()}`,
    });
    return res;
  }

  async getInvitedHowListByAdmin() {
    let url = `${API_URL}/admin/how/invitedHowList`;
    let res = await httpService.get(url, {
      Authorization: `Bearer ${authService.getToken()}`,
    });
    return res;
  }

  async getAllHowListForAdmin() {
    let url = `${API_URL}/admin/how/list`;
    let res = await httpService.get(url, {
      Authorization: `Bearer ${authService.getToken()}`,
    });
    return res;
  }

  async getInviteChildHowList() {
    let url = `${API_URL}/how/invitationChildHow`;
    let res = await httpService.get(url, {
      Authorization: `Bearer ${authService.getToken()}`,
    });
    return res;
  }

  async inviteChildHow(data) {
    let url = `${API_URL}/how/childInvite`;
    let res = await httpService.post(url, data, {
      Authorization: `Bearer ${authService.getToken()}`,
    });
    return res;
  }

  async removeChildHowInvitation(invitationsId) {
    let url = `${API_URL}/how/invitation/delete/${invitationsId}`;
    let res = await httpService.delete(
      url,
      {},
      {
        Authorization: `Bearer ${authService.getToken()}`,
      }
    );
    return res;
  }

  async removeChildHow(childHowID) {
    let url = `${API_URL}/how/child/delete/${childHowID}`;
    let res = await httpService.delete(
      url,
      {},
      {
        Authorization: `Bearer ${authService.getToken()}`,
      }
    );
    return res;
  }

  async removeAdminHow(childHowID) {
    let url = `${API_URL}/admin/how/child/delete/${childHowID}`;
    let res = await httpService.delete(
      url,
      {},
      {
        Authorization: `Bearer ${authService.getToken()}`,
      }
    );
    return res;
  }

  async removeAdminUser(adminID) {
    let url = `${API_URL}/admin/admin/adminUser/${adminID}`;
    let res = await httpService.delete(
      url,
      {},
      {
        Authorization: `Bearer ${authService.getToken()}`,
      }
    );
    return res;
  }

  async getSuspendedChildHowList() {
    let url = `${API_URL}/how/list`;
    let res = await httpService.get(url, {
      Authorization: `Bearer ${authService.getToken()}`,
    });
    return res;
  }

  async getAdminHowDonorList() {
    let url = `${API_URL}/admin/donor/howDonor`;
    let res = await httpService.get(url, {
      Authorization: `Bearer ${authService.getToken()}`,
    });
    return res;
  }

  async getAdminDonorList() {
    let url = `${API_URL}/admin/donor/`;
    let res = await httpService.get(url, {
      Authorization: `Bearer ${authService.getToken()}`,
    });
    return res;
  }

  async getAdminDonorInvitations() {
    let url = `${API_URL}/admin/invitation?invitationFor=donor`;
    let res = await httpService.get(url, {
      Authorization: `Bearer ${authService.getToken()}`,
    });
    return res;
  }

  async getAllDonorInvitedByHow() {
    let url = `${API_URL}/how/donor/invitedDonorByHow`;
    let res = await httpService.get(url, {
      Authorization: `Bearer ${authService.getToken()}`,
    });
    return res;
  }

  async getInvitationDonorListByHow() {
    let url = `${API_URL}/how/donor/invitationDonorByHow`;
    let res = await httpService.get(url, {
      Authorization: `Bearer ${authService.getToken()}`,
    });
    return res;
  }

  async sendAdminToHowInvitation(data) {
    let url = `${API_URL}/admin/how/invite`;
    let res = await httpService.post(url, data, {
      Authorization: `Bearer ${authService.getToken()}`,
    });
    return res;
  }

  async getInvitationHowListByAdmin() {
    let url = `${API_URL}/admin/how/invitationList`;
    let res = await httpService.get(url, {
      Authorization: `Bearer ${authService.getToken()}`,
    });
    return res;
  }

  async sendHowToDonorInvitation(data) {
    let url = `${API_URL}/how/donor/invite`;
    let res = await httpService.post(url, data, {
      Authorization: `Bearer ${authService.getToken()}`,
    });
    return res;
  }

  async getAllDonorListForHow() {
    let url = `${API_URL}/how/donor/invite`;
    let res = await httpService.post(url, data, {
      Authorization: `Bearer ${authService.getToken()}`,
    });
    return res;
  }

  async getInvitedDonorListByHow() {
    let url = `${API_URL}/how/donor/invitedDonorByHow`;
    let res = await httpService.get(url, {
      Authorization: `Bearer ${authService.getToken()}`,
    });
    return res;
  }

  async getAdminDashboardInfo() {
    let url = `${API_URL}/admin/dashboard`;
    let res = await httpService.get(url, {
      Authorization: `Bearer ${authService.getToken()}`,
    });
    return res.data;
  }

  async getAdminCounts() {
    let url = `${API_URL}/admin/admin/count-report`;
    let res = await httpService.get(url, {
      Authorization: `Bearer ${authService.getToken()}`,
    });
    return res.data;
  }

  async getHowDonorCount() {
    let url = `${API_URL}/how/donor/count`;
    let res = await httpService.put(
      url,
      {},
      {
        Authorization: `Bearer ${authService.getToken()}`,
      }
    );
    return res.data;
  }

  async getTotalHowCounts() {
    let url = `${API_URL}/how/getTotalHowCount`;
    let res = await httpService.get(url, {
      Authorization: `Bearer ${authService.getToken()}`,
    });
    return res.data;
  }

  async deleteDonorInvitationRecord(inviteID) {
    let url = `${API_URL}/how/invitation/delete/${inviteID}`;
    let res = await httpService.delete(
      url,
      {},
      {
        Authorization: `Bearer ${authService.getToken()}`,
      }
    );
    return res;
  }

  async deleteHowDonor(donorID) {
    let url = `${API_URL}/how/donor/delete/${donorID}`;
    let res = await httpService.delete(
      url,
      {},
      {
        Authorization: `Bearer ${authService.getToken()}`,
      }
    );
    return res;
  }

  async deleteAdminHowDonor(donorID) {
    let url = `${API_URL}/admin/donor/delete/${donorID}`;
    let res = await httpService.delete(
      url,
      {},
      {
        Authorization: `Bearer ${authService.getToken()}`,
      }
    );
    return res;
  }

  async deleteAdminEvent(eventID) {
    let url = `${API_URL}/admin/admin/deleteEvents/${eventID}`;
    let res = await httpService.delete(
      url,
      {},
      {
        Authorization: `Bearer ${authService.getToken()}`,
      }
    );
    return res;
  }

  async removeAdminHowInvitation(inviteID) {
    let url = `${API_URL}/admin/how/delete/${inviteID}`;
    let res = await httpService.delete(
      url,
      {},
      {
        Authorization: `Bearer ${authService.getToken()}`,
      }
    );
    return res;
  }

  async removeAdminDonor(donorID) {
    let url = `${API_URL}/admin/donor/deleteDonor/${donorID}`;
    let res = await httpService.delete(
      url,
      {},
      {
        Authorization: `Bearer ${authService.getToken()}`,
      }
    );
    return res;
  }

  async removeHowDonorInvitation(inviteID) {
    let url = `${API_URL}/how/invitation/delete/${inviteID}`;
    let res = await httpService.delete(
      url,
      {},
      {
        Authorization: `Bearer ${authService.getToken()}`,
      }
    );
    return res;
  }

  async createChildHow(io) {
    let url = `${API_URL}/how/invitedChild/create`;
    let res = await httpService.post(url, io, {
      Authorization: `Bearer ${authService.getToken()}`,
    });
    return res;
  }

  async updateChildHow(io) {
    let url = `${API_URL}/how/child/update/${io.id}`;
    let res = await httpService.put(url, io, {
      Authorization: `Bearer ${authService.getToken()}`,
    });
    return res;
  }

  async updateAdminDonor(io) {
    let url = `${API_URL}/admin/donor/updateDonor/${io.id}`;
    let res = await httpService.put(url, io, {
      Authorization: `Bearer ${authService.getToken()}`,
    });
    return res;
  }

  async updateAdminHow(io) {
    let url = `${API_URL}/admin/how/child/update/${io.id}`;
    let res = await httpService.put(url, io, {
      Authorization: `Bearer ${authService.getToken()}`,
    });
    return res;
  }

  async updateAdminUserInfo(io) {
    let url = `${API_URL}/admin/admin/updateAdminUser/${io.id}`;
    let res = await httpService.put(url, io, {
      Authorization: `Bearer ${authService.getToken()}`,
    });
    return res;
  }

  async updateHowProfile(io) {
    let url = `${API_URL}/how/update/howProfile/${io.id}`;
    let res = await httpService.put(url, io, {
      Authorization: `Bearer ${authService.getToken()}`,
    });
    return res;
  }

  async getHowUserProfile() {
    let url = `${API_URL}/how/howProfile/getInfo`;
    let res = await httpService.get(url, {
      Authorization: `Bearer ${authService.getToken()}`,
    });
    return res;
  }

  async forgetHowPassword(io) {
    let url = `${API_URL}/how/auth/forget`;
    let res = await httpService.post(url, io, {
      Authorization: `Bearer ${authService.getToken()}`,
    });
    return res;
  }

  async forgetDonorPassword(io) {
    let url = `${API_URL}/donor/auth/forgetpassword`;
    let res = await httpService.post(url, io, {
      Authorization: `Bearer ${authService.getToken()}`,
    });
    return res;
  }

  async forgetAdminPassword(io) {
    let url = `${API_URL}/admin/auth/forget`;
    let res = await httpService.post(url, io, {
      Authorization: `Bearer ${authService.getToken()}`,
    });
    return res;
  }

  async resetHowPassword(io) {
    let url = `${API_URL}/how/auth/reset`;
    let res = await httpService.post(url, io, {
      Authorization: `Bearer ${authService.getToken()}`,
    });
    return res;
  }

  async resetAdminPassword(io) {
    let url = `${API_URL}/admin/auth/reset`;
    let res = await httpService.post(url, io, {
      Authorization: `Bearer ${authService.getToken()}`,
    });
    return res;
  }

  async resetDonorPassword(io) {
    let url = `${API_URL}/donor/auth/reset`;
    let res = await httpService.post(url, io, {
      Authorization: `Bearer ${authService.getToken()}`,
    });
    return res;
  }

  async getAdminHowParentInfo(parentID) {
    let url = `${API_URL}/admin/how/getParent/${parentID}`;
    let res = await httpService.get(url, {
      Authorization: `Bearer ${authService.getToken()}`,
    });
    return res;
  }

  async getAdminUserList() {
    let url = `${API_URL}/admin/admin/userList`;
    let res = await httpService.get(url, {
      Authorization: `Bearer ${authService.getToken()}`,
    });
    return res;
  }
}

const apiService = new ApiService();
export default apiService;
