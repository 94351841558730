import CachedIcon from "@mui/icons-material/Cached";
import { TablePagination, Typography } from "@mui/material";
import { tableIcons } from "components/ui-components/MaterialTable";
import MaterialTable from "material-table";

export default function DataTable(props) {
  const defaultAction = {
    icon: CachedIcon,
    tooltip: "Reload Data",
    isFreeAction: true,
    onClick: () => {
      if (props.onReload) {
        props.onReload();
      }
    },
  };

  const actions = props.actions
    ? [...props.actions, defaultAction]
    : [defaultAction];

  const getSourceMessage = () => {
    if (props.isError) {
      return (
        <>
          <Typography variant="body1" color={"red"}>
            {props.errorMessage}
          </Typography>
        </>
      );
    }

    return "No records to display";
  };
  return (
    <MaterialTable
      id="my-material-table"
      icons={tableIcons}
      title={props.title}
      columns={props.columns}
      actions={actions}
      isLoading={props.isLoading || props.loading}
      options={{
        emptyRowsWhenPaging: false,
        headerStyle: {
          fontSize: "17px",
          background: "inherit",
          color: "inherit",
        },
        actionsColumnIndex: -1,
        pageSize: 5,
        ...props.options,
      }}
      localization={{
        body: {
          emptyDataSourceMessage: getSourceMessage(),
        },
      }}
      components={{
        Pagination: (props) => (
          <TablePagination
            {...props}
            sx={{
              overflow: "hidden",
              "& > div > p ": {
                margin: 0,
              },
            }}
          />
        ),
      }}
      data={props.data}
    />
  );
}
