import { QueryClient } from "@tanstack/react-query";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchInterval: 1000 * 60 * 60, // 60 Minutes
    },
  },
});

export function clearAllCaches() {
  queryClient.clear();
}

export function clearCache(cacheKey) {
  queryClient.removeQueries(cacheKey);
}

export function refetchQuery(cacheKey) {
  queryClient.refetchQueries(cacheKey);
}
