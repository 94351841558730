import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Filler,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from "chart.js";

import { Box, Card } from "@mui/material";
import Typography from "@mui/material/Typography";
import faker from "faker";
import { Bar } from "react-chartjs-2";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "bottom",
    },
  },
};

const labels = [
  "2012",
  "2013",
  "2014",
  "2015",
  "2016",
  "2017",
  "2018",
  "2019",
  "2020",
  "2021",
  "2022",
  "2023",
];

export const data = {
  labels,
  datasets: [
    {
      label: "Year",
      data: labels.map(() => faker.datatype.number({ min: 0, max: 10000 })),
      backgroundColor: "rgb(136, 132, 216)",
      fill: true,
    },
  ],
};

export function HowDonorRevenueReport() {
  return (
    <>
      <Typography py={1} variant="h5">
        Yearly Revenue Report
      </Typography>
      <Box
        sx={{
          width: "100%",
          height: "80%",
          margin: "auto",
          "& > .canvas": {
            width: "100% !important",
          },
        }}
      >
        <Bar className="canvas" options={options} data={data} />
        <Typography variant="body1" component={"a"} href="#">
          View Details
        </Typography>
      </Box>
    </>
  );
}
