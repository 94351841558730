import { createTheme } from "@mui/material";
import { CommonStore } from "services/store/CommonStore/PullStore";
const themeMode = CommonStore.getRawState().themeMode;
const mainTheme = createTheme({
  palette: {
    mode: themeMode ? "light" : "dark",
  },
});

export default mainTheme;
