import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Filler,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from "chart.js";

import Typography from "@mui/material/Typography";
import faker from "faker";
import { Bar } from "react-chartjs-2";
import { Box, Card } from "@mui/material";
import GetDashboardCard from "Common/GetDashboardCard";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "bottom",
    },
  },
};

const labels = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const data = {
  labels,
  datasets: [
    {
      label: "Amount",
      data: labels.map(() => faker.datatype.number({ min: 0, max: 10000 })),
      backgroundColor: "rgb(136, 132, 216)",
      fill: true,
    },
  ],
};

export function HowMonthlyRevenueReport() {
  return (
    <>
      <Typography py={1} variant="h5">
        Monthly Revenue Report
      </Typography>
      <Box
        sx={{
          width: "100%",
          height: "80%",
          margin: "auto",
          "& > .canvas": {
            width: "100% !important",
          },
        }}
      >
        <Bar className="canvas" options={options} data={data} />
        <Typography variant="body1" component={"a"} href="#">
          View Details
        </Typography>
      </Box>
    </>
  );
}
