import BoxTable from "Common/DataTable/BoxTable";
import MainLayouts from "Common/Layouts/MainLayouts";
import AdminAllEvents from "./AdminAllEvents";

export default function AdminEventSection() {
  return (
    <MainLayouts>
      <BoxTable>
        <AdminAllEvents />
      </BoxTable>
    </MainLayouts>
  );
}
