import {
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import { howAdminMenu } from "Common/NavigationMenu";
import { useNavigate } from "react-router";
import { CommonStore } from "services/store/CommonStore/PullStore";

export default function HowAdminSideBar() {
  const navigate = useNavigate();
  const darkMode = CommonStore.useState((s) => s.themeMode);
  const isDrawerOpen = CommonStore.useState((s) => s.isDrawerOpen);
  return (
    <Drawer
      variant="persistent"
      anchor="left"
      open={isDrawerOpen}
      sx={{
        "& > div": {
          marginTop: "50px",
          paddingTop: "20px",
          position: "fixed !important",
          zIndex: "1099 !important",
        },
      }}
    >
      <List sx={{ display: "flex", flexDirection: "column" }}>
        {howAdminMenu &&
          howAdminMenu.map((item, index) => (
            <ListItemButton
              key={index}
              onClick={() => {
                navigate(item.link);
                return null;
              }}
            >
              <ListItem
                sx={{
                  padding: 0,
                  color: darkMode ? "white !important" : "black !important",
                }}
              >
                <ListItemAvatar>{item.icon}</ListItemAvatar>
                <ListItemText primary={item.name} />
              </ListItem>
            </ListItemButton>
          ))}
      </List>
      <Divider />
    </Drawer>
  );
}
