import { Store } from "pullstate";

export const ApiCommonStore = new Store({
  isChildHowListChanged: false,
  isCreateNewEvent: false,
});

export const setIsChildHowListChanged = (isChanged) => {
  ApiCommonStore.update((s) => {
    s.isChildHowListChanged = isChanged;
  });
};

export const setIsCreateNewEvent = (isChanged) => {
  ApiCommonStore.update((s) => {
    s.isCreateNewEvent = isChanged;
  });
};
