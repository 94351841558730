import { Box } from "@mui/material";
import AuthGuard from "Common/AuthGuard/AuthGuard";
import BoxTable from "Common/DataTable/BoxTable";
import MainLayouts from "Common/Layouts/MainLayouts";
import HowSetupPayment from "../HowDashbord/HowSetupPayment";

export default function HowPaymentSectionPage() {
  return (
    <AuthGuard>
      <MainLayouts>
        <BoxTable>
          <Box
            sx={{ display: "flex", justifyContent: "center", height: "100%" }}
          >
            <HowSetupPayment />
          </Box>
        </BoxTable>
      </MainLayouts>
    </AuthGuard>
  );
}
