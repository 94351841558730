import { Box, Card, CardContent, Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import utils from "services/Utils";
import apiService from "services/http-service/ApiService";

const AdminDashboardCard = (props) => {
  let data = null;

  const q = useQuery(["getAdminDashboardDetails"], async () => {
    return await apiService.getAdminDashboardInfo();
  });

  if (props.isHow) {
    data = q.data?.howcount;
  } else if (props.totalDonors) {
    data = q.data?.donorcount;
  } else if (props.totalDonation) {
    data = utils.prettyPrice(q.data?.totalDonateAMount[0]?.total || 0);
  } else {
    data = utils.prettyPrice(q.data?.totalTodayDonateAMount[0]?.total || 0);
  }

  return (
    <Card
      sx={{
        // height: "100% !important",
        display: "flex !important",
        justifyContent: "center !important",
        alignItems: "center !important",
      }}
    >
      <CardContent
        sx={{
          textAlign: "center",
          paddingTop: "2rem",
        }}
      >
        <Typography color="inherit" gutterBottom variant="overline">
          {props.title}
        </Typography>
        <Box>
          <Typography color="inherit" variant="h4">
            {data}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

export default AdminDashboardCard;
