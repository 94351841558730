import { Box, Card } from "@mui/material";
import BoxTable from "Common/DataTable/BoxTable";
import MainLayouts from "Common/Layouts/MainLayouts";
import DonorDonateCamp from "./DonorContent/DonorDonateCamp";

export default function DonorPayment() {
  return (
    <MainLayouts>
      <BoxTable>
        <Box
          sx={{
            margin: "auto",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Card>
            <DonorDonateCamp />
          </Card>
        </Box>
      </BoxTable>
    </MainLayouts>
  );
}
