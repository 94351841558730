import { TextField } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";

export default function HRoundedTextField(props) {
  const objForm = useFormContext();

  function getError() {
    if (props.name) {
      return Boolean(objForm.formState.errors[props.name]);
    }

    return false;
  }

  function getMessage() {
    if (props.name) {
      if (objForm.formState.errors[props.name]) {
        return objForm.formState.errors[props.name].message;
      }
    }
    return "";
  }

  return (
    <Controller
      name={props.name}
      control={objForm.control}
      defaultValue=""
      render={({ field }) => {
        return (
          <TextField
            variant="outlined"
            fullWidth
            {...field}
            name={props.name}
            error={getError()}
            label={props.label}
            sx={{
              "& .MuiOutlinedInput-root": {
                color: "inherit",
                "& fieldset": {
                  borderColor: "inherit",
                  borderRadius: 5,
                },
              },
            }}
            helperText={getMessage() || ""}
          />
        );
      }}
    />
  );
}
