import { yupResolver } from "@hookform/resolvers/yup";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
    Autocomplete,
    Button,
    Divider,
    IconButton,
    InputAdornment,
    Paper,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { useMutation } from "@tanstack/react-query";
import ErrorPlaceHolder from "Common/ErrorPlaceHolder";
import GetRequiredLabel from "Common/GetRequiredLabel";
import HRoundedTextField from "Common/Inputs/HRoundedTextField";
import { RedditTextField } from "Common/Inputs/Inputs";
import { useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { adminLoginImage } from "services/DefaultConfig";
import apiService from "services/http-service/ApiService";
import authService from "services/http-service/AuthService";
import {
    ApiCommonStore,
    setIsChildHowListChanged,
} from "services/store/ApiStates/ApiCommonStore";
import { SUPPORTED_FORMATS } from "validations/user.validation";
import * as yup from "yup";

const schema = yup.object().shape({
  firstName: yup.string().required("First name is required"),
  lastName: yup.string().required("Last name is required"),
  childHouseName: yup.string().required("Child house name is required"),
  email: yup
    .string()
    .email("Invalid Email Address")
    .required("Email is required"),
  password: yup.string().required("Password is required"),
  mobile: yup.string().min(10).max(10).required("Phone number is required"),
  address: yup.string().required("Address field is required"),
  description: yup.string().optional(),
  country: yup.string().required("Country is  required"),
  state: yup.string().required("State is required"),
  city: yup.string().required("City is required"),
  zipCode: yup.string().required("Zip code is required"),
  userProfile: yup
    .mixed()
    .optional()
    .test("fileType", "Unsupported File Format", function (value) {
      return value?.type ? SUPPORTED_FORMATS.includes(value?.type) : true;
    })
    .test("fileSize", "File Size is too large", (value) => {
      const sizeInBytes = 5000000; //0.5MB
      return value?.size ? value?.size <= sizeInBytes : true;
    }),
  howLogo: yup
    .mixed()
    .optional()
    .test("fileType", "Unsupported File Format", function (value) {
      return value?.type ? SUPPORTED_FORMATS.includes(value?.type) : true;
    })
    .test("fileSize", "File Size is too large", (value) => {
      const sizeInBytes = 5000000; //0.5MB
      return value?.size ? value?.size <= sizeInBytes : true;
    }),
  howImage: yup
    .mixed()
    .optional()
    .test("fileType", "Unsupported File Format", function (value) {
      return value?.type ? SUPPORTED_FORMATS.includes(value?.type) : true;
    })
    .test("fileSize", "File Size is too large", (value) => {
      const sizeInBytes = 5000000; //0.5MB
      return value?.size ? value?.size <= sizeInBytes : true;
    }),
});

export default function AdminSignUpPage() {
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const isChildHowListChanged = ApiCommonStore.useState(
    (s) => s.isChildHowListChanged
  );

  const addOperation = useMutation(async (data) => {
    return await apiService.createAdmin(data);
  });

  const objForm = useForm({
    defaultValues: {
      firstName: "",
      lastName: "",
      childHouseName: "",
      email: "",
      password: "",
      mobile: "",
      address: "",
      description: "",
      state: "",
      city: "",
      zipCode: "",
      userProfile: null,
      howLogo: null,
      howImage: null,
    },
    resolver: yupResolver(schema),
  });

  const loginAction = useMutation(async (data) => {
    if (data) {
      return await apiService.adminUserLogin(data);
    }
    return null;
  });

  const onSave = async (data) => {
    try {
      const io = {
        email: data.email,
        first_name: data.firstName,
        last_name: data.lastName,
        mobile: data.mobile,
        password: data.password,
        how_name: data.childHouseName,
        how_desc: data.description,
        address: data.address,
        country_code: data.country,
        zipcode: data.zipCode,
        state: data.state,
        city: data.city,
        image: data.userProfile,
        how_image: data.howImage,
        backimage: data.howLogo,
      };

      const result = await addOperation.mutateAsync(io);
      if (result.Users) {
        toast.success("Admin user has been created successfully.");
        setIsChildHowListChanged(!isChildHowListChanged);

        const userResp = await loginAction.mutateAsync({
          email: data.email,
          password: data.password,
        });
        
        if (userResp.success) {
          authService.setUserData(userResp.data?.admin);
          authService.setToken(userResp.data?.token);
          navigate("/dashboard");
        }
        return null;
      }
    } catch (ex) {
      console.log("Error occurred from how sign up page", ex.message);
      toast.error("Some error occurred!");
    }
  };

  return (
    <FormProvider {...objForm}>
      <Grid container component="main" sx={{ height: "100vh" }}>
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: `url(${adminLoginImage})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 6,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography component="h1" variant="h5">
              Create How Admin
            </Typography>
            <Divider />
            <Box py={2}></Box>
            <form onSubmit={objForm.handleSubmit(onSave)}>
              <>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6} lg={4}>
                    <GetRequiredLabel isRequired={true} name={"First Name"} />
                    <HRoundedTextField
                      name={"firstName"}
                      label={"First Name"}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                    <GetRequiredLabel name={"Last Name"} isRequired={true} />
                    <HRoundedTextField name={"lastName"} label={"Last Name"} />
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <GetRequiredLabel
                      isRequired={true}
                      name={"Child House Name"}
                    />
                    <HRoundedTextField
                      name={"childHouseName"}
                      label={"Child House Name"}
                    />
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <GetRequiredLabel isRequired={true} name={"Email"} />
                    <HRoundedTextField name={"email"} label={"Email"} />
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <GetRequiredLabel isRequired={true} name={"Password"} />
                    <Controller
                      name={"password"}
                      control={objForm.control}
                      defaultValue=""
                      render={({ field }) => {
                        return (
                          <RedditTextField
                            fullWidth
                            variant="outlined"
                            label="Password"
                            name="password"
                            type={showPassword ? "text" : "password"}
                            {...field}
                            error={Boolean(objForm.formState.errors.password)}
                            helperText={
                              objForm.formState.errors.password
                                ? objForm.formState.errors.password.message
                                : ""
                            }
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={() =>
                                      setShowPassword(!showPassword)
                                    }
                                    edge="end"
                                  >
                                    {showPassword ? (
                                      <VisibilityOff />
                                    ) : (
                                      <Visibility />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                        );
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <GetRequiredLabel isRequired={true} name={"Mobile"} />
                    <HRoundedTextField name={"mobile"} label={"Mobile"} />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <GetRequiredLabel isRequired={true} name={"Address"} />
                    <Controller
                      name={"address"}
                      control={objForm.control}
                      defaultValue=""
                      render={({ field }) => {
                        return (
                          <RedditTextField
                            fullWidth
                            variant="outlined"
                            label="Address"
                            name="address"
                            type="text"
                            {...field}
                            multiline
                            rows={3}
                            error={Boolean(objForm.formState.errors.address)}
                            helperText={
                              objForm.formState.errors.address
                                ? objForm.formState.errors.address.message
                                : ""
                            }
                          />
                        );
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <GetRequiredLabel isRequired={false} name={"Description"} />
                    <Controller
                      name={"description"}
                      control={objForm.control}
                      defaultValue=""
                      render={({ field }) => {
                        return (
                          <RedditTextField
                            fullWidth
                            variant="outlined"
                            label="Description"
                            name="description"
                            type="text"
                            {...field}
                            multiline
                            rows={3}
                          />
                        );
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={3}>
                    <GetRequiredLabel isRequired={true} name={"Country"} />
                    <Controller
                      name={"country"}
                      control={objForm.control}
                      render={({ field }) => {
                        return (
                          <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            {...field}
                            options={[
                              "India",
                              "America",
                              "London",
                              "America",
                              "Canada",
                              "USA",
                            ]}
                            label="Country"
                            renderInput={(params) => (
                              <TextField
                                sx={{
                                  borderRadius: 15,
                                  "& .MuiOutlinedInput-root": {
                                    "& fieldset": {
                                      borderColor: "inherit",
                                      borderRadius: 5,
                                    },
                                  },
                                }}
                                label="Select Country"
                                {...params}
                                fullWidth
                                variant="outlined"
                                type="text"
                                error={Boolean(
                                  objForm.formState.errors.country
                                )}
                                helperText={
                                  objForm.formState.errors.country
                                    ? objForm.formState.errors.country.message
                                    : ""
                                }
                                inputProps={{
                                  ...params.inputProps,
                                  autoComplete: "new-password", // disable autocomplete and autofill
                                }}
                              />
                            )}
                          />
                        );
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={3}>
                    <GetRequiredLabel isRequired={true} name={"State"} />
                    <HRoundedTextField name={"state"} label={"State"} />
                  </Grid>
                  <Grid item xs={12} md={6} lg={3}>
                    <GetRequiredLabel isRequired={true} name={"City"} />
                    <HRoundedTextField name={"city"} label={"City"} />
                  </Grid>
                  <Grid item xs={12} md={6} lg={3}>
                    <GetRequiredLabel isRequired={true} name={"Zip Code"} />
                    <HRoundedTextField name={"zipCode"} label={"Zip Code"} />
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <GetRequiredLabel
                      isRequired={false}
                      name={"User Profile (jpg,img,png,jpeg)"}
                    />
                    <RedditTextField
                      type="file"
                      name="userProfile"
                      fullWidth
                      id="file"
                      label="Select image for event"
                      onChange={(event) => {
                        objForm.setValue("userProfile", event.target.value);
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      error={Boolean(objForm.formState.errors.userProfile)}
                      helperText={
                        objForm.formState.errors.userProfile
                          ? objForm.formState.errors.userProfile.message
                          : ""
                      }
                    />
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <GetRequiredLabel
                      isRequired={false}
                      name={"How Logo (jpg,img,png,jpeg)"}
                    />

                    <RedditTextField
                      type="file"
                      name="howLogo"
                      fullWidth
                      id="file"
                      label="Select how image"
                      onChange={(event) => {
                        objForm.setValue("howLogo", event.target.value);
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <GetRequiredLabel
                      isRequired={false}
                      name={"How Image (jpg,img,png,jpeg)"}
                    />
                    <RedditTextField
                      type="file"
                      name="howImage"
                      fullWidth
                      id="file"
                      label="Select back image"
                      onChange={(event) =>
                        objForm.setValue("howImage", event.target.value)
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  {addOperation.isError && (
                    <Grid item xs={12} py={2}>
                      <Box py={2}>
                        <ErrorPlaceHolder
                          error={
                            addOperation.error &&
                            addOperation.error.error &&
                            addOperation.error.error.message &&
                            addOperation.error.error.message.details &&
                            addOperation.error.error.message.details[0] &&
                            addOperation.error.error.message.details[0].message
                              ? addOperation.error.error.message.details[0]
                                  .message
                              : addOperation.error &&
                                addOperation.error.data &&
                                addOperation.error.data.error
                              ? addOperation.error.data.error.message
                              : addOperation.error.error.message
                          }
                        />
                      </Box>
                    </Grid>
                  )}
                  <Stack
                    direction="row"
                    spacing={2}
                    sx={{
                      width: "100%",
                      padding: 2,
                    }}
                    justifyContent={"center"}
                  >
                    <LoadingButton
                      loading={addOperation.isLoading}
                      disabled={addOperation.isLoading}
                      type="submit"
                      variant="contained"
                      color="secondary"
                    >
                      Save
                    </LoadingButton>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        navigate("/");
                      }}
                    >
                      Cancel
                    </Button>
                  </Stack>
                </Grid>
              </>
            </form>
          </Box>
        </Grid>
      </Grid>
    </FormProvider>
  );
}
