import {SET_MONTHLY_DONOR_HOW_COUNT_REPORT} from '../../constant/index';

const initstate = {
    Donorhowcountmonthlyreport: []
}

const ReportReducer = (state = initstate, action) => {
    const { type, payload } = action;
    
    switch (type){
        case SET_MONTHLY_DONOR_HOW_COUNT_REPORT:
            return { ...state, Donorhowcountmonthlyreport: payload.data };
        default:
            return state;
    }
}
export default  ReportReducer;
