import { RestoreFromTrash } from "@mui/icons-material";
import { Alert, Button, Typography } from "@mui/material";
import { donorCardClose } from "services/DonorCommonStore";
import authService from "services/http-service/AuthService";
import { CommonStore } from "services/store/CommonStore/PullStore";
import { adminCardClose } from "services/store/GridTemplateLayoutStore/AdminDashboardLayoutStore";
import { handleCardClose } from "services/store/GridTemplateLayoutStore/HowDashboardLayoutStore";
export default function MinimizeItemShower(props) {
  const isDark = CommonStore.useState((s) => s.themeMode);

  const getActions = () => {
    if (props.cardID) {
      if (authService.getUserRole() === "Admin") {
        adminCardClose(props.cardID);
        return null;
      }

      if (authService.getUserRole() === "Donor") {
        donorCardClose(props.cardID);
        return null;
      } else {
        handleCardClose(props.cardID);
        return null;
      }
    }
  };

  return (
    <Alert
      variant="standard"
      color="info"
      icon={false}
      className="samir"
      sx={{
        "&>div": {
          minWidth: "100% !important",
          display: "flex !important",
          alignItems: "center !important",
          width: "100% !important",
          justifyContent: "space-between !important",
        },
        m: 2,
      }}
    >
      <Typography color={"inherit"} variant="body1">
        {props.name || "N/A"}
      </Typography>
      <Button
        onClick={getActions}
        variant={!isDark ? "outlined" : "contained"}
        color="primary"
        endIcon={<RestoreFromTrash />}
      >
        Restore
      </Button>
    </Alert>
  );
}
