import {
  AccountCircle,
  AutoAwesomeMosaic,
  Brightness4,
  Brightness7,
  Notifications,
} from "@mui/icons-material";
import MenuIcon from "@mui/icons-material/Menu";
import { Badge, Stack, Tooltip, useMediaQuery } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import * as React from "react";
import { useNavigate } from "react-router";
import utils from "services/Utils";
import authService from "services/http-service/AuthService";
import {
  CommonStore,
  configureDarkMode,
  setIsDrawerOpen,
} from "services/store/CommonStore/PullStore";
import mainTheme from "styles/theme";
import LayoutMenu from "./LayoutMenu";
import UserProfileMenu from "./UserProfileMenu";

export default function MainAppBar(props) {
  const [isOpen, setIsOpen] = React.useState(false);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const darkMode = CommonStore.useState((s) => s.themeMode);
  const navigate = useNavigate();

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const toggleLayoutMenu = () => {
    setIsOpen(!isOpen);
  };

  const toggleDarkMode = () => {
    if (darkMode) {
      configureDarkMode(false);
      utils.setLST("darkTheme", false);
    } else {
      configureDarkMode(true);
      utils.setLST("darkTheme", true);
    }
  };

  const downSmall = useMediaQuery(mainTheme.breakpoints.down("sm"));

  const configureUserProfileAddress = () => {
    const userRole = authService.getUserRole();

    if (userRole === "HowAdmin") {
      navigate("/how-profile");
      return null;
    }

    if (userRole === "Donor") {
      navigate("/donor-profile");
      return null;
    }

    if (userRole === "Admin") {
      navigate("/admin-profile");
      return null;
    }
  };

  return (
    <>
      <AppBar
        position="fixed"
        // sx={{
        //   zIndex: 12000,
        // }}
        color="default"
      >
        <Toolbar
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Stack direction={"row"} alignItems={"center"}>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={setIsDrawerOpen}
            >
              <MenuIcon />
            </IconButton>
            <img
              style={{ marginLeft: "1%", margin: 0 }}
              src={"/assets/logo/holydomus_logo.png"}
              height={downSmall ? "35px !important" : 50}
              alt="logo.png"
            />
          </Stack>

          <Box sx={{ flexGrow: 0 }}>
            <Stack spacing={1} direction={"row"}>
              <Tooltip title="Dashboard Layouts">
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  edge="start"
                  onClick={toggleLayoutMenu}
                >
                  <AutoAwesomeMosaic />
                </IconButton>
              </Tooltip>
              <IconButton color="inherit" onClick={toggleDarkMode} edge="start">
                {darkMode ? (
                  <Brightness7 sx={{ height: "25px", width: "25px" }} />
                ) : (
                  <Brightness4 sx={{ height: "25px", width: "25px" }} />
                )}
              </IconButton>
              <IconButton color="inherit" aria-label="open drawer" edge="start">
                <Badge badgeContent={4} color="secondary">
                  <Notifications />
                </Badge>
              </IconButton>
              <IconButton
                color="inherit"
                edge="start"
                onClick={handleOpenUserMenu}
              >
                <AccountCircle sx={{ height: "25px", width: "25px" }} />
              </IconButton>
            </Stack>
            <UserProfileMenu
              anchorElUser={anchorElUser}
              configureUserProfileAddress={configureUserProfileAddress}
              handleCloseUserMenu={handleCloseUserMenu}
            />
            <LayoutMenu
              toggleLayoutMenu={toggleLayoutMenu}
              isOpen={isOpen}
            />
          </Box>
        </Toolbar>
      </AppBar>
    </>
  );
}
