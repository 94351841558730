import { Button, Typography } from "@mui/material";
import { Link } from "react-router-dom";

export function getLoginButton({ title, link }) {
  return (
    <Link to={link}>
      <Button fullWidth variant="contained" sx={{ my: 2, py: 1 }}>
        {title}
      </Button>
    </Link>
  );
}

export function getCopyRightFooter() {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      sx={{ mt: 5 }}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://holypenniesdonor.herokuapp.com/">
        HolyDomus
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}
