import AuthGuard from "Common/AuthGuard/AuthGuard";
import MainLayouts from "Common/Layouts/MainLayouts";
import HowTermPolicy from "pages/How/HowDashbord/HowTermPolicy";
import HowHelp from "../HowHelp/HowHelp";
import BoxTable from "Common/DataTable/BoxTable";
import { Card } from "@mui/material";

export default function HowSupportSectionPage() {
  return (
    <AuthGuard>
      <MainLayouts>
        <BoxTable>
          <Card variant="outlined">
            <HowHelp />
          </Card>
        </BoxTable>
        <BoxTable>
          <Card variant="outlined">
            <HowTermPolicy />
          </Card>
        </BoxTable>
      </MainLayouts>
    </AuthGuard>
  );
}
