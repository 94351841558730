import { GlobalStyles, ThemeProvider, createTheme } from "@mui/material";
import { grey } from "@mui/material/colors";
import { darkModeTextColor, lightModeTextColor } from "services/DefaultConfig";
import { CommonStore } from "services/store/CommonStore/PullStore";

export default function CustomThemeProvider(props) {
  const darkMode = CommonStore.useState((s) => s.themeMode);
  const mainTheme = createTheme({
    typography: {
      button: {
        textTransform: "none",
        fontSize: "15px",
      },
    },
    palette: {
      mode: darkMode ? "dark" : "light",
      background: {
        default: darkMode ? "#212121" : grey[50],
        paper: darkMode ? "#212121" : grey[50],
      },
      text: {
        primary: darkMode ? darkModeTextColor : lightModeTextColor,
      },
      primary: {
        main: "#673ab7",
        dark: "#5c3b96",
      },
      secondary: {
        main: "#2196f3",
        dark: "#1d85d8",
      },
    },
  });
  return (
    <>
      <GlobalStyles
        styles={{
          body: {
            backgroundColor: `${darkMode ? "black" : "whiteSmoke"} !important`,
          },
        }}
      />
      <ThemeProvider theme={mainTheme}>{props.children}</ThemeProvider>;
    </>
  );
}
