import { Box, Card, createTheme, CssBaseline, Grid, makeStyles, ThemeProvider } from "@material-ui/core";
import clsx from "clsx";
import Header from "Header";
import AdminWrapper from "layout/MainLayout/AdminWrapper";
import AuthWrapper from "layout/MainLayout/AuthWrapper";
import { useState } from "react";
import Sidebar from "Sidebar";
import ListOfHolyHouses from "./ListOfHolyHouses";
import { CommonStore, configureDarkMode } from "services/store/CommonStore/PullStore";

const drawerWidth = 180;
const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex"
    },

    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create("margin", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        marginLeft: -drawerWidth
    },
    contentShift: {
        transition: theme.transitions.create("margin", {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen
        }),
        marginLeft: 0
    },
    drawerHeader: {
        display: "flex",
        alignItems: "center",
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: "flex-end"
    }
}));

export default function HolyHouses() {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const darkMode = CommonStore.useState((s)=>s.themeMode)
    

    const handleDrawerToggle = () => {
        setOpen(!open);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const theme = createTheme({
        palette: {
            type: darkMode ? "dark" : "light"
        }
    });

    const toggleDarkMode = () => {
        configureDarkMode(!darkMode);
        localStorage.setItem('darkTheme', !darkMode)
    };

    return (
        <AuthWrapper>
            <AdminWrapper>
                <ThemeProvider theme={theme}>
                    <div className={classes.root}>
                        <CssBaseline />
                        <Header
                            handleDrawerToggle={handleDrawerToggle}
                            toggleDarkMode={toggleDarkMode}
                            darkMode={darkMode}
                        />
                        <Sidebar handleDrawerClose={handleDrawerClose} open={open} />
                        <main
                            className={clsx(classes.content, {
                                [classes.contentShift]: open
                            })}
                        >
                            <div className={classes.drawerHeader} />
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Card>
                                        <ListOfHolyHouses />
                                    </Card>
                                </Grid>
                            </Grid>
                        </main>
                    </div>
                </ThemeProvider>
            </AdminWrapper>
        </AuthWrapper>
    )
}
