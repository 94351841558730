export const howLoginImage =
  "https://images.unsplash.com/photo-1675082704413-53bd4ad1637b?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8fHx8fHx8MTY3NzU2NzY5MQ&ixlib=rb-4.0.3&q=80&w=620";
export const donorLoginImage =
  "https://images.unsplash.com/photo-1676644815731-4373ddf553b8?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8fHx8fHx8MTY3NzU2NzU0Mw&ixlib=rb-4.0.3&q=80&w=620";
export const adminLoginImage =
  "https://generatorfun.com/code/uploads/Random-Nature-image-3.jpg";
export const forgetPasswordImage =
  "https://generatorfun.com/code/uploads/Random-Nature-image-3.jpg";
export const IMAGE_FORMAT = ["image/jpg", "image/jpeg", "image/png"];

export const lightModeTextColor = "#5d5d5d";
export const darkModeTextColor = "#a6a6a6";
