import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation } from "@tanstack/react-query";
import LoginScreenPage from "Common/LoginScreen/LoginScreenPage";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { adminLoginImage } from "services/DefaultConfig";
import apiService from "services/http-service/ApiService";
import authService from "services/http-service/AuthService";
import * as yup from "yup";

const schema = yup.object().shape({
  userEmail: yup
    .string()
    .email("Invalid email address")
    .required("Email is required"),
  userPassword: yup
    .string()
    .min(4, "Password must be at least 4 characters")
    .required("Password is required"),
});

export default function DonorLoginPage() {
  const navigate = useNavigate();
  const objForm = useForm({
    defaultValues: {
      userEmail: "",
      userPassword: "",
    },
    resolver: yupResolver(schema),
  });

  const loginAction = useMutation(async (data) => {
    if (data) {
      return await apiService.adminUserLogin(data);
    }
    return null;
  });

  const onLogin = async (data) => {
    const io = {
      email: data.userEmail,
      password: data.userPassword,
    };
    const userResp = await loginAction.mutateAsync(io);
    if (userResp.success) {
      authService.setUserData(userResp.data?.admin);
      authService.setToken(userResp.data?.token);
      toast.success("Admin successfully login");
      navigate("/dashboard");
      return null;
    }

    throw new Error(userResp.error.message || "Error occurred while login!");
  };

  return (
    <FormProvider {...objForm}>
      <LoginScreenPage
        title={"Admin Login"}
        landingImage={adminLoginImage}
        onLogin={onLogin}
        action={loginAction}
        signUpLink={"/admin-signup"}
        forgetPasswordLink={'/forgetPasswordAdmin'}
      />
    </FormProvider>
  );
}
