import { makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles({
    root: {
        width: '100%',
    },
});

export default function ListOfHolyHouses() {
    function createData(number, userImage, houseName, firstName, lastName, email, joinDate) {
        return { number, userImage, houseName, firstName, lastName, email, joinDate };
    }

    const rows = [
        createData(1, 'https://holypenniesapi.herokuapp.com/images/howavatar.jpg', 'Deve', 'John', 'Deo', 'john@email.com', "05/02/2023"),
        createData(2, 'https://holypenniesapi.herokuapp.com/images/howavatar.jpg', 'Holy House', 'Jane', 'Smith', 'jane@email.com', "05/03/2023"),
        createData(3, 'https://holypenniesapi.herokuapp.com/images/howavatar.jpg', 'Holy House', 'Lenerd', 'Chistan', 'lenerd@email.com', "05/02/2023"),
        createData(4, 'https://holypenniesapi.herokuapp.com/images/howavatar.jpg', 'Toro', 'Bozo', 'Clown', 'bozo@email.com', "15/02/2023"),
        createData(5, 'https://holypenniesapi.herokuapp.com/images/howavatar.jpg', 'Temple 1', 'Eric', 'Costanga', 'Eric@email.com', "05/02/2023"),
        createData(6, 'https://holypenniesapi.herokuapp.com/images/howavatar.jpg', 'Deve', 'John', 'Deo', 'john@email.com', "05/02/2023"),
        createData(7, 'https://holypenniesapi.herokuapp.com/images/howavatar.jpg', 'Holy House', 'Jane', 'Smith', 'jane@email.com', "05/03/2023"),
        createData(8, 'https://holypenniesapi.herokuapp.com/images/howavatar.jpg', 'Holy House', 'Lenerd', 'Chistan', 'lenerd@email.com', "05/02/2023"),
        createData(9, 'https://holypenniesapi.herokuapp.com/images/howavatar.jpg', 'Toro', 'Bozo', 'Clown', 'bozo@email.com', "15/02/2023"),
        createData(10, 'https://holypenniesapi.herokuapp.com/images/howavatar.jpg', 'Temple 1', 'Eric', 'Costanga', 'Eric@email.com', "05/02/2023"),
        createData(11, 'https://holypenniesapi.herokuapp.com/images/howavatar.jpg', 'Deve', 'John', 'Deo', 'john@email.com', "05/02/2023"),
    ];
    const TblCell = ({ children }) => (
        <Typography color="textPrimary">{children}</Typography>
    )

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    const classes = useStyles();
    return (
        <>
            <Paper className={classes.root}>
                <TableContainer className={classes.container}>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                <TableCell><TblCell>#</TblCell></TableCell>
                                <TableCell align="right"><TblCell>User Image</TblCell></TableCell>
                                <TableCell align="right"><TblCell>House Name</TblCell></TableCell>
                                <TableCell align="right"><TblCell>First Name</TblCell></TableCell>
                                <TableCell align="right"><TblCell>Last Name</TblCell></TableCell>
                                <TableCell align="right"><TblCell>Email</TblCell></TableCell>
                                <TableCell align="right"><TblCell>Joining Date</TblCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                                <TableRow
                                    key={row.number}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        <TblCell>{row.number}</TblCell>
                                    </TableCell>
                                    <TableCell align="right"><img src={row.userImage} height={"20px"} style={{ borderRadius: "50%", width: "30px", height: "30px" }} /></TableCell>
                                    <TableCell align="right">
                                        <TblCell>
                                            {row.houseName}
                                        </TblCell>
                                    </TableCell>
                                    <TableCell align="right">
                                        <TblCell>
                                            {row.firstName}
                                        </TblCell>
                                    </TableCell>
                                    <TableCell align="right">
                                        <TblCell>
                                            {row.lastName}
                                        </TblCell>
                                    </TableCell>
                                    <TableCell align="right">
                                        <TblCell>
                                            {row.email}
                                        </TblCell>
                                    </TableCell>
                                    <TableCell align="right">
                                        <TblCell>
                                            {row.joinDate}
                                        </TblCell>
                                    </TableCell>

                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 20, 25, 50, 100]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        </>
    )
}
