import { AccountCircle } from '@material-ui/icons';
import { Autocomplete, TextField } from '@mui/material';
const countryNames = ['India', 'America', 'London', 'Shrilanka', 'Canada', 'USA']

export function RedditTextField(props) {
    return <TextField
        id="inputRounded"
        sx={{
            borderRadius: 15,
            '& .MuiOutlinedInput-root': {
                '& fieldset': {
                    borderColor: 'inherit',
                    borderRadius: 5,
                },
            },
        }}
        {...props}
    />;
}

export function RedditAutoComplete(props) {
    return (
        <>
            <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={countryNames}
                onChange={(e,value) => {
                    props.onChange(value)
                }}
                renderInput={(params) => (
                    <TextField
                        sx={{
                            borderRadius: 15,
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderColor: 'inherit',
                                    borderRadius: 5,
                                },
                            },
                        }}
                        label='Select Country'
                        {...params}
                        {...props}
                        inputProps={{
                            ...params.inputProps,
                            autoComplete: 'new-password', // disable autocomplete and autofill
                        }}
                    />
                )}
            />
        </>
    )
}