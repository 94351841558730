import { CssBaseline } from "@material-ui/core";
import { Box } from "@mui/material";
import authService from "services/http-service/AuthService";
import { CommonStore } from "services/store/CommonStore/PullStore";
import "../../styles.css";
import AdminSideBar from "./AdminSideBar";
import DonorSideBar from "./DonorSideBar";
import HowAdminSideBar from "./HowAdminSideBar";
import MainAppBar from "./MainAppBar";

function MainLayouts({ children }) {
  const userRole = authService.getUserRole();
  const isDrawerMenuOpen = CommonStore.useState((s) => s.isDrawerOpen);
  
  return (
    <>
      <CssBaseline />
      <MainAppBar />
      {userRole === "HowAdmin" && <HowAdminSideBar />}
      {userRole === "Donor" && <DonorSideBar />}
      {userRole === "Admin" && <AdminSideBar />}
      <Box
        sx={{
          marginTop: "74px",
          paddingLeft: isDrawerMenuOpen ? "220px" : "0px",
        }}
      >
        {children}
      </Box>
    </>
  );
}

export default MainLayouts;
