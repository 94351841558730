import utils from "services/Utils";

class AuthService {
  getToken() {
    return utils.getLST("jwt");
  }

  setUserData(userData) {
    return utils.setLST("user", userData);
  }

  getUserData() {
    const userData = utils.getLST("user");
    if (userData) {
      return userData;
    }

    return null;
  }

  setToken(token) {
    return utils.setLST("jwt", token);
  }

  getUserRole() {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      return user.role;
    }
  }

  userLogout() {
    utils.removeItem("user");
    utils.removeItem("jwt");
    // localStorage.clear();
  }

  isUserLogin() {
    const user = JSON.parse(localStorage.getItem("user"));
    const token = localStorage.getItem("jwt");
    if (user && token) {
      return true;
    }

    return false;
  }
}

const authService = new AuthService();
window["auth"] = authService;
export default authService;
