import { Box, CircularProgress } from "@mui/material";
import GetWindowButton from "Common/GetWindowButton";
import LayoutHeaders from "Common/Layouts/LayoutHeaders";
import { useEffect, useState } from "react";
import { Responsive as ResponsiveGridLayout } from "react-grid-layout";
import { withSize } from "react-sizeme";
import { toast } from "react-toastify";
import {
  HowDashboardLayoutStore,
  getDashboardLayoutElement,
  setIsCardLayoutChanged,
} from "services/store/GridTemplateLayoutStore/HowDashboardLayoutStore";

function HowDashboardContent({ size: { width } }) {
  const [isChanged, setIsChanged] = useState(true);
  const initialLayouts = HowDashboardLayoutStore.useState(
    (s) => s.initialDashboardLayouts
  );
  const [lstHowDashboardElement, setLstHowDashboardElement] = useState(null);
  const [layouts, setLayouts] = useState({});

  const isCardLayoutsChanged = HowDashboardLayoutStore.useState(
    (s) => s.isCardLayoutsChanged
  );

  const onLayoutSave = () => {
    saveToLS(layouts);
    toast.success("Layouts has been saved");
  };

  const onLayoutChange = (_, allLayouts) => {
    setLayouts(allLayouts);
  };

  

  const loadData = () => {
    const result = getDashboardLayoutElement();
    setLstHowDashboardElement(result);
  };

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    if (isCardLayoutsChanged) {
      onLayoutChange();
      loadData();
      setIsCardLayoutChanged(false);
    }
  }, [isCardLayoutsChanged]);

  useEffect(() => {
    if (lstHowDashboardElement && lstHowDashboardElement.length > 0) {
      if (getFromLS() && Object.keys(getFromLS()).length > 0) {
        setLayouts(getFromLS());
      } else {
        setLayouts(initialLayouts);
      }
    }
  }, [lstHowDashboardElement]);

  if (layouts && Object.keys(layouts).length <= 0)
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "90vh",
        }}
      >
        <CircularProgress />
      </Box>
    );

  return (
    <>
      <LayoutHeaders title={"How Dashboard"} onSave={onLayoutSave} />
      <ResponsiveGridLayout
        layouts={layouts}
        breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
        cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
        width={width}
        rowHeight={60}
        onLayoutChange={onLayoutChange}
        // autoSize={true}
      >
        {lstHowDashboardElement &&
          lstHowDashboardElement.length > 0 &&
          lstHowDashboardElement.map(
            (item, index) =>
              !item.isClosed && (
                <Box key={item.key}>
                  <GetWindowButton
                    isCard={item.isCard}
                    isDonationCard={item.isDonationCard}
                    overFlowNone={item.overFlowNone}
                    cardID={item.key}
                    isVisible={item.isVisible}
                  >
                    {item.element}
                  </GetWindowButton>
                </Box>
              )
          )}
      </ResponsiveGridLayout>
    </>
  );
}

export default withSize()(HowDashboardContent);

function getFromLS() {
  if (global.localStorage) {
    return (
      JSON.parse(global.localStorage.getItem("howDashboardLayouts")) || null
    );
  }
}

function saveToLS(value) {
  if (global.localStorage) {
    global.localStorage.setItem("howDashboardLayouts", JSON.stringify(value));
  }
}
