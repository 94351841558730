import { LockOutlined } from "@material-ui/icons";
import { AccountCircle, ArrowBack } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Hidden, Stack } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import CssBaseline from "@mui/material/CssBaseline";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import ErrorPlaceHolder from "Common/ErrorPlaceHolder";
import { useFormContext } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { getCopyRightFooter } from "services/RenderUtils";

export default function LoginScreenPage({
  title,
  landingImage,
  onLogin,
  action,
  landingPageLink,
  signUpLink,
  forgetPasswordLink,
}) {
  const navigate = useNavigate();
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useFormContext();

  let internalServerError = "";
  if (
    action.error &&
    action.error.data &&
    action.error.data.error &&
    action.error.data.error?.message
  ) {
    internalServerError = action.error.data.error?.message;
  }
  return (
    <Grid container component="main" sx={{ height: "100vh" }}>
      <CssBaseline />
      <Grid
        item
        xs={false}
        sm={4}
        md={7}
        sx={{
          backgroundImage: `url(${landingImage})`,
          backgroundRepeat: "no-repeat",
          backgroundColor: (t) =>
            t.palette.mode === "light"
              ? t.palette.grey[50]
              : t.palette.grey[900],
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <Box
          sx={{
            my: 8,
            mx: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlined />
          </Avatar>
          <Typography component="h1" variant="h5">
            {title}
          </Typography>
          <Box mt={1}>
            <form onSubmit={handleSubmit(onLogin)}>
              <TextField
                margin="normal"
                fullWidth
                autoFocus
                label="Email Address"
                name="email"
                {...register("userEmail")}
                error={Boolean(errors.userEmail)}
                helperText={
                  Boolean(errors.userEmail) ? errors.userEmail?.message : ""
                }
              />
              <TextField
                margin="normal"
                fullWidth
                name="password"
                label="Password"
                type="password"
                {...register("userPassword")}
                error={Boolean(errors.userPassword)}
                helperText={
                  Boolean(errors.userPassword)
                    ? errors.userPassword?.message
                    : ""
                }
              />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <FormControlLabel
                  control={<Checkbox value="remember" color="primary" />}
                  label="Remember me"
                />
                <Box
                  component={"a"}
                  sx={{
                    "&:hover": {
                      cursor: "pointer",
                    },
                    borderBottom: "black solid 1px",
                  }}
                  onClick={() => {
                    navigate(`${forgetPasswordLink}`);
                  }}
                >
                  Forget Password
                </Box>
              </Box>
              {action.isError && (
                <Box py={2}>
                  <ErrorPlaceHolder
                    error={internalServerError || action.error.error.message}
                  />
                </Box>
              )}
              <LoadingButton
                loading={action.isLoading}
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Sign In
              </LoadingButton>
            </form>
            <Stack direction={"row"} justifyContent={"space-between"}>
              <Hidden mdDown>
                <Link to={landingPageLink ? landingPageLink : "/"}>
                  Back to Landing Page
                </Link>
                {/* <Link to={signUpLink ? signUpLink : "/"}>
                  Don't have an account? Sign Up
                </Link> */}
              </Hidden>
              <Hidden mdUp>
                <Button
                  startIcon={<ArrowBack />}
                  onClick={() => {
                    if (landingPageLink) {
                      navigate(landingPageLink);
                    } else {
                      navigate("/");
                    }
                  }}
                  variant="outlined"
                >
                  Back To Landing
                </Button>
                {/* <Button
                  onClick={() => {
                    if (signUpLink) {
                      navigate(signUpLink);
                    } else {
                      navigate("/");
                    }
                  }}
                  startIcon={<AccountCircle />}
                  variant="outlined"
                >
                  Sign Up
                </Button> */}
              </Hidden>
            </Stack>
            {getCopyRightFooter()}
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}
