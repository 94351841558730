import AuthGuard from "Common/AuthGuard/AuthGuard";
import BoxTable from "Common/DataTable/BoxTable";
import MainLayouts from "Common/Layouts/MainLayouts";
import React from "react";
import AdminUserList from "./AdminUserList";

export default function AdminUserSection() {
  return (
    <AuthGuard>
      <MainLayouts>
        <BoxTable>
          <AdminUserList />
        </BoxTable>
      </MainLayouts>
    </AuthGuard>
  );
}
