import Typography from "@mui/material/Typography";
import { useQuery } from "@tanstack/react-query";
import DataTable from "Common/DataTable/DataTable";
import utils from "services/Utils";
import apiService from "services/http-service/ApiService";

export default function AdminInvitedDonors() {
  const q = useQuery(["getAdminHowDonor"], async () => {
    return await apiService.getAdminHowDonorList();
  });

  return (
    <DataTable
      title="Donors"
      isLoading={q.isLoading}
      isError={q.isError}
      errorMessage={
        q.isError
          ? (q.error && q.error.error && q.error.error.message) ||
            "Some error occurred! Please contact admin."
          : null
      }
      columns={[
        {
          title: "Full Name",
          field: "firstname",
          render: (rowData) => {
            return `${rowData.User.first_name} ${rowData.User.last_name}`;
          },
        },
        { title: "Email", field: "User.email" },
        { title: "Mobile", field: "User.mobile" },
        { title: "Role", field: "User.role" },
        {
          title: "Created Date",
          field: "createdAt",
          render: (row) => {
            return (
              <>
                <Typography variant="body1">
                  {utils.getDDMMYYYY(row.createdAt)}
                </Typography>
              </>
            );
          },
        },
        {
          title: "Updated Date",
          field: "updatedAt",
          render: (row) => {
            return (
              <>
                <Typography variant="body1">
                  {utils.getDDMMYYYY(row.updatedAt)}
                </Typography>
              </>
            );
          },
        },
      ]}
      data={(q.data && q.data.data && q.data.data.rows) || []}
    />
  );
}
