import AuthGuard from "Common/AuthGuard/AuthGuard";
import BoxTable from "Common/DataTable/BoxTable";
import MainLayouts from "Common/Layouts/MainLayouts";
import ChildHowPage from "../ChildHow/ChildHowPage";
import HowInvitationPage from "../Invitations/HowInvitationPage";
import SuspendedHowPage from "../SuspendedHow/SuspendedHowPage";

export default function HowHouseOfWorshipPage() {
  return (
    <AuthGuard>
      <MainLayouts>
        <BoxTable>
          <ChildHowPage />
        </BoxTable>
        <BoxTable>
          <HowInvitationPage />
        </BoxTable>
        <BoxTable>
          <SuspendedHowPage />
        </BoxTable>
      </MainLayouts>
    </AuthGuard>
  );
}
