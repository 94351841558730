import BoxTable from "Common/DataTable/BoxTable";
import DataTable from "Common/DataTable/DataTable";

export default function RevenueFromChildHolyHouses() {
  return (
    <BoxTable>
      <DataTable
        title="Revenue From Child Holy Houses"
        columns={[
          { title: "Transaction id", field: "id" },
          { title: "Amount", field: "amount" },
          { title: "Donor Name", field: "donorName" },
          { title: "Date", field: "date" },
        ]}
        data={[]}
        options={{
          exportButton: {
            csv: true,
          },
        }}
      />
    </BoxTable>
  );
}
