import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Divider, Grid } from "@material-ui/core";
import { LoadingButton } from "@mui/lab";
import { Button, Typography } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import ErrorPlaceHolder from "Common/ErrorPlaceHolder";
import HRoundedTextField from "Common/Inputs/HRoundedTextField";
import { FormProvider, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import utils from "services/Utils";
import apiService from "services/http-service/ApiService";
import * as yup from "yup";

const schema = yup.object().shape({
  email: yup.string().email().required("Email is required"),
  firstName: yup.string().required("First name is required"),
});

export default function HowInviteChild(props) {
  const objForm = useForm({
    defaultValues: {
      email: "",
      firstName: "",
    },
    resolver: yupResolver(schema),
  });

  const sendHowToDonorInvitationOperation = useMutation(async (data) => {
    return await apiService.sendHowToDonorInvitation(data);
  });

  const onSave = async (data) => {
    try {
      const io = {
        email: data.email,
        firstname: data.firstName,
        refercode: utils.getCurrentUserReferCode(),
      };
      const res = await sendHowToDonorInvitationOperation.mutateAsync(io);
      if (res.success) {
        toast.success(res.data);
        if (props.toggle && props.onComplete) {
          props.onComplete();
          props.toggle();
        }
        return null;
      }

      throw new Error(res.data);
    } catch (ex) {
      toast.error(ex.message);
    }
  };

  return (
    <FormProvider {...objForm}>
      <Typography variant="h5" fontWeight={"bold"} pb={2}>
        Send Invitation
        <Divider />
      </Typography>
      <form onSubmit={objForm.handleSubmit(onSave)}>
        <Box sx={{ maxWidth: "100%" }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sx={{ marginTop: 11 }}>
              <HRoundedTextField name="email" label="Email" />
            </Grid>
            <Grid item xs={12} sx={{ marginTop: 11 }}>
              <HRoundedTextField name="firstName" label="First Name" />
            </Grid>
            {sendHowToDonorInvitationOperation.isError && (
              <Grid item xs={12}>
                <ErrorPlaceHolder
                  error={
                    sendHowToDonorInvitationOperation.error.stack ||
                    (sendHowToDonorInvitationOperation.error &&
                      sendHowToDonorInvitationOperation.error.error &&
                      sendHowToDonorInvitationOperation.error.error.message) ||
                    "Some error occurred!"
                  }
                />
              </Grid>
            )}

            <Grid item xs={12} sm={6}>
              <LoadingButton
                fullWidth
                type="submit"
                variant="contained"
                color="primary"
                loading={sendHowToDonorInvitationOperation.isLoading}
                disabled={sendHowToDonorInvitationOperation.isLoading}
              >
                Submit
              </LoadingButton>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button
                fullWidth
                color="secondary"
                variant="contained"
                onClick={() => {
                  props.toggle();
                }}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </Box>
      </form>
    </FormProvider>
  );
}
