import { Box, CircularProgress } from "@mui/material";
import LayoutHeaders from "Common/Layouts/LayoutHeaders";
import { useEffect, useState } from "react";
import { Responsive as ResponsiveGridLayout } from "react-grid-layout";
import { withSize } from "react-sizeme";
import { toast } from "react-toastify";
import {
  DonorCommonStore,
  getDonorDashboardLayouts,
  setDonorCardIsChanged,
} from "services/DonorCommonStore";
import GetDonorWindowButton from "../GetDonorWindowButton";

function DonorDashboardContent({ size: { width } }) {
  const [isChange, setIsChange] = useState(false);
  const [layouts, setLayouts] = useState({});

  const isCardLayoutsChanged = DonorCommonStore.useState(
    (s) => s.donorCardIsChanged
  );

  const [lstDonorElement, setLstDonorElement] = useState(null);
  const initialLayouts = DonorCommonStore.useState(
    (s) => s.initialDashboardLayouts
  );

  const onLayoutSave = () => {
    saveToLS(layouts);
    toast.success("Layouts has been saved");
  };

  const onLayoutChange = (_, allLayouts) => {
    setLayouts(allLayouts);
  };

  const loadData = () => {
    const result = getDonorDashboardLayouts();
    setLstDonorElement(result);
  };

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    if (isCardLayoutsChanged) {
      onLayoutChange();
      loadData();
      setDonorCardIsChanged(false);
    }
  }, [isCardLayoutsChanged]);

  useEffect(() => {
    if (lstDonorElement && lstDonorElement.length > 0) {
      if (getFromLS() && Object.keys(getFromLS()).length > 0) {
        setLayouts(getFromLS());
      } else {
        setLayouts(initialLayouts);
      }
    }
  }, [lstDonorElement]);

  if (layouts && Object.keys(layouts).length <= 0)
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "90vh",
        }}
      >
        <CircularProgress />
      </Box>
    );

  return (
    <>
      <LayoutHeaders title={"Donor Dashboard"} onSave={onLayoutSave} />
      <ResponsiveGridLayout
        layouts={layouts}
        breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
        cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
        width={width}
        rowHeight={60}
        onLayoutChange={onLayoutChange}
      >
        {lstDonorElement &&
          lstDonorElement.length > 0 &&
          lstDonorElement.map(
            (item, index) =>
              !item.isClosed && (
                <Box key={item.key}>
                  <GetDonorWindowButton
                    isCard={item.isCard}
                    isDonationCard={item.isDonationCard}
                    overFlowNone={item.overFlowNone}
                    cardID={item.key}
                    isVisible={item.isVisible}
                  >
                    {item.element}
                  </GetDonorWindowButton>
                </Box>
              )
          )}
        <GetDonorWindowButton></GetDonorWindowButton>
      </ResponsiveGridLayout>
    </>
  );
}

export default withSize()(DonorDashboardContent);

function getFromLS() {
  if (global.localStorage) {
    return (
      JSON.parse(global.localStorage.getItem("donorDashboardLayouts")) || null
    );
  }
}

function saveToLS(value) {
  if (global.localStorage) {
    global.localStorage.setItem("donorDashboardLayouts", JSON.stringify(value));
  }
}
