import { Box } from "@mui/material";
import React from "react";

export default function BoxTable(props) {
  return (
    <Box
      sx={{
        overflow: "auto",
        padding: "1rem",
        "& > div > div:nth-of-type(2)>div": {
          overflow: "hidden !important",
        },
      }}
    >
      {props.children}
    </Box>
  );
}
