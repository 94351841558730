import { makeStyles } from "@material-ui/core";
import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import gettingStarted from "../../../assets/images/Contact/getting.png";
import transactionImg from "../../../assets/images/Contact/transactions.png";
import profile from "../../../assets/images/Dashboard/profile.png";

const useStyles = makeStyles({
  avatar: {
    width: "100px",
    height: "100px",
    borderRadius: "50%",
    overflow: "hidden",
    backgroundColor: "#c4c4ff",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  avatarImage: {
    width: "60px",
    height: "60px",
    objectFit: "cover",
    verticalAlign: "middle",
    margin: "0% !important",
  },
  mainBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
});

export default function HowHelp() {
  const classes = useStyles();
  return (
    <Box p={2}>
      <Grid container spacing={2}>
        <Grid item xs={12} py={1} ml={2}>
          <Typography color={"inherit"} variant="h5">
            Help Center
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Box className={classes.mainBox}>
            <Box className={classes.avatar}>
              <img
                className={classes.avatarImage}
                src={gettingStarted}
                alt="Avatar Image1"
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                textAlign: "center",
                pt: 2,
              }}
            >
              <Typography color={"inherit"} variant="h5">
                Getting Started
              </Typography>
              <Typography color={"inherit"} variant="body1">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Non
                unde.
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Box className={classes.mainBox}>
            <Box className={classes.avatar}>
              <img
                className={classes.avatarImage}
                src={transactionImg}
                alt="Avatar Image2"
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                textAlign: "center",
                pt: 2,
              }}
            >
              <Typography color={"inherit"} variant="h5">
                Transaction
              </Typography>
              <Typography color={"inherit"} variant="body1">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Non
                unde.
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Box className={classes.mainBox}>
            <Box className={classes.avatar}>
              <img
                className={classes.avatarImage}
                src={profile}
                alt="Avatar Image3"
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                textAlign: "center",
                pt: 2,
              }}
            >
              <Typography color={"inherit"} variant="h5">
                Profile
              </Typography>
              <Typography color={"inherit"} variant="body1">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Non
                unde.
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Box className={classes.mainBox}>
            <Box className={classes.avatar}>
              <img
                className={classes.avatarImage}
                src={gettingStarted}
                alt="Avatar Image4"
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                textAlign: "center",
                pt: 2,
              }}
            >
              <Typography color={"inherit"} variant="h5">
                Getting Started
              </Typography>
              <Typography color={"inherit"} variant="body1">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Non
                unde.
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Box className={classes.mainBox}>
            <Box className={classes.avatar}>
              <img
                className={classes.avatarImage}
                src={transactionImg}
                alt="Avatar Image5"
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                textAlign: "center",
                pt: 2,
              }}
            >
              <Typography color={"inherit"} variant="h5">
                Transaction
              </Typography>
              <Typography color={"inherit"} variant="body1">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Non
                unde.
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Box className={classes.mainBox}>
            <Box className={classes.avatar}>
              <img
                className={classes.avatarImage}
                src={profile}
                alt="Avatar Image6"
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                textAlign: "center",
                pt: 2,
              }}
            >
              <Typography color={"inherit"} variant="h5">
                Profile
              </Typography>
              <Typography color={"inherit"} variant="body1">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Non
                unde.
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
