import { Store } from "pullstate";

export const CommonStore = new Store({
  themeMode: Boolean(JSON.parse(localStorage.getItem("darkTheme"))),
  isDrawerOpen: false,
});

export const configureDarkMode = (mode) => {
  CommonStore.update((s) => {
    s.themeMode = mode;
  });
};

export const setIsDrawerOpen = () => {
  CommonStore.update((s) => {
    s.isDrawerOpen = !s.isDrawerOpen;
  });
};
