import { Box } from '@mui/material';
import React, { useEffect } from 'react';
import { redirect } from "react-router-dom";


const AuthWrapper = ({ children }) => {
  const configuration = () => {
    if (!localStorage.getItem('user')) {
      redirect('/')
    }
  }

  useEffect(() => {
    configuration()
  }, [])


  return (
    <React.Fragment>
      <Box>
        {children}
      </Box>
    </React.Fragment>
  )
}

export default AuthWrapper;