import { Box } from "@mui/material";
import Typography from "@mui/material/Typography";
import CardTable from "Common/DataTable/CardTable";
import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";
import faker from "faker";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
    },
  },
};

const labels = [
  "03/10",
  "03/11",
  "03/12",
  "03/13",
  "03/14",
  "03/15",
  "03/16",
  "03/17",
  "03/18",
  "03/19",
  "03/20",
];

export const data = {
  labels,
  datasets: [
    {
      label: "Amount",
      data: labels.map(() => faker.datatype.number({ min: 100, max: 1000 })),
      borderColor: "rgb(255, 99, 132)",
      backgroundColor: "rgba(255, 99, 132, 0.5)",
    },
  ],
};

export default function HowCurrentMonthLineChart() {
  return (
    <>
      <Box
        sx={{
          p: 4,
        }}
      >
        <Typography variant="h6">Current Month Donation's</Typography>
        <Line options={options} data={data} />
      </Box>
    </>
  );
}
