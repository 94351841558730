import { Box, CircularProgress } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import LayoutHeaders from "Common/Layouts/LayoutHeaders";
import { useEffect, useState } from "react";
import { Responsive as ResponsiveGridLayout } from "react-grid-layout";
import { withSize } from "react-sizeme";
import { toast } from "react-toastify";
import apiService from "services/http-service/ApiService";
import {
  AdminDashboardLayoutStore,
  getAdminDashboardLayouts,
  setAdminCardLayoutIsChanged,
} from "services/store/GridTemplateLayoutStore/AdminDashboardLayoutStore";
import AdminWindowButton from "../AdminWindowButton";

function AdminContentPage({ size: { width } }) {
  const [isChange, setIsChange] = useState(false);
  const [layouts, setLayouts] = useState({});

  const isCardLayoutsChanged = AdminDashboardLayoutStore.useState(
    (s) => s.adminCardLayoutIsChanged
  );

  const [lstAdminDashboardElement, setLstAdminDashboardElement] =
    useState(null);

  const initialLayouts = AdminDashboardLayoutStore.useState(
    (s) => s.initialDashboardLayouts
  );

  const onLayoutSave = () => {
    saveToLS(layouts);
    toast.success("Layouts has been saved");
  };

  const onLayoutChange = (_, allLayouts) => {
    setLayouts(allLayouts);
  };

  const loadData = () => {
    const result = getAdminDashboardLayouts();
    setLstAdminDashboardElement(result);
  };

  useEffect(() => {
    loadData();
  }, []);
  

  useEffect(() => {
    if (isCardLayoutsChanged) {
      onLayoutChange();
      loadData();
      setAdminCardLayoutIsChanged(false);
    }
  }, [isCardLayoutsChanged]);

  useEffect(() => {
    if (lstAdminDashboardElement && lstAdminDashboardElement.length > 0) {
      if (getFromLS() && Object.keys(getFromLS()).length > 0) {
        setLayouts(getFromLS());
      } else {
        setLayouts(initialLayouts);
      }
    }
  }, [lstAdminDashboardElement]);

  const q = useQuery(["getAdminDashboardDetails"], async () => {
    return await apiService.getAdminDashboardInfo();
  });

  if (layouts && Object.keys(layouts).length <= 0)
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "90vh",
        }}
      >
        <CircularProgress />
      </Box>
    );

  return (
    <>
      <LayoutHeaders title={"Admin Dashboard"} onSave={onLayoutSave} />
      <ResponsiveGridLayout
        layouts={layouts}
        breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
        cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
        width={width}
        rowHeight={60}
        onLayoutChange={onLayoutChange}
      >
        {lstAdminDashboardElement &&
          lstAdminDashboardElement.length > 0 &&
          lstAdminDashboardElement.map(
            (item, index) =>
              !item.isClosed && (
                <Box key={item.key}>
                  <AdminWindowButton
                    isCard={item.isCard}
                    isDonationCard={item.isDonationCard}
                    overFlowNone={item.overFlowNone}
                    cardID={item.key}
                    isVisible={item.isVisible}
                  >
                    {item.element}
                  </AdminWindowButton>
                </Box>
              )
          )}
      </ResponsiveGridLayout>
    </>
  );
}

export default withSize()(AdminContentPage);

function getFromLS() {
  if (global.localStorage) {
    return (
      JSON.parse(global.localStorage.getItem("adminDashboardLayouts")) || null
    );
  }
}

function saveToLS(value) {
  if (global.localStorage) {
    global.localStorage.setItem("adminDashboardLayouts", JSON.stringify(value));
  }
}
