const initialState = {
    loader: false,
    lists: [],
}

function NotificationReducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case 'SET_LOADER':
            return { ...state, loader: action.loader }
        case 'RETRIEVE_HOWNOTIFICATION':
            return { ...state, lists: payload };
        default:
            return state;
    }
};
export default NotificationReducer;