import AuthGuard from "Common/AuthGuard/AuthGuard";
import BoxTable from "Common/DataTable/BoxTable";
import MainLayouts from "Common/Layouts/MainLayouts";
import AdminHowsDonorList from "./AdminHowsDonorList";
import AdminDonorList from "./AdminDonorList";

export default function AdminDonorSection() {
  return (
    <AuthGuard>
      <MainLayouts>
        <BoxTable>
          <AdminDonorList />
        </BoxTable>
        <BoxTable>
          <AdminHowsDonorList />
        </BoxTable>
      </MainLayouts>
    </AuthGuard>
  );
}
