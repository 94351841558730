import { AddBox } from "@material-ui/icons";
import { Dialog, DialogContent } from "@mui/material";
import DataTable from "Common/DataTable/DataTable";
import { useState } from "react";
import HowInviteChild from "../ChildHow/HowInviteChild";
import AddUpdateDonor from "./AddUpdateDonor";
export default function HowDonorsPage() {
  const [isOpen, setIsOpen] = useState(false);
  const [isAdding, setIsAdding] = useState(false);

  const toggle = () => {
    setIsOpen(false);
    setIsAdding(false);
  };
  return (
    <>
      <DataTable
        title="Donors"
        columns={[
          { title: "#", field: "id" },
          { title: "Profile", field: "profile" },
          {
            title: "First Name",
            field: "firstName",
          },
          { title: "Last Name", field: "lastName" },
          { title: "Email", field: "email" },
          { title: "Mobile", field: "mobile" },
          { title: "Joining Date", field: "joiningDate" },
        ]}
        actions={[
          {
            icon: () => {
              return <AddBox />;
            },
            tooltip: "Invite",
            isFreeAction: true,
            onClick: () => {
              setIsOpen(true);
            },
          },
          {
            icon: () => {
              return <AddBox />;
            },
            tooltip: "Add New",
            isFreeAction: true,
            onClick: () => {
              setIsAdding(true);
            },
          },
        ]}
        data={[]}
      />

      {isOpen && (
        <Dialog
          open={isOpen}
          onClose={toggle}
          style={{
            width: "450px",
            display: "block",
            margin: "auto",
          }}
        >
          <DialogContent>
            <HowInviteChild toggle={toggle} />
          </DialogContent>
        </Dialog>
      )}
      {isAdding && (
        <Dialog
          open={isAdding}
          onClose={toggle}
          fullWidth
          sx={{
            display: "block",
            margin: "auto",
            maxWidth: "sm",
            overflow: "hidden",
          }}
        >
          <DialogContent>
            <AddUpdateDonor toggle={toggle} />
          </DialogContent>
        </Dialog>
      )}
    </>
  );
}
