import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation } from "@tanstack/react-query";
import LoginScreenPage from "Common/LoginScreen/LoginScreenPage";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { howLoginImage } from "services/DefaultConfig";
import apiService from "services/http-service/ApiService";
import authService from "services/http-service/AuthService";
import * as yup from "yup";

const schema = yup.object().shape({
  userEmail: yup
    .string()
    .email("Invalid email address")
    .required("Email is required"),
  userPassword: yup
    .string()
    .min(4, "Password must be at least 4 characters")
    .required("Password is required"),
});

export default function HowLoginPage() {
  const navigate = useNavigate();
  const objForm = useForm({
    defaultValues: {
      userEmail: "",
      userPassword: "",
    },
    resolver: yupResolver(schema),
  });

  const loginAction = useMutation(async (data) => {
    if (data) {
      return await apiService.howUserLogin(data);
    }

    return null;
  });

  const onLogin = async (data) => {
    const io = {
      email: data.userEmail,
      password: data.userPassword,
    };
    const userResp = await loginAction.mutateAsync(io);

    if (userResp.success) {
      authService.setUserData(userResp.data?.how);
      authService.setToken(userResp.data?.token);
      toast.success("User successfully login");
      navigate("/how-dashboard");
      return null;
    }
  };

  return (
    <FormProvider {...objForm}>
      <LoginScreenPage
        title={"Holy House Login"}
        landingImage={howLoginImage}
        onLogin={onLogin}
        action={loginAction}
        signUpLink={"/holy-house-account-creation"}
        forgetPasswordLink={'/forgetPasswordHowAdmin'}
      />
    </FormProvider>
  );
}
