import axios from "axios";

class HttpService {
  constructor() {
    this.requests = [];
  }

  createAxiosConfig(url, headers, method, data) {
    const config = {
      url,
      method,
      headers: headers,
    };
    if (data) {
      config.data = data;
    }
    return config;
  }

  handleError(ex) {
    if (ex.response && ex.response.data) {
      throw ex.response.data;
    } else if (ex.response && ex.response.statusText) {
      throw new Error(
        `Http request is unable to complete, Status: ${ex.response.statusText}, Status Code: ${ex.response.status}`
      );
    } else if (typeof ex === "string") {
      throw new Error(ex);
    } else if (ex.length > 0) {
      throw new Error(ex[0]);
    } else if (ex instanceof Error) {
      throw ex;
    } else if (ex === undefined) {
      throw new Error("Undefined Error. ");
    } else {
      throw new Error("Unexpected Error. " + JSON.stringify(ex));
    }
  }

  handleSuccess(data) {
    return data;
  }

  async get(url, headers = {}) {
    try {
      const axiosConfig = this.createAxiosConfig(url, headers, "GET");
      const response = await axios(axiosConfig);
      if (response && response.data) {
        return this.handleSuccess(response.data);
      } else {
        return this.handleSuccess(response);
      }
    } catch (ex) {
      this.handleError(ex);
    }
  }

  async post(url, data, headers = {}) {
    headers = {
      "Content-Type": "application/json",
      ...headers,
    };
    try {
      const axiosConfig = this.createAxiosConfig(url, headers, "POST", data);
      const response = await axios(axiosConfig);
      if (response.status) return this.handleSuccess(response.data);
      else return this.handleError(response.error);
    } catch (ex) {
      this.handleError(ex);
    }
  }

  async put(url, data, headers = {}) {
    headers = { "Content-Type": "application/json", ...headers };
    try {
      const axiosConfig = this.createAxiosConfig(url, headers, "PUT", data);
      const response = await axios(axiosConfig);
      return this.handleSuccess(response.data);
    } catch (ex) {
      this.handleError(ex);
    }
  }

  async delete(url, data, headers = {}) {
    headers = { "Content-Type": "application/json", ...headers };
    try {
      const axiosConfig = this.createAxiosConfig(url, headers, "DELETE", data);
      const response = await axios(axiosConfig);
      return this.handleSuccess(response.data);
    } catch (ex) {
      this.handleError(ex);
    }
  }
}

const httpService = new HttpService();
export default httpService;
