import { DonationDonought } from "components/Admin/DonationDonought";
import { DonationPie } from "components/Admin/DonationPie";
import LastMonthLIneChart from "components/Admin/LastMonthLIneChart";
import AdminDashboardCard from "pages/Admin/AdminContent/AdminDashboardCard";
import { AdminDonationBarChart } from "pages/Admin/AdminContent/AdminDonationBarChart";
import { AdminDonationByState } from "pages/Admin/AdminContent/AdminDonationByState";
import { AdminDonationLineChart } from "pages/Admin/AdminContent/AdminDonationLineChart";
import AdminDonorInvitation from "pages/Admin/AdminContent/AdminDonorInvitations";
import AdminHowInvitations from "pages/Admin/AdminContent/AdminHowInvitations";
import AdminInvitedDonors from "pages/Admin/AdminContent/AdminInvitedDonors";
import AdminHowList from "pages/Admin/How/AdminHowList";
import { Store } from "pullstate";
// const q = useQuery(["getAdminDashboardDetails"], async () => {
//   return await apiService.getAdminDashboardInfo();
// });

export const AdminDashboardLayoutStore = new Store({
  minimizeWindow: false,
  reStoreWindow: true,
  closeWindow: false,
  selectedCardKey: null,
  adminCardLayoutIsChanged: false,
  initialDashboardLayouts: {
    lg: [
      {
        w: 4,
        h: 6,
        x: 8,
        y: 0,
        i: "1",
        moved: false,
        static: false,
      },
      {
        w: 2,
        h: 3,
        x: 0,
        y: 0,
        i: "2",
        moved: false,
        static: false,
      },
      {
        w: 2,
        h: 3,
        x: 0,
        y: 3,
        i: "3",
        moved: false,
        static: false,
      },
      {
        w: 2,
        h: 3,
        x: 2,
        y: 3,
        i: "4",
        moved: false,
        static: false,
      },
      {
        w: 2,
        h: 3,
        x: 2,
        y: 0,
        i: "5",
        moved: false,
        static: false,
      },
      {
        w: 4,
        h: 5,
        x: 4,
        y: 6,
        i: "6",
        moved: false,
        static: false,
      },
      {
        w: 4,
        h: 5,
        x: 0,
        y: 6,
        i: "7",
        moved: false,
        static: false,
      },
      {
        w: 2,
        h: 6,
        x: 4,
        y: 0,
        i: "8",
        moved: false,
        static: false,
      },
      {
        w: 2,
        h: 6,
        x: 6,
        y: 0,
        i: "9",
        moved: false,
        static: false,
      },
      {
        w: 4,
        h: 5,
        x: 8,
        y: 6,
        i: "10",
        moved: false,
        static: false,
      },
      {
        w: 6,
        h: 10,
        x: 0,
        y: 21,
        i: "11",
        moved: false,
        static: false,
      },
      {
        w: 6,
        h: 10,
        x: 0,
        y: 11,
        i: "12",
        moved: false,
        static: false,
      },
      {
        w: 6,
        h: 10,
        x: 6,
        y: 11,
        i: "13",
        moved: false,
        static: false,
      },
      {
        w: 6,
        h: 10,
        x: 6,
        y: 21,
        i: "14",
        moved: false,
        static: false,
      },
    ],
    md: [
      {
        w: 6,
        h: 6,
        x: 4,
        y: 0,
        i: "1",
        moved: false,
        static: false,
      },
      {
        w: 2,
        h: 3,
        x: 0,
        y: 0,
        i: "2",
        moved: false,
        static: false,
      },
      {
        w: 2,
        h: 3,
        x: 0,
        y: 3,
        i: "3",
        moved: false,
        static: false,
      },
      {
        w: 2,
        h: 3,
        x: 2,
        y: 3,
        i: "4",
        moved: false,
        static: false,
      },
      {
        w: 2,
        h: 3,
        x: 2,
        y: 0,
        i: "5",
        moved: false,
        static: false,
      },
      {
        w: 5,
        h: 6,
        x: 5,
        y: 6,
        i: "6",
        moved: false,
        static: false,
      },
      {
        w: 5,
        h: 6,
        x: 0,
        y: 6,
        i: "7",
        moved: false,
        static: false,
      },
      {
        w: 5,
        h: 4,
        x: 5,
        y: 12,
        i: "8",
        moved: false,
        static: false,
      },
      {
        w: 5,
        h: 4,
        x: 0,
        y: 12,
        i: "9",
        moved: false,
        static: false,
      },
      {
        w: 5,
        h: 5,
        x: 0,
        y: 16,
        i: "10",
        moved: false,
        static: false,
      },
      {
        w: 10,
        h: 8,
        x: 0,
        y: 21,
        i: "11",
        moved: false,
        static: false,
      },
      {
        w: 5,
        h: 5,
        x: 5,
        y: 16,
        i: "12",
        moved: false,
        static: false,
      },
      {
        w: 10,
        h: 9,
        x: 0,
        y: 29,
        i: "13",
        moved: false,
        static: false,
      },
      {
        w: 10,
        h: 5,
        x: 0,
        y: 38,
        i: "14",
        moved: false,
        static: false,
      },
    ],
    sm: [
      {
        w: 3,
        h: 4,
        x: 0,
        y: 11,
        i: "1",
        moved: false,
        static: false,
      },
      {
        w: 2,
        h: 3,
        x: 0,
        y: 0,
        i: "2",
        moved: false,
        static: false,
      },
      {
        w: 2,
        h: 3,
        x: 0,
        y: 3,
        i: "3",
        moved: false,
        static: false,
      },
      {
        w: 2,
        h: 3,
        x: 2,
        y: 0,
        i: "4",
        moved: false,
        static: false,
      },
      {
        w: 2,
        h: 3,
        x: 2,
        y: 3,
        i: "5",
        moved: false,
        static: false,
      },
      {
        w: 4,
        h: 5,
        x: 0,
        y: 6,
        i: "6",
        moved: false,
        static: false,
      },
      {
        w: 3,
        h: 4,
        x: 3,
        y: 11,
        i: "7",
        moved: false,
        static: false,
      },
      {
        w: 2,
        h: 6,
        x: 4,
        y: 0,
        i: "8",
        moved: false,
        static: false,
      },
      {
        w: 2,
        h: 5,
        x: 4,
        y: 6,
        i: "9",
        moved: false,
        static: false,
      },
      {
        w: 6,
        h: 7,
        x: 0,
        y: 15,
        i: "10",
        moved: false,
        static: false,
      },
      {
        w: 6,
        h: 6,
        x: 0,
        y: 22,
        i: "11",
        moved: false,
        static: false,
      },
      {
        w: 6,
        h: 5,
        x: 0,
        y: 34,
        i: "12",
        moved: false,
        static: false,
      },
      {
        w: 6,
        h: 6,
        x: 0,
        y: 28,
        i: "13",
        moved: false,
        static: false,
      },
      {
        w: 6,
        h: 6,
        x: 0,
        y: 39,
        i: "14",
        moved: false,
        static: false,
      },
    ],
    xs: [
      {
        w: 4,
        h: 5,
        x: 0,
        y: 8,
        i: "1",
        moved: false,
        static: false,
      },
      {
        w: 1,
        h: 3,
        x: 0,
        y: 0,
        i: "2",
        moved: false,
        static: false,
      },
      {
        w: 1,
        h: 3,
        x: 2,
        y: 0,
        i: "3",
        moved: false,
        static: false,
      },
      {
        w: 1,
        h: 3,
        x: 1,
        y: 0,
        i: "4",
        moved: false,
        static: false,
      },
      {
        w: 1,
        h: 3,
        x: 3,
        y: 0,
        i: "5",
        moved: false,
        static: false,
      },
      {
        w: 4,
        h: 6,
        x: 0,
        y: 13,
        i: "6",
        moved: false,
        static: false,
      },
      {
        w: 4,
        h: 5,
        x: 0,
        y: 19,
        i: "7",
        moved: false,
        static: false,
      },
      {
        w: 2,
        h: 5,
        x: 2,
        y: 3,
        i: "8",
        moved: false,
        static: false,
      },
      {
        w: 2,
        h: 5,
        x: 0,
        y: 3,
        i: "9",
        moved: false,
        static: false,
      },
      {
        w: 4,
        h: 5,
        x: 0,
        y: 24,
        i: "10",
        moved: false,
        static: false,
      },
      {
        w: 4,
        h: 8,
        x: 0,
        y: 29,
        i: "11",
        moved: false,
        static: false,
      },
      {
        w: 4,
        h: 6,
        x: 0,
        y: 37,
        i: "12",
        moved: false,
        static: false,
      },
      {
        w: 4,
        h: 10,
        x: 0,
        y: 43,
        i: "13",
        moved: false,
        static: false,
      },
      {
        w: 4,
        h: 6,
        x: 0,
        y: 53,
        i: "14",
        moved: false,
        static: false,
      },
    ],
    xxs: [
      {
        w: 2,
        h: 4,
        x: 0,
        y: 6,
        i: "1",
        moved: false,
        static: false,
      },
      {
        w: 1,
        h: 3,
        x: 0,
        y: 0,
        i: "2",
        moved: false,
        static: false,
      },
      {
        w: 1,
        h: 3,
        x: 1,
        y: 0,
        i: "3",
        moved: false,
        static: false,
      },
      {
        w: 1,
        h: 3,
        x: 0,
        y: 3,
        i: "4",
        moved: false,
        static: false,
      },
      {
        w: 1,
        h: 3,
        x: 1,
        y: 3,
        i: "5",
        moved: false,
        static: false,
      },
      {
        w: 2,
        h: 4,
        x: 0,
        y: 10,
        i: "6",
        moved: false,
        static: false,
      },
      {
        w: 2,
        h: 4,
        x: 0,
        y: 14,
        i: "7",
        moved: false,
        static: false,
      },
      {
        w: 1,
        h: 5,
        x: 0,
        y: 18,
        i: "8",
        moved: false,
        static: false,
      },
      {
        w: 1,
        h: 5,
        x: 1,
        y: 18,
        i: "9",
        moved: false,
        static: false,
      },
      {
        w: 2,
        h: 4,
        x: 0,
        y: 23,
        i: "10",
        moved: false,
        static: false,
      },
      {
        w: 2,
        h: 5,
        x: 0,
        y: 27,
        i: "11",
        moved: false,
        static: false,
      },
      {
        w: 2,
        h: 7,
        x: 0,
        y: 32,
        i: "12",
        moved: false,
        static: false,
      },
      {
        w: 2,
        h: 10,
        x: 0,
        y: 39,
        i: "13",
        moved: false,
        static: false,
      },
      {
        w: 2,
        h: 6,
        x: 0,
        y: 49,
        i: "14",
        moved: false,
        static: false,
      },
    ],
  },
  lstAdminDashboardContent: [
    {
      key: "1",
      element: <AdminDonationBarChart />,
      isVisible: true,
      isClosed: false,
      isCard: true,
      name: "Donations",
    },
    {
      key: "2",
      element: <AdminDashboardCard isHow={true} title="Holy Houses" />,
      isVisible: true,
      isClosed: false,
      isDonationCard: true,
      name: "Holy Houses",
    },
    {
      key: "3",
      element: <AdminDashboardCard totalDonors={true} title="Total Donors" />,
      isVisible: true,
      isClosed: false,
      isDonationCard: true,
      name: "Total Donors",
    },
    {
      key: "4",
      element: (
        <AdminDashboardCard totalDonation={true} title="Total Donation" />
      ),
      isVisible: true,
      isClosed: false,
      isDonationCard: true,
      name: "Total Donation",
    },
    {
      key: "5",
      element: (
        <AdminDashboardCard todayDonation={true} title="Today's Donation" />
      ),
      isVisible: true,
      isClosed: false,
      isDonationCard: true,
      name: "Today's Donation",
    },
    {
      key: "6",
      element: <AdminDonationLineChart />,
      isVisible: true,
      isClosed: false,
      isCard: true,
      name: "Total Donation",
    },
    {
      key: "7",
      element: <AdminDonationByState />,
      isVisible: true,
      isClosed: false,
      isCard: true,
      name: "Total Donation",
    },
    {
      key: "8",
      element: <DonationPie />,
      isVisible: true,
      isClosed: false,
      overFlowNone: true,
      isCard: true,
      name: "Donation Pi Chart",
    },
    {
      key: "9",
      element: <DonationDonought />,
      isVisible: true,
      overFlowNone: true,
      isClosed: false,
      isCard: true,
      name: "Donation Donought",
    },
    {
      key: "10",
      element: <LastMonthLIneChart />,
      isVisible: true,
      isClosed: false,
      isCard: true,
      name: "Last Month Line Chart",
    },
    {
      key: "11",
      element: <AdminHowList />,
      isVisible: true,
      isClosed: false,
      isCard: true,
      name: "Total HoWs",
    },
    {
      key: "12",
      element: <AdminHowInvitations />,
      isVisible: true,
      isClosed: false,
      isCard: true,
      name: "HoWs Invitations",
    },
    {
      key: "13",
      element: <AdminInvitedDonors />,
      isVisible: true,
      isClosed: false,
      isCard: true,
      name: "Invited Donor",
    },
    {
      key: "14",
      element: <AdminDonorInvitation />,
      isVisible: true,
      isClosed: false,
      isCard: true,
      name: "Donor Invitations",
    },
  ],
  removedDashboardLayouts: [],
});

export const setAdminCardLayoutIsChanged = (isChanged) => {
  AdminDashboardLayoutStore.update((s) => {
    s.adminCardLayoutIsChanged = isChanged;
  });
};

export const handleMinimize = (cardID) => {
  AdminDashboardLayoutStore.update((s) => {
    s.lstAdminDashboardContent = s.lstAdminDashboardContent.map((item) => {
      if (parseInt(item.key) === parseInt(cardID)) {
        return {
          ...item,
          isVisible: !item.isVisible,
        };
      }
      return item;
    });
  });

  setAdminCardLayoutIsChanged(true);
};

export const adminCardClose = (cardID) => {
  AdminDashboardLayoutStore.update((s) => {
    s.lstAdminDashboardContent = s.lstAdminDashboardContent.map((item) => {
      if (parseInt(item.key) === parseInt(cardID)) {
        return {
          ...item,
          isClosed: !item.isClosed,
        };
      } else {
        return item;
      }
    });
  });
  getAdminDashboardLayouts();
  setAdminCardLayoutIsChanged(true);
};

export const getAdminDashboardLayouts = () =>
  AdminDashboardLayoutStore.getRawState().lstAdminDashboardContent;
