import AuthGuard from "Common/AuthGuard/AuthGuard";
import InitialRoundedLoading from "Common/InitialRoundedLoading";

export default function IndexPage() {
  return (
    <AuthGuard>
      <InitialRoundedLoading />
    </AuthGuard>
  );
}
