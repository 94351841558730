import AuthGuard from "Common/AuthGuard/AuthGuard";
import BoxTable from "Common/DataTable/BoxTable";
import MainLayouts from "Common/Layouts/MainLayouts";
import HowAllDonor from "./HowAllDonor";
import HowDonorInvitation from "./HowDonorInvitation";
import HowSuspendedDonor from "./HowSuspendedDonor";

export default function HowDonorSection() {
  return (
    <AuthGuard>
      <MainLayouts>
        <BoxTable>
          <HowAllDonor />
        </BoxTable>
        <BoxTable>
          <HowDonorInvitation />
        </BoxTable>
        <BoxTable>
          <HowSuspendedDonor />
        </BoxTable>
      </MainLayouts>
    </AuthGuard>
  );
}
