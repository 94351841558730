import AuthGuard from "Common/AuthGuard/AuthGuard";
import MainLayouts from "Common/Layouts/MainLayouts";
import DonorHolyHouseWidget from "./DonorContent/DonorHolyHouseWidget";
import BoxTable from "Common/DataTable/BoxTable";

export default function DonorHows() {
  return (
    <AuthGuard>
      <MainLayouts>
        <BoxTable>
          <DonorHolyHouseWidget />
        </BoxTable>
      </MainLayouts>
    </AuthGuard>
  );
}
