import AuthGuard from "Common/AuthGuard/AuthGuard";
import BoxTable from "Common/DataTable/BoxTable";
import MainLayouts from "Common/Layouts/MainLayouts";
import AdminHowInvitations from "../AdminContent/AdminHowInvitations";
import AdminHowList from "./AdminHowList";

export default function AdminHowSection() {
  return (
    <AuthGuard>
      <MainLayouts>
        <BoxTable>
          <AdminHowList />
        </BoxTable>
        <BoxTable>
          <AdminHowInvitations />
        </BoxTable>
      </MainLayouts>
    </AuthGuard>
  );
}
