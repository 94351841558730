import { useState } from "react";

import {
  Check,
  Payment,
  Receipt,
  VolunteerActivism,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  FormControl,
  FormHelperText,
  InputLabel,
  OutlinedInput,
  Stack,
  Step,
  StepLabel,
  Stepper,
  styled,
} from "@mui/material";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import CardTable from "Common/DataTable/CardTable";
import { Formik } from "formik";
import useScriptRef from "hooks/useScriptRef";
import * as Yup from "yup";

const QontoStepIconRoot = styled("div")(({ ownerState }) => ({
  display: "flex",
  height: 22,
  alignItems: "center",
  ...(ownerState.active && {
    color: "#784af4",
  }),
  "& .QontoStepIcon-completedIcon": {
    color: "#784af4",
    zIndex: 1,
    fontSize: 18,
  },
  "& .QontoStepIcon-circle": {
    width: 8,
    height: 8,
    borderRadius: "50%",
    backgroundColor: "currentColor",
  },
}));

function QontoStepIcon(props) {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <Check className="QontoStepIcon-completedIcon" />
      ) : (
        <div className="QontoStepIcon-circle" />
      )}
    </QontoStepIconRoot>
  );
}

const ColorlibConnector = styled(StepConnector)(({}) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: "#3F51B5",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: "#3F51B5",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    borderRadius: 1,
  },
}));

const StepContainerStyledBox = styled("div")(({ ownerState }) => ({
  zIndex: 1,
  color: "#fff",
  width: 50,
  height: 50,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  backgroundColor: "#3F51B5",
  ...(ownerState.completed && {
    backgroundColor: "#4BB543",
  }),
}));

function ColorlibStepIcon(props) {
  const { active, completed, className } = props;

  const icons = {
    1: <VolunteerActivism />,
    2: <Payment />,
    3: <Receipt />,
  };

  return (
    <StepContainerStyledBox
      ownerState={{ completed, active }}
      className={className}
    >
      {icons[String(props.icon)]}
    </StepContainerStyledBox>
  );
}

const steps = ["Enter Donation Amount ", "Make Payment", "Get Payment Details"];

const DonorDonateCamp = () => {
  const [activestate, setactivestate] = useState(0);
  const [showPassword, setShowPassword] = useState(false);
  const scriptedRef = useScriptRef();

  return (
    <>
      <Card
        sx={{
          pt: 4,
          pb: 3,
          pl: 2,
          pr: 2,
        }}
      >
        <Stepper
          alternativeLabel
          activeStep={activestate}
          connector={<ColorlibConnector />}
        >
          {steps.map((label) => (
            <Step key={label}>
              {" "}
              <StepLabel StepIconComponent={ColorlibStepIcon}>
                {label}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </Card>

      <Box sx={{ p: 3 }}>
        <Formik
          initialValues={{
            amount: 0,
            submit: null,
          }}
          validationSchema={Yup.object().shape({
            amount: Yup.number().required("amount is required"),
          })}
          onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
            try {
              if (scriptedRef.current) {
                setStatus({ success: true });
                setSubmitting(false);
              }
            } catch (err) {
              console.error(err);
              if (scriptedRef.current) {
                setStatus({ success: false });
                setErrors({ submit: err.message });
                setSubmitting(false);
              }
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
          }) => (
            <form onSubmit={handleSubmit}>
              <Box>
                {activestate === 0 ? (
                  <>
                    <Box sx={{ py: 4, textAlign: "center" }}>
                      <h2>Enter Donation Amount </h2>
                    </Box>
                    <FormControl fullWidth>
                      <InputLabel htmlFor="amount">Enter amount</InputLabel>
                      <OutlinedInput
                        id="amount"
                        type="number"
                        name="amount"
                        value={values.amount}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Enter amount"
                      />
                    </FormControl>
                    {touched.amount && errors.amount && (
                      <FormHelperText
                        error
                        id="standard-weight-helper-text-email-login"
                      >
                        {errors.amount}
                      </FormHelperText>
                    )}
                  </>
                ) : null}

                {activestate === 1 ? (
                  <Box sx={{ py: 4, textAlign: "center" }}>
                    <h2>Make Payments</h2>
                  </Box>
                ) : null}
                {activestate === 2 ? (
                  <Box sx={{ py: 4, textAlign: "center" }}>
                    <h2>Get Donation Details</h2>
                  </Box>
                ) : null}
              </Box>
              <Stack
                spacing={2}
                direction="row"
                justifyContent={"space-between"}
                style={{
                  marginTop: "1rem",
                }}
              >
                <Box style={{ flex: 1 }}>
                  <Button
                    onClick={() => setactivestate(activestate - 1)}
                    disabled={activestate === 0 ? true : false}
                    disableElevation
                    fullWidth
                    type="button"
                    variant="contained"
                    color="secondary"
                  >
                    Back
                  </Button>
                </Box>
                <Box style={{ flex: 1 }}>
                  <Button
                    onClick={() => setactivestate(activestate + 1)}
                    disabled={!values.amount}
                    disableElevation
                    fullWidth
                    type="button"
                    variant="contained"
                    color="secondary"
                  >
                    Next
                  </Button>
                </Box>
              </Stack>
            </form>
          )}
        </Formik>
      </Box>
    </>
  );
};

export default DonorDonateCamp;
