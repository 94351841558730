import { AccountCircle, Logout, Settings } from "@mui/icons-material";
import { Divider, ListItemButton, Menu, Typography } from "@mui/material";
import { useNavigate } from "react-router";
import authService from "services/http-service/AuthService";

export default function UserProfileMenu({
  anchorElUser,
  configureUserProfileAddress,
  handleCloseUserMenu,
}) {
  const navigate = useNavigate();
  return (
    <Menu
      sx={{
        marginTop: "25px",
        marginLeft: "-5px !important",
        position: "absolute",
        zIndex: 15115,
      }}
      id="menu-appbar"
      anchorEl={anchorElUser}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={Boolean(anchorElUser)}
      onClose={handleCloseUserMenu}
    >
      <ListItemButton
        sx={{
          padding: "8px !important",
        }}
        onClickCapture={() => {
          configureUserProfileAddress();
          handleCloseUserMenu();
        }}
      >
        <AccountCircle sx={{ mr: 1 }} />
        <Typography variant="body1">Profile</Typography>
      </ListItemButton>
      <Divider />
      <ListItemButton
        sx={{
          padding: "8px !important",
        }}
        onClick={() => {
          handleCloseUserMenu();
        }}
      >
        <Settings sx={{ mr: 1 }} />
        <Typography variant="body1">Setting</Typography>
      </ListItemButton>
      <ListItemButton
        sx={{
          padding: "8px !important",
        }}
        onClick={() => {
          authService.userLogout();
          handleCloseUserMenu();
          navigate("/");
          window.location.reload();
          return null;
        }}
      >
        <Logout sx={{ mr: 1 }} />
        <Typography variant="body1">Logout</Typography>
      </ListItemButton>
    </Menu>
  );
}
