import { CreditCard, Dashboard, Event } from "@material-ui/icons";
import {
  HelpCenter,
  House,
  InsertInvitation,
  Payment,
  People,
  VerifiedUser,
} from "@mui/icons-material";

export const howAdminMenu = [
  { name: "Dashboard", link: "/how-dashboard", icon: <Dashboard /> },
  { name: "House Of Worship", link: "/how-house_of_worship", icon: <House /> },
  { name: "Donors", link: "/how-donors", icon: <People /> },
  { name: "Events", link: "/how-events", icon: <Event /> },
  { name: "Support", link: "/how-support", icon: <HelpCenter /> },
  { name: "Payments", link: "/how-payments", icon: <CreditCard /> },
  { name: "Revenue", link: "/how-revenue", icon: <CreditCard /> },
];

export const adminMenu = [
  { name: "Dashboard", link: "/dashboard", icon: <Dashboard /> },
  { name: "Admin Users", link: "adminUsers", icon: <VerifiedUser /> },
  {
    name: "Holy House",
    link: "holyHouse",
    icon: <House />,
  },
  { name: "Donors", link: "donors", icon: <People /> },
  { name: "Events", link: "events", icon: <Event /> },
  { name: "Explore", link: "explore", icon: <HelpCenter /> },
  { name: "Favorites", link: "favorites", icon: <CreditCard /> },
];

export const donorMenu = [
  { name: "Dashboard", link: "/", icon: <Dashboard /> },
  { name: "House Of Worship", link: "/houseOfWorship", icon: <House /> },
  { name: "Donates", link: "/donates", icon: <Payment /> },
  { name: "Invitation", link: "/invitations", icon: <InsertInvitation /> },
  { name: "Payment", link: "/payments", icon: <CreditCard /> },
];
