import { Box, Grid, Typography } from "@mui/material";
import utils from "services/Utils";

export default function ViewAdminUserInfo(props) {
  const data = props.objItem.howAdmin;
  const userInfo = props.objItem;

  return (
    <Box
      sx={{
        p: 2,
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography fontWeight={"bold"} variant="h6">
            User Information
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography fontWeight={"bold"} variant="body1">
            Created Date :
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="body1">
            {utils.getDDMMYYYY(userInfo.createdAt) || "N/A"}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography fontWeight={"bold"} variant="body1">
            First Name :
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="body1">
            {userInfo.first_name || "N/A"}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="body1" fontWeight={"bold"}>
            Last Name :
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="body1">{userInfo.last_name || "N/A"}</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="body1" fontWeight={"bold"}>
            Description :
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="body1">{data.how_desc || "N/A"}</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="body1" fontWeight={"bold"}>
            Email Add :
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="body1">{userInfo.email || "N/A"}</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="body1" fontWeight={"bold"}>
            Contact No :
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="body1">{userInfo.mobile || "N/A"}</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="body1" fontWeight={"bold"}>
            City :
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="body1">{data.city || "N/A"}</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="body1" fontWeight={"bold"}>
            State :
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="body1">{data.state || "N/A"}</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="body1" fontWeight={"bold"}>
            Zip Code :
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="body1">{data.zipcode || "N/A"}</Typography>
        </Grid>
        {/* <Grid item xs={4}>
          <Typography variant="body1" fontWeight={"bold"}>
            Country :
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="body1">{data.country_code || "N/A"}</Typography>
        </Grid> */}
        <Grid item xs={4}>
          <Typography variant="body1" fontWeight={"bold"}>
            Status :
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="body1">
            {userInfo.isActive ? "Active" : "Not-Active" || "N/A"}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
}
