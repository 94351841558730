import { AddBox, Delete, Edit } from "@mui/icons-material";
import { Dialog, DialogContent, useMediaQuery } from "@mui/material";
import { useMutation, useQuery } from "@tanstack/react-query";
import DataTable from "Common/DataTable/DataTable";
import { format } from "date-fns";
import AddUpdateEvent from "pages/How/HowEvents/AddUpdateEvent";
import { useState } from "react";
import { toast } from "react-toastify";
import utils from "services/Utils";
import apiService from "services/http-service/ApiService";
import { CommonStore } from "services/store/CommonStore/PullStore";
import mainTheme from "styles/theme";

export default function AdminAllEvents() {
  const [isEdit, setIsEdit] = useState(false);
  const [isview, setIsView] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [objItem, setObjItem] = useState({});
  const isMediumScreen = useMediaQuery(mainTheme.breakpoints.down("md"));
  const isChildHowListChanged = CommonStore.useState(
    (s) => s.isChildHowListChanged
  );

  const deleteAction = useMutation(async (eventID) => {
    return await apiService.deleteAdminEvent(eventID);
  });

  const toggle = () => {
    setIsEdit(false);
    setIsOpen(false);
  };

  const q = useQuery(
    ["getAllEventForAdmin", isChildHowListChanged],
    async () => {
      return await apiService.getAllEventForAdmin();
    }
  );

  const onComplete = () => {
    q.refetch();
  };

  const deleteEventAction = async (eventID) => {
    try {
      const isValid = await utils.showWarning(
        "Do you want to delete this record?"
      );

      if (isValid.isConfirmed) {
        const result = await deleteAction.mutateAsync(eventID);
        if (!result.success) {
          throw new Error(result.error.message);
          return null;
        }

        if (result.success) {
          toast.success(result.data);
          q.refetch();
        }
      }
    } catch (ex) {
      if (ex && ex.error && ex.error.message) {
        toast.error(ex.error.message);
        return null;
      } else {
        toast.error(ex.message);
      }
    }
  };

  return (
    <>
      <DataTable
        title="All Events"
        isLoading={deleteAction.isLoading || q.isLoading || q.isRefetching}
        isError={q.isError}
        errorMessage={
          q.isError
            ? (q.error && q.error.error && q.error.error.message) ||
              "Errors occurred while getting event list! please contact admin."
            : null
        }
        columns={[
          {
            title: "Created Date",
            field: "createdAt",
            render: (rowData) => {
              return format(new Date(rowData.createdAt), "dd/MM/yyyy");
            },
          },
          { title: "Title", field: "title" },
          { title: "Description", field: "desc" },
          { title: "Time", field: "time" },
        ]}
        data={(q.data && q.data.data && q.data.data.rows) || []}
        onReload={() => {
          q.refetch();
        }}
        actions={[
          {
            icon: () => {
              return <AddBox />;
            },
            tooltip: "Add Event",
            isFreeAction: true,
            onClick: () => {
              setIsOpen(true);
              setIsEdit(false);
              setObjItem(null);
            },
          },
          {
            icon: () => {
              return <Edit color="primary" />;
            },
            tooltip: "Edit Information",
            onClick: (event, rowData) => {
              setObjItem(rowData);
              setIsOpen(true);
              setIsEdit(true);
            },
          },
          {
            icon: () => {
              return <Delete color="error" />;
            },
            tooltip: "Delete Event",
            onClick: (e, rowData) => {
              deleteEventAction(rowData.eventid);
            },
          },
        ]}
      />

      {isOpen && (
        <Dialog
          open={isOpen}
          onClose={toggle}
          fullWidth
          sx={{
            maxWidth: isMediumScreen ? "100%" : "50%",
            display: "block",
            margin: "auto",
          }}
        >
          <DialogContent>
            <AddUpdateEvent
              isAdmin={true}
              objItem={objItem}
              onComplete={onComplete}
              toggle={toggle}
              isEdit={isEdit}
            />
          </DialogContent>
        </Dialog>
      )}
    </>
  );
}
