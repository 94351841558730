import { AddBox } from "@material-ui/icons";
import { Delete } from "@mui/icons-material";
import { Dialog, DialogContent, useMediaQuery } from "@mui/material";
import { useMutation, useQuery } from "@tanstack/react-query";
import DataTable from "Common/DataTable/DataTable";
import { format } from "date-fns";
import { useState } from "react";
import { toast } from "react-toastify";
import utils from "services/Utils";
import apiService from "services/http-service/ApiService";
import mainTheme from "styles/theme";
import AddUpdateInvitation from "./AddUpdateInvitation";

export default function HowInvitationPage() {
  const isSmallScreen = useMediaQuery(mainTheme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery(mainTheme.breakpoints.down("md"));
  const [isOpen, setIsOpen] = useState(false);
  const deleteAction = useMutation(async (invitationID) => {
    return await apiService.removeChildHowInvitation(invitationID);
  });

  const { data, isError, error, refetch, isLoading, isRefetching } = useQuery(
    ["getInviteChildHowList"],
    async () => {
      const result = await apiService.getInviteChildHowList();
      return result.data;
    }
  );

  const toggle = () => {
    setIsOpen(false);
  };

  const onComplete = () => {
    setIsOpen(false);
    refetch();
  };

  const deleteInvitation = async (invitationID) => {
    try {
      const isValid = await utils.showWarning(
        "Do you want to delete this invitation?"
      );
      if (isValid.isConfirmed) {
        const result = await deleteAction.mutateAsync(invitationID);
        if (result.success) {
          toast.success(result.data);
          refetch();
        } else {
          throw new Error(result.message);
        }
      }
    } catch (ex) {
      toast.error(ex.message);
    }
  };

  return (
    <>
      <DataTable
        title="Child How Invitations"
        onReload={() => {
          refetch();
        }}
        isLoading={isRefetching  || deleteAction.isLoading || isLoading}
        isError={isError}
        errorMessage={
          isError
            ? (error && error.error && error.error.message) ||
              "Some error occurred! Please contact admin."
            : null
        }
        columns={[
          {
            title: "Full Name",
            field: "firstname",
          },
          {
            title: "Email",
            field: "email",
          },
          {
            title: "Invite Date",
            field: "createdAt",
            render: (rowData) => {
              return format(new Date(rowData.createdAt), "dd/MM/yyyy");
            },
          },
        ]}
        actions={[
          {
            icon: () => {
              return <AddBox />;
            },
            tooltip: "Invite",
            isFreeAction: true,
            onClick: () => {
              setIsOpen(true);
            },
          },
          {
            icon: () => {
              return <Delete color="error" />;
            },
            tooltip: "Delete",
            style: {
              justifyContent: "center",
            },
            onClick: (e, rowData) => {
              deleteInvitation(rowData.id);
            },
          },
        ]}
        data={(data && data.rows) || []}
      />

      {isOpen && (
        <Dialog
          open={isOpen}
          onClose={toggle}
          style={{
            display: "block",
            margin: "auto",
            maxWidth: isMediumScreen ? "100%" : "30%",
          }}
        >
          <DialogContent>
            <AddUpdateInvitation onComplete={onComplete} toggle={toggle} />
          </DialogContent>
        </Dialog>
      )}
    </>
  );
}
