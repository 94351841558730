import {
  Card,
  CardContent,
  CardHeader,
  Chip,
  Grid,
  Typography,
} from "@mui/material";
import AuthGuard from "Common/AuthGuard/AuthGuard";
import BoxTable from "Common/DataTable/BoxTable";
import MainLayouts from "Common/Layouts/MainLayouts";
import utils from "services/Utils";
import authService from "services/http-service/AuthService";

export default function AdminProfilePage() {
  const userProfile = authService.getUserData();
  console.log(userProfile);
  return (
    <AuthGuard>
      <MainLayouts>
        <BoxTable>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Card>
                <CardHeader
                  title={
                    <Typography
                      sx={{
                        fontSize: 20,
                        fontWeight: "bold",
                      }}
                    >
                      User Information
                    </Typography>
                  }
                />
                <CardContent sx={{ overflow: "auto", margin: "14px" }}>
                  <Grid container spacing={2}>
                    <Grid item xs={6} md={3}>
                      <Typography variant="body1" fontWeight={"bold"}>
                        First Name :
                      </Typography>
                    </Grid>
                    <Grid item xs={6} md={9}>
                      <Typography variant="body1">
                        {utils.firstLetterToCapital(userProfile.first_name) ||
                          "N/A"}
                      </Typography>
                    </Grid>
                    <Grid item xs={6} md={3}>
                      <Typography variant="body1" fontWeight={"bold"}>
                        Last Name :
                      </Typography>
                    </Grid>
                    <Grid item xs={6} md={9}>
                      <Typography variant="body1">
                        {utils.firstLetterToCapital(userProfile.last_name) ||
                          "N/A"}
                      </Typography>
                    </Grid>
                    <Grid item xs={6} md={3}>
                      <Typography variant="body1" fontWeight={"bold"}>
                        Email :
                      </Typography>
                    </Grid>
                    <Grid item xs={6} md={9}>
                      <Typography variant="body1">
                        {userProfile.email || "N/A"}
                      </Typography>
                    </Grid>
                    <Grid item xs={6} md={3}>
                      <Typography variant="body1" fontWeight={"bold"}>
                        Phone Number :
                      </Typography>
                    </Grid>
                    <Grid item xs={6} md={9}>
                      <Typography variant="body1">
                        {userProfile.mobile || "N/A"}
                      </Typography>
                    </Grid>
                    <Grid item xs={6} md={3}>
                      <Typography variant="body1" fontWeight={"bold"}>
                        Refer Code :
                      </Typography>
                    </Grid>
                    <Grid item xs={6} md={9}>
                      <Typography variant="body1">
                        {userProfile.refercode || "N/A"}
                      </Typography>
                    </Grid>
                    <Grid item xs={6} md={3}>
                      <Typography variant="body1" fontWeight={"bold"}>
                        User Active :
                      </Typography>
                    </Grid>
                    <Grid item xs={6} md={9}>
                      {userProfile.isActive ? (
                        <Chip label="Active" color="primary" />
                      ) : (
                        <Chip label="Not Active" color="error" />
                      )}
                    </Grid>
                    <Grid item xs={6} md={3}>
                      <Typography variant="body1" fontWeight={"bold"}>
                        User Availability :
                      </Typography>
                    </Grid>
                    <Grid item xs={6} md={9}>
                      {userProfile.isDeleted ? (
                        <Chip label="User has been deleted" color="error" />
                      ) : (
                        <Chip label="Available" color="success" />
                      )}
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </BoxTable>
      </MainLayouts>
    </AuthGuard>
  );
}
