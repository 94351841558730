import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import { Box, Button, Divider, Grid, Typography } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import ErrorPlaceHolder from "Common/ErrorPlaceHolder";
import HRoundedTextField from "Common/Inputs/HRoundedTextField";
import { FormProvider, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import apiService from "services/http-service/ApiService";
import authService from "services/http-service/AuthService";
import * as yup from "yup";

const schema = yup.object().shape({
  email: yup.string().email().required("Email is required."),
  firstName: yup.string().required("First name is required."),
});

export default function AddUpdateHowDonorInvite(props) {
  const addOperation = useMutation(async (data) => {
    return await apiService.sendHowToDonorInvitation(data);
  });

  const objForm = useForm({
    defaultValues: {
      email: "",
      firstName: "",
    },
    resolver: yupResolver(schema),
  });

  const onSave = async (invitationsInviteFormData) => {
    const io = {
      email: invitationsInviteFormData.email,
      firstname: invitationsInviteFormData.firstName,
      invitationFor: authService.getUserRole(),
      refercode: authService.getUserData().refercode,
    };

    const result = await addOperation.mutateAsync(io);
    if (result.success) {
      toast.success(result.data);
      if (props.onComplete) props.onComplete();
      if (props.toggle) props.toggle();
      return null;
    }

    toast.error("Some error occurred while send invitation!");
  };

  return (
    <>
      <Typography variant="h5" fontWeight={"bold"} pb={2}>
        Send Invitation
        <Divider />
      </Typography>
      <FormProvider {...objForm}>
        <form onSubmit={objForm.handleSubmit(onSave)}>
          <Box sx={{ maxWidth: "100%" }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <HRoundedTextField name={"email"} label={"Email Address"} />
              </Grid>
              <Grid item xs={12}>
                <HRoundedTextField name={"firstName"} label={"First Name"} />
              </Grid>
              {addOperation.isError && (
                <Grid item xs={12}>
                  <Box py={2}>
                    <ErrorPlaceHolder
                      error={
                        addOperation.error &&
                        (addOperation.error.error &&
                        addOperation.error.error.message
                          ? addOperation.error.error.message
                          : addOperation.error.message)
                      }
                    />
                  </Box>
                </Grid>
              )}
              <Grid item xs={12} sm={6}>
                <LoadingButton
                  type="submit"
                  variant="contained"
                  color="primary"
                  loading={addOperation.isLoading}
                  disabled={addOperation.isLoading}
                  fullWidth
                >
                  Submit
                </LoadingButton>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Button
                  fullWidth
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    props.toggle();
                  }}
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </Box>
        </form>
      </FormProvider>
    </>
  );
}
