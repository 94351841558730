import { yupResolver } from "@hookform/resolvers/yup";
import { LockOutlined } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  CssBaseline,
  Grid,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import ErrorPlaceHolder from "Common/ErrorPlaceHolder";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { forgetPasswordImage } from "services/DefaultConfig";
import { getCopyRightFooter } from "services/RenderUtils";
import apiService from "services/http-service/ApiService";
import * as yup from "yup";

const schema = yup.object().shape({
  email: yup.string().email("Email is required").required("Email is required"),
});

export default function ForgetPasswordHowAdmin() {
  const navigate = useNavigate();

  const objForm = useForm({
    defaultValues: {
      email: "",
    },
    resolver: yupResolver(schema),
  });
  const errors = objForm.formState.errors;

  const sendOTPAction = useMutation(async (data) => {
    return await apiService.forgetHowPassword(data);
  });

  const onLogin = async (data) => {
    const res = await sendOTPAction.mutateAsync({ email: data.email });
    if (res.success) {
      toast.success(
        "Password Reset Link has been sent to your registered email."
      );
      objForm.reset();
      navigate("/");
      return null;
    }
  };

  return (
    <form onSubmit={objForm.handleSubmit(onLogin)}>
      <Grid container component="main" sx={{ height: "100vh" }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: `url(${forgetPasswordImage})`,
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "light"
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
              <LockOutlined />
            </Avatar>
            <Typography component="h1" variant="h5">
              Forget Password
            </Typography>
            <Box mt={1} sx={{ width: "80%" }}>
              <TextField
                margin="normal"
                fullWidth
                autoFocus
                label="Email Address"
                name="email"
                {...objForm.register("email")}
                error={Boolean(errors.email)}
                helperText={Boolean(errors.email) ? errors.email?.message : ""}
              />
              {sendOTPAction.isError && (
                <Box py={2}>
                  <ErrorPlaceHolder error={sendOTPAction.error.error.message} />
                </Box>
              )}
              <Stack mt={2} spacing={2} direction={"row"}>
                <Button
                  fullWidth
                  variant="contained"
                  onClick={() => {
                    navigate("/");
                  }}
                >
                  Back
                </Button>
                <Button
                  disabled={sendOTPAction.isLoading}
                  type="submit"
                  fullWidth
                  variant="contained"
                >
                  Continue
                </Button>
              </Stack>
              {getCopyRightFooter()}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </form>
  );
}
