import { Box, Button, Card, Stack, TextField, Typography } from "@mui/material";
import { Formik } from "formik";
import { useState } from "react";
import { CommonStore } from "services/store/CommonStore/PullStore";
import * as Yup from "yup";

export default function HowSetupPayment() {
  const darkTheme = CommonStore.useState((s) => s.themeMode);
  const [value, setValue] = useState({
    cvc: "",
    date: "",
    name: "",
    number: "",
  });

  const onSubmit = (data) => {
    console.log("this is data", data);
  };
  return (
    <>
      <Box
        component={Card}
        style={{
          height: "100%",
          padding: "1rem",
        }}
      >
        <Box m={1} py={1} mt={"16px"}>
          <Typography variant="h5" color="inherit">
            Setup Payment
          </Typography>
        </Box>
        <Box className="container">
          <Card
            sx={{
              padding: 3,
              border: "1px solid",
              // borderColor: theme.palette.primary.light,
              borderColor: "darkGrey",
              ":hover": {
                boxShadow: "0 2px 14px 0 rgb(32 40 45 / 8%)",
              },
              // backgroundColor: !themeMode ? "white" : "#424242",
            }}
          >
            <Box className="row m-1">
              <Box className="col-md-12">
                <Box className="card m-3">
                  <Box
                    sx={{
                      backgroundColor: darkTheme ? "#121212d6" : "white",
                      p: 2,
                      borderColor: "none !important",
                    }}
                  >
                    <Typography
                      variant="h6"
                      sx={{
                        color: darkTheme ? "white" : "black",
                      }}
                    >
                      Credit or Debit Card
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Card>
          <Box pt={2}>
            <Formik
              initialValues={{
                cardNumber: 0,
                name: "",
                cvc: "",
                date: "",
                amount: "",
              }}
              validationSchema={Yup.object().shape({
                cardNumber: Yup.string()
                  .matches(/^[0-9]{16}$/, "Card number must be 16 digits")
                  .required("Card number is required"),
                name: Yup.string().required("Name is required"),
                cvc: Yup.string()
                  .matches(/^[0-9]{3,4}$/, "CVC must be 3 or 4 digits")
                  .required("CVC is required"),
                amount: Yup.number().required("Amount is required"),
                date: Yup.date()
                  .min(new Date(), "Date must be in the future")
                  .required("Date is required"),
              })}
              onSubmit={async (values) => {
                onSubmit(values);
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values,
              }) => (
                <form
                  noValidate
                  onSubmit={handleSubmit}
                  className="form"
                  autoComplete="off"
                >
                  <Box py={1}>
                    <TextField
                      fullWidth
                      name="cardNumber"
                      type="number"
                      placeholder="xxxx-xxxx-xxxx-xxxx"
                      label="Card Number"
                      id="fullWidth"
                      value={values.cardNumber || ""}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    {errors.cardNumber && touched.cardNumber && (
                      <Box
                        style={{
                          color: "red",
                          marginTop: "3px",
                          marginLeft: "3px",
                        }}
                      >
                        {errors.cardNumber}
                      </Box>
                    )}
                  </Box>
                  <Box>
                    <TextField
                      fullWidth
                      label="Card Holder Name"
                      id="fullWidth"
                      type="text"
                      name="name"
                      value={values.name}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    {errors.name && touched.name && (
                      <Box
                        style={{
                          color: "red",
                          marginTop: "3px",
                          marginLeft: "3px",
                        }}
                      >
                        {errors.name}
                      </Box>
                    )}
                  </Box>
                  <Stack direction="row" spacing={1}>
                    <Box py={1} width={"100%"}>
                      <TextField
                        fullWidth
                        name="date"
                        id="date-local"
                        label="Date"
                        type="date"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.date}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                      {errors.date && touched.date && (
                        <Box
                          style={{
                            color: "red",
                            marginTop: "3px",
                            marginLeft: "3px",
                          }}
                        >
                          {errors.date}
                        </Box>
                      )}
                    </Box>
                    <Box py={1} width={"100%"}>
                      <TextField
                        fullWidth
                        label="CVC"
                        id="fullWidth"
                        type="number"
                        name="cvc"
                        value={values.cvc}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                      {errors.cvc && touched.cvc && (
                        <Box
                          style={{
                            color: "red",
                            marginTop: "3px",
                            marginLeft: "3px",
                          }}
                        >
                          {errors.cvc}
                        </Box>
                      )}
                    </Box>
                  </Stack>

                  <Box pb={1}>
                    <TextField
                      fullWidth
                      label="Amount"
                      id="fullWidth"
                      type="number"
                      name="amount"
                      value={values.amount}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    {errors.amount && touched.amount && (
                      <Box
                        style={{
                          color: "red",
                          marginTop: "3px",
                          marginLeft: "3px",
                        }}
                      >
                        {errors.amount}
                      </Box>
                    )}
                  </Box>
                  <Box py={2} className="row justify-content-center">
                    <Stack spacing={1} direction="row">
                      <Box>
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                        >
                          Save
                        </Button>
                      </Box>
                      <Box>
                        <Button
                          type="button"
                          variant="contained"
                          color="secondary"
                        >
                          Cancel
                        </Button>
                      </Box>
                    </Stack>
                  </Box>
                </form>
              )}
            </Formik>
          </Box>
        </Box>
      </Box>
    </>
  );
}
