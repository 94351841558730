import { QueryClientProvider } from "@tanstack/react-query";
import CustomThemeProvider from "Common/Layouts/CustomThemeProvider";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { queryClient } from "services/QueryClient/QueryClient";
import App from "./App";
import store from "./store";
import "./styles/global.css";
import { Box } from "@mui/material";

const rootElement = document.getElementById("root");
ReactDOM.render(
  <CustomThemeProvider>
    <Box
      sx={{
        position: "absolute",
        zIndex: 120000,
      }}
    >
      <ToastContainer />
    </Box>
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Provider>
    </QueryClientProvider>
  </CustomThemeProvider>,
  rootElement
);
