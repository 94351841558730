import { AddBox, Delete, Edit, Visibility } from "@mui/icons-material";
import { Button, Dialog, DialogContent, useMediaQuery } from "@mui/material";
import { useMutation, useQuery } from "@tanstack/react-query";
import DataTable from "Common/DataTable/DataTable";
import { format } from "date-fns";
import { useState } from "react";
import { toast } from "react-toastify";
import utils from "services/Utils";
import apiService from "services/http-service/ApiService";
import { ApiCommonStore } from "services/store/ApiStates/ApiCommonStore";
import mainTheme from "styles/theme";
import AdminHowAddUpdate from "./AdminHowAddUpdate";
import ViewHowInformation from "./ViewHowInformation";
import ViewAdminHowParent from "./ViewAdminHowParent";

export default function AdminHowList() {
  const [parentID, setParentID] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [isParentHowView, setIsParentHowView] = useState(false);
  const [isView, setIsView] = useState(false);
  const [objItem, setObjItem] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const isSmallScreen = useMediaQuery(mainTheme.breakpoints.down("md"));

  const deleteAction = useMutation(async (childHowID) => {
    return await apiService.removeAdminHow(childHowID);
  });

  const isChildHowListChanged = ApiCommonStore.useState(
    (s) => s.isChildHowListChanged
  );

  const toggle = () => {
    setIsOpen(false);
    setIsAdding(false);
    setIsEdit(false);
  };

  const q = useQuery(
    ["getAllHowListForAdmin", isChildHowListChanged],
    async () => {
      return await apiService.getAllHowListForAdmin();
    }
  );

  const onComplete = () => {
    q.refetch();
  };

  const deleteChildHow = async (childHowID) => {
    try {
      const isValid = await utils.showWarning(
        "Do you want to delete this record?"
      );

      if (isValid.isConfirmed) {
        const result = await deleteAction.mutateAsync(childHowID);
        if (!result.success) {
          throw new Error(result.error.message);
          return null;
        }

        if (result.success) {
          toast.success(result.data);
          q.refetch();
        }
      }
    } catch (ex) {
      if (ex && ex.error && ex.error.message) {
        toast.error(ex.error.message);
        return null;
      } else {
        toast.error(ex.message);
      }
    }
  };

  return (
    <>
      <DataTable
        title="HoWs"
        isLoading={deleteAction.isLoading || q.isLoading}
        isError={q.isError}
        errorMessage={
          q.isError
            ? (q.error && q.error.error && q.error.error.message) ||
              "Errors occurred while getting hows list! please contact admin."
            : null
        }
        columns={[
          {
            title: "Created Date",
            field: "createdAt",
            render: (rowData) => {
              return format(new Date(rowData.createdAt), "dd/MM/yyyy");
            },
          },
          {
            title: "User Name",
            field: "User.first_name",
            render: (rowData) => {
              return utils.getFullName(
                rowData.User.first_name,
                rowData.User.last_name
              );
            },
          },
          { title: "House Name", field: "how_name", width: "100%" },
          { title: "Contact No.", field: "User.mobile" },
          { title: "Role", field: "User.role" },
          { title: "City", field: "city" },
          { title: "State", field: "state" },
          {
            title: "Parent",
            field: "parent_id",
            render: (rowData) => {
              if (rowData.parent_id ||rowData.invitedBy ) {
                return (
                  <Button
                    onClick={() => {
                      setIsParentHowView(true);
                      setParentID(rowData.parent_id || rowData.invitedBy);
                    }}
                    size="small"
                    color="info"
                    variant="outlined"
                  >
                    View Info
                  </Button>
                );
              } else {
                return "N/A";
              }
            },
          },
        ]}
        data={(q.data && q.data.data) || []}
        onReload={() => {
          q.refetch();
        }}
        actions={[
          {
            icon: () => {
              return <AddBox />;
            },
            tooltip: "Add Hows",
            isFreeAction: true,
            onClick: () => {
              setIsAdding(true);
            },
          },
          {
            icon: () => {
              return <Visibility color="info" />;
            },
            tooltip: "View More",
            onClick: (e, rowData) => {
              setIsView(true);
              setObjItem(rowData);
            },
          },
          {
            icon: () => {
              return <Edit color="primary" />;
            },
            tooltip: "Edit Information",
            onClick: (e, rowData) => {
              setIsAdding(true);
              setIsEdit(true);
              setObjItem(rowData);
            },
          },
          {
            icon: () => {
              return <Delete color="error" />;
            },
            tooltip: "Delete Hows",
            onClick: (e, rowData) => {
              deleteChildHow(rowData.id);
            },
          },
        ]}
      />
      {isView && (
        <Dialog
          open={isView}
          onClose={() => {
            setIsView(false);
            setObjItem({});
          }}
          maxWidth={"sm"}
          fullWidth
          sx={{
            position: "absolute",
            zIndex: 12000,
          }}
        >
          <DialogContent>
            <ViewHowInformation objItem={objItem} />
          </DialogContent>
        </Dialog>
      )}

      {isParentHowView && (
        <Dialog
          open={isParentHowView}
          onClose={() => {
            setIsParentHowView(false);
            setParentID(null);
          }}
          maxWidth={"sm"}
          fullWidth
          sx={{
            position: "absolute",
            zIndex: 12000,
          }}
        >
          <DialogContent>
            <ViewAdminHowParent parentID={parentID} />
          </DialogContent>
        </Dialog>
      )}

      {isAdding && (
        <Dialog
          open={isAdding}
          onClose={toggle}
          maxWidth={"lg"}
          sx={{
            position: "absolute",
            zIndex: 12000,
          }}
        >
          <DialogContent>
            <AdminHowAddUpdate
              isEdit={isEdit}
              objItem={objItem}
              onComplete={onComplete}
              toggle={toggle}
            />
          </DialogContent>
        </Dialog>
      )}
    </>
  );
}
