import { Box, CardContent, Typography } from "@mui/material";
import { CommonStore } from "services/store/CommonStore/PullStore";

export default function DonorDashboardCard(props) {
  const themeMode = CommonStore.useState((s) => s.themeMode);
  return (
    <>
      <CardContent
        sx={{
          textAlign: "center",
          paddingTop: "2rem",
          backgroundColor: themeMode ? "#212121 !important" : "#fafafa !important",
          color: themeMode ? "#a6a6a6" : "#5d5d5d",
          transition: "boxShadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
          borderRadius: "4px",
          backgroundImage:
            "linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))",
          overflow: "hidden",
          width: "100%",
          height: "100%",
        }}
      >
        <Typography color="inherit" gutterBottom variant="overline">
          {props.title}
        </Typography>
        <Box>
          <Typography color="inherit" variant="h4">
            {props.amount}
          </Typography>
        </Box>
      </CardContent>
    </>
  );
}
