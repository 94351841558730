
import { createStore, applyMiddleware, combineReducers } from 'redux';
import customizationReducer from './customizationReducer';
import thunkMiddleware from 'redux-thunk';
import AuthReducer from "./reducer/AuthReducer";
import UserReducer from "./reducer/userReducer";
import DonorReducer from './reducer/donorReducer';
import { composeWithDevTools } from 'redux-devtools-extension';
import LoaderReducer from './reducer/loader';
import  ReportReducer from './reducer/Reports/index';
import ModelLoaderReducer from './reducer/modeLoaderReducer';

import NotificationReducer from './reducer/notificationReducer';
// import InvitationReducer from './reducer/invitationReducer';
// import DonateReducer from './reducer/donateReducer';
import HowReducer from './reducer/HowReducer';
import DashboardReducer from './reducer/DashboardReducer';

import { ENVIROMENT } from '../config/Env'; 


const rootReducer = combineReducers({
  // customization: customizationReducer,
  auth: AuthReducer,
  UserReducer,
  HowReducer,
  DashboardReducer,
  DonorReducer,
  LoaderReducer,
  ReportReducer ,
  ModelLoaderReducer,
  NotificationReducer,
  // InvitationReducer,
  // DonateReducer

})

const middleware = [thunkMiddleware];

const Store = createStore(
    rootReducer,
    composeWithDevTools(applyMiddleware(...middleware))
    // ENVIROMENT === "DEV" ? composeWithDevTools(applyMiddleware(...middleware)) : applyMiddleware(...middleware)
);

export default Store;