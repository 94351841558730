import { AddBox, Delete } from "@mui/icons-material";
import { Dialog, DialogContent } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import DataTable from "Common/DataTable/DataTable";
import AddUpdateInvitation from "pages/How/Invitations/AddUpdateInvitation";
import { useState } from "react";
import { toast } from "react-toastify";
import utils from "services/Utils";
import apiService from "services/http-service/ApiService";

export default function AdminHowInvitations() {
  const [isAdding, setIsAdding] = useState(false);

  const toggle = () => {
    setIsAdding(!isAdding);
  };

  const q = useQuery(["getInvitationHowListByAdmin"], async () => {
    return await apiService.getInvitationHowListByAdmin();
  });

  const howQ = useQuery(["getInvitedHowListByAdmin"], async () => {
    return await apiService.getInvitedHowListByAdmin();
  });

  const onComplete = () => {
    q.refetch();
    howQ.refetch();
  };

  const deleteHowInvitation = async (howID) => {
    try {
      const isValid = await utils.showWarning("Do you want to delete?");
      if (isValid.isConfirmed) {
        const res = await apiService.removeAdminHowInvitation(howID);
        if (res.success) {
          onComplete();
          toast.success(res.message);
          return null;
        }

        throw new Error(res.error.message);
      }
    } catch (ex) {
      if (ex) {
        toast.error(ex.message);
      }
    }
  };

  return (
    <>
      <DataTable
        isLoading={q.isLoading || q.isRefetching}
        isError={q.isError}
        errorMessage={
          q.isError
            ? (q.error &&
                q.error.data &&
                q.error.data.error &&
                q.error.data.error.message) ||
              "Error occurred please contact admin!"
            : null
        }
        title="Hows Invitations"
        data={(q.data && q.data.data) || []}
        columns={[
          {
            title: "First Name",
            field: "firstname",
          },
          { title: "Email", field: "email" },
          {
            title: "Invited Date",
            field: "invitedby.createdAt",
            render: (rowData) => {
              return utils.getDateFnsDDMMYYY(rowData.createdAt);
            },
          },
          {
            title: "Expiry Date",
            field: "expirein",
            render: (rowData) => {
              return utils.getDDMMYYYY(rowData.expireIn);
            },
          },
        ]}
        onReload={() => {
          q.refetch();
        }}
        actions={[
          {
            icon: () => {
              return <AddBox />;
            },
            tooltip: "Send How Invitation",
            isFreeAction: true,
            onClick: () => {
              toggle();
            },
          },
          {
            icon: () => {
              return <Delete color="error" />;
            },
            tooltip: "Delete How Invitation",
            onClick: (e, rowData) => {
              deleteHowInvitation(rowData.id);
            },
          },
        ]}
      />
      {isAdding && (
        <Dialog
          open={isAdding}
          onClose={() => {
            console.log("close");
            toggle();
          }}
          maxWidth={"lg"}
          sx={{
            position: "absolute",
            zIndex: 12000,
          }}
        >
          <DialogContent>
            <AddUpdateInvitation
              onComplete={onComplete}
              isAdmin={true}
              toggle={toggle}
            />
          </DialogContent>
        </Dialog>
      )}
    </>
  );
}
