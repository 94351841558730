import DonorAccountSetting from "pages/Donor/DonorContent/DonorAccountSetting";
import DonorDashboardCard from "pages/Donor/DonorContent/DonorDashboardCard";
import DonorDonateCamp from "pages/Donor/DonorContent/DonorDonateCamp";
import { DonorDonationBarChart } from "pages/Donor/DonorContent/DonorDonationBarChart";
import DonorHolyHouseWidget from "pages/Donor/DonorContent/DonorHolyHouseWidget";
import { DonorLastMonthDonation } from "pages/Donor/DonorContent/DonorLastMonthDonation";
import { DonorMonthlyPie } from "pages/Donor/DonorContent/DonorMonthlyPie";
import DonorSetupGoal from "pages/Donor/DonorContent/DonorSetupGoal";
import { DonorTotalDonation } from "pages/Donor/DonorContent/DonorTotalDonation";
import { Store } from "pullstate";

export const DonorCommonStore = new Store({
  minimizeWindow: false,
  reStoreWindow: true,
  closeWindow: false,
  selectedCardKey: null,
  donorCardIsChanged: false,
  initialDashboardLayouts: {
    lg: [
      {
        w: 4,
        h: 6,
        x: 4,
        y: 0,
        i: "1",
        moved: false,
        static: false,
      },
      {
        w: 2,
        h: 3,
        x: 0,
        y: 0,
        i: "2",
        moved: false,
        static: false,
      },
      {
        w: 2,
        h: 3,
        x: 0,
        y: 3,
        i: "3",
        moved: false,
        static: false,
      },
      {
        w: 2,
        h: 3,
        x: 2,
        y: 3,
        i: "4",
        moved: false,
        static: false,
      },
      {
        w: 2,
        h: 3,
        x: 2,
        y: 0,
        i: "5",
        moved: false,
        static: false,
      },
      {
        w: 2,
        h: 6,
        x: 8,
        y: 0,
        i: "6",
        moved: false,
        static: false,
      },
      {
        w: 2,
        h: 6,
        x: 10,
        y: 0,
        i: "7",
        moved: false,
        static: false,
      },
      {
        w: 8,
        h: 9,
        x: 4,
        y: 6,
        i: "8",
        moved: false,
        static: false,
      },
      {
        w: 8,
        h: 7,
        x: 0,
        y: 15,
        i: "9",
        moved: false,
        static: false,
      },
      {
        w: 4,
        h: 7,
        x: 8,
        y: 15,
        i: "10",
        moved: false,
        static: false,
      },
      {
        w: 4,
        h: 9,
        x: 0,
        y: 6,
        i: "12",
        moved: false,
        static: false,
      },
      {
        w: 12,
        h: 9,
        x: 0,
        y: 22,
        i: "13",
        moved: false,
        static: false,
      },
    ],
    md: [
      {
        w: 5,
        h: 4,
        x: 0,
        y: 4,
        i: "1",
        moved: false,
        static: false,
      },
      {
        w: 2,
        h: 2,
        x: 0,
        y: 0,
        i: "2",
        moved: false,
        static: false,
      },
      {
        w: 2,
        h: 2,
        x: 0,
        y: 2,
        i: "3",
        moved: false,
        static: false,
      },
      {
        w: 2,
        h: 2,
        x: 2,
        y: 2,
        i: "4",
        moved: false,
        static: false,
      },
      {
        w: 2,
        h: 2,
        x: 2,
        y: 0,
        i: "5",
        moved: false,
        static: false,
      },
      {
        w: 3,
        h: 4,
        x: 7,
        y: 0,
        i: "6",
        moved: false,
        static: false,
      },
      {
        w: 3,
        h: 4,
        x: 4,
        y: 0,
        i: "7",
        moved: false,
        static: false,
      },
      {
        w: 5,
        h: 4,
        x: 5,
        y: 4,
        i: "8",
        moved: false,
        static: false,
      },
      {
        w: 5,
        h: 9,
        x: 0,
        y: 8,
        i: "9",
        moved: false,
        static: false,
      },
      {
        w: 5,
        h: 9,
        x: 0,
        y: 17,
        i: "10",
        moved: false,
        static: false,
      },
      {
        w: 5,
        h: 9,
        x: 5,
        y: 17,
        i: "11",
        moved: false,
        static: false,
      },
      {
        w: 5,
        h: 9,
        x: 5,
        y: 8,
        i: "12",
        moved: false,
        static: false,
      },
      {
        w: 10,
        h: 7,
        x: 0,
        y: 26,
        i: "13",
        moved: false,
        static: false,
      },
      {
        w: 10,
        h: 7,
        x: 0,
        y: 33,
        i: "14",
        moved: false,
        static: false,
      },
    ],
    sm: [
      {
        w: 4,
        h: 4,
        x: 2,
        y: 0,
        i: "1",
        moved: false,
        static: false,
      },
      {
        w: 2,
        h: 2,
        x: 0,
        y: 0,
        i: "2",
        moved: false,
        static: false,
      },
      {
        w: 2,
        h: 2,
        x: 0,
        y: 2,
        i: "3",
        moved: false,
        static: false,
      },
      {
        w: 2,
        h: 2,
        x: 2,
        y: 4,
        i: "4",
        moved: false,
        static: false,
      },
      {
        w: 2,
        h: 2,
        x: 2,
        y: 6,
        i: "5",
        moved: false,
        static: false,
      },
      {
        w: 4,
        h: 4,
        x: 2,
        y: 8,
        i: "6",
        moved: false,
        static: false,
      },
      {
        w: 3,
        h: 6,
        x: 3,
        y: 12,
        i: "7",
        moved: false,
        static: false,
      },
      {
        w: 5,
        h: 6,
        x: 1,
        y: 18,
        i: "8",
        moved: false,
        static: false,
      },
      {
        w: 4,
        h: 6,
        x: 0,
        y: 24,
        i: "9",
        moved: false,
        static: false,
      },
      {
        w: 4,
        h: 9,
        x: 0,
        y: 30,
        i: "10",
        moved: false,
        static: false,
      },
      {
        w: 4,
        h: 9,
        x: 2,
        y: 39,
        i: "11",
        moved: false,
        static: false,
      },
      {
        w: 4,
        h: 9,
        x: 2,
        y: 48,
        i: "12",
        moved: false,
        static: false,
      },
      {
        w: 6,
        h: 7,
        x: 0,
        y: 57,
        i: "13",
        moved: false,
        static: false,
      },
      {
        w: 6,
        h: 7,
        x: 0,
        y: 64,
        i: "14",
        moved: false,
        static: false,
      },
    ],
    xs: [
      {
        w: 4,
        h: 5,
        x: 0,
        y: 6,
        i: "1",
        moved: false,
        static: false,
      },
      {
        w: 2,
        h: 3,
        x: 0,
        y: 0,
        i: "2",
        moved: false,
        static: false,
      },
      {
        w: 2,
        h: 3,
        x: 0,
        y: 3,
        i: "3",
        moved: false,
        static: false,
      },
      {
        w: 2,
        h: 3,
        x: 2,
        y: 0,
        i: "4",
        moved: false,
        static: false,
      },
      {
        w: 2,
        h: 3,
        x: 2,
        y: 3,
        i: "5",
        moved: false,
        static: false,
      },
      {
        w: 2,
        h: 4,
        x: 0,
        y: 11,
        i: "6",
        moved: false,
        static: false,
      },
      {
        w: 2,
        h: 4,
        x: 2,
        y: 11,
        i: "7",
        moved: false,
        static: false,
      },
      {
        w: 4,
        h: 5,
        x: 0,
        y: 15,
        i: "8",
        moved: false,
        static: false,
      },
      {
        w: 4,
        h: 7,
        x: 0,
        y: 20,
        i: "9",
        moved: false,
        static: false,
      },
      {
        w: 4,
        h: 8,
        x: 0,
        y: 27,
        i: "10",
        moved: false,
        static: false,
      },
      {
        w: 4,
        h: 9,
        x: 0,
        y: 35,
        i: "12",
        moved: false,
        static: false,
      },
      {
        w: 4,
        h: 9,
        x: 0,
        y: 44,
        i: "13",
        moved: false,
        static: false,
      },
      {
        w: 4,
        h: 9,
        x: 0,
        y: 53,
        i: "14",
        moved: false,
        static: false,
      },
    ],
    xxs: [
      {
        w: 2,
        h: 4,
        x: 0,
        y: 6,
        i: "1",
        moved: false,
        static: false,
      },
      {
        w: 1,
        h: 3,
        x: 0,
        y: 0,
        i: "2",
        moved: false,
        static: false,
      },
      {
        w: 1,
        h: 3,
        x: 1,
        y: 0,
        i: "3",
        moved: false,
        static: false,
      },
      {
        w: 1,
        h: 3,
        x: 0,
        y: 3,
        i: "4",
        moved: false,
        static: false,
      },
      {
        w: 1,
        h: 3,
        x: 1,
        y: 3,
        i: "5",
        moved: false,
        static: false,
      },
      {
        w: 2,
        h: 4,
        x: 0,
        y: 10,
        i: "6",
        moved: false,
        static: false,
      },
      {
        w: 2,
        h: 4,
        x: 0,
        y: 14,
        i: "7",
        moved: false,
        static: false,
      },
      {
        w: 2,
        h: 3,
        x: 0,
        y: 18,
        i: "8",
        moved: false,
        static: false,
      },
      {
        w: 2,
        h: 7,
        x: 0,
        y: 21,
        i: "9",
        moved: false,
        static: false,
      },
      {
        w: 2,
        h: 9,
        x: 0,
        y: 28,
        i: "10",
        moved: false,
        static: false,
      },
      {
        w: 2,
        h: 9,
        x: 0,
        y: 37,
        i: "12",
        moved: false,
        static: false,
      },
      {
        w: 2,
        h: 11,
        x: 0,
        y: 46,
        i: "13",
        moved: false,
        static: false,
      },
    ],
  },
  lstDonorElement: [
    {
      key: "1",
      element: <DonorTotalDonation />,
      isVisible: true,
      isClosed: false,
      isCard: true,
      name: "Total Donation",
    },
    {
      key: "2",
      element: <DonorDashboardCard title="Total Spends" amount="$30k" />,
      isVisible: true,
      isClosed: false,
      isDonationCard: true,
      name: "Total Spends",
    },
    {
      key: "3",
      element: <DonorDashboardCard title="Total Donation" amount="$100k" />,
      isVisible: true,
      isClosed: false,
      isDonationCard: true,
      name: "Total Donation",
    },
    {
      key: "4",
      element: <DonorDashboardCard title="Roundup Donation" amount="$18k" />,
      isVisible: true,
      isClosed: false,
      isDonationCard: true,
      name: "Roundup Donation",
    },
    {
      key: "5",
      element: <DonorDashboardCard title="Fixed Donation" amount="$4k" />,
      isVisible: true,
      isClosed: false,
      isDonationCard: true,
      name: "Fixed Donation",
    },
    {
      key: "6",
      element: <DonorMonthlyPie />,
      isVisible: true,
      isClosed: false,
      isCard: true,
      overFlowNone: true,
      name: "Donor Monthly Pie",
    },
    {
      key: "7",
      element: <DonorLastMonthDonation />,
      isVisible: true,
      isClosed: false,
      isCard: true,
      overFlowNone: true,
      name: "Last month's spend",
    },
    {
      key: "8",
      element: <DonorDonationBarChart />,
      isVisible: true,
      isClosed: false,
      isCard: true,
      overFlowNone: true,
      name: "Fixed Donation",
    },
    {
      key: "9",
      element: <DonorSetupGoal />,
      isVisible: true,
      isClosed: false,
      isCard: true,
      name: "Goals",
    },
    {
      key: "10",
      element: <DonorDonateCamp />,
      isVisible: true,
      isClosed: false,
      isCard: true,
      name: "Make Payments",
    },
    {
      key: "12",
      element: <DonorAccountSetting />,
      isVisible: true,
      isClosed: false,
      isCard: true,
      overFlowNone: true,
      name: "Account Setting",
    },
    {
      key: "13",
      element: <DonorHolyHouseWidget />,
      isVisible: true,
      isClosed: false,
      isCard: false,
      name: "House of Worship",
    },
  ],
});

export const setDonorCardIsChanged = (isChanged) => {
  DonorCommonStore.update((s) => {
    s.donorCardIsChanged = isChanged;
  });
};

export const donorCardClose = (cardID) => {
  DonorCommonStore.update((s) => {
    s.lstDonorElement = s.lstDonorElement.map((item) => {
      if (parseInt(item.key) === parseInt(cardID)) {
        return {
          ...item,
          isClosed: !item.isClosed,
        };
      } else {
        return item;
      }
    });
  });
  getDonorDashboardLayouts();
  setDonorCardIsChanged(true);
};

export const getDonorDashboardLayouts = () =>
  DonorCommonStore.getRawState().lstDonorElement;
