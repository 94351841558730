import { Box, CardContent, Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import apiService from "services/http-service/ApiService";

const TotalHowCard = () => {
  const q = useQuery(["getTotalHowCount"], async () => {
    return await apiService.getTotalHowCounts();
  });

  return (
    <CardContent
      sx={{
        textAlign: "center",
        paddingTop: "2rem",
      }}
    >
      <Typography color="inherit" gutterBottom variant="overline">
        Total Holy Houses
      </Typography>
      <Box>
        <Typography color="inherit" variant="h4">
          {q.data}
        </Typography>
      </Box>
    </CardContent>
  );
};

export default TotalHowCard;
