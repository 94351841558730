import { Box } from "@mui/material";
import Typography from "@mui/material/Typography";
import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";
import faker from "faker";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: false,
  plugins: {
    legend: {
      position: "bottom",
      display: false, // Use 'display' instead of 'Legend'
    },
  },
};

const labels = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
];

export const data = {
  labels,
  datasets: [
    {
      label: "Amount",
      data: labels.map(() =>
        faker.datatype.number({ min: 100, max: 1000 })
      ),
      borderColor: "rgb(136, 132, 216)",
      backgroundColor: "rgb(136, 132, 216)",
    },
  ],
};

export default function HowCurrentMonthDailyRevenue() {
  return (
    <>
      <Typography variant="h5">Current Month Daily Revenue</Typography>
      <Typography variant="body1">
        The total number of sessions within the date range. It is the period
        time a user is actively engaged with your website, page or app, etc
      </Typography>
      <Box
        sx={{
          pt: 5,
          width: "100%", // Set the desired width
          height: "400px", // Set the desired height
          margin: "auto",
        }}
      >
        <Line className="canvas" options={options} data={data} height={300} /> 
      </Box>
    </>
  );
}
