import { AddBox } from "@material-ui/icons";
import { Delete } from "@mui/icons-material";
import { Dialog, DialogContent, useMediaQuery } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import DataTable from "Common/DataTable/DataTable";
import { useState } from "react";
import { toast } from "react-toastify";
import utils from "services/Utils";
import apiService from "services/http-service/ApiService";
import mainTheme from "styles/theme";
import AddUpdateHowDonorInvite from "./AddUpdateHowDonorInvite";
export default function HowDonorInvitation() {
  const [isOpen, setIsOpen] = useState(false);
  const isMediumScreen = useMediaQuery(mainTheme.breakpoints.down("md"));

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const q = useQuery(["getInvitationDonorListByHow"], async () => {
    return await apiService.getInvitationDonorListByHow();
  });

  const onComplete = () => {
    q.refetch();
  };

  const deleteInvitation = async (donorID) => {
    try {
      const isValid = await utils.showWarning("Do you want to delete?");
      if (isValid.isConfirmed) {
        const res = await apiService.removeHowDonorInvitation(donorID);
        if (res.success) {
          toast.success(res.message);
          onComplete();
        }
      }
    } catch (ex) {
      toast.error(ex.message);
    }
  };

  return (
    <>
      <DataTable
        title="Donor Invitations"
        onReload={() => {
          onComplete();
        }}
        isError={q.isError}
        errorMessage={
          q.isError
            ? q.error.data.error.message ||
              "Error occurred please contact admin!"
            : null
        }
        isLoading={q.isLoading || q.isRefetching}
        columns={[
          {
            title: "First Name",
            field: "firstname",
          },
          { title: "Email", field: "email" },
          {
            title: "Invite Date",
            field: "inviteDate",
            render: (row) => {
              return utils.getDateFnsDDMMYYY(row.createdAt);
            },
          },
        ]}
        actions={[
          {
            icon: () => {
              return <AddBox />;
            },
            tooltip: "Invite Donor",
            isFreeAction: true,
            onClick: () => {
              setIsOpen(true);
            },
          },
          {
            icon: () => {
              return <Delete color="error" />;
            },
            tooltip: "Delete Donor Invitation",
            onClick: (event, item) => {
              deleteInvitation(item.id);
            },
          },
        ]}
        data={(q.data && q.data.data) || []}
      />

      {isOpen && (
        <Dialog
          open={isOpen}
          onClose={toggle}
          fullWidth
          style={{
            display: "block",
            margin: "auto",
            maxWidth: isMediumScreen ? "100%" : "30%",
          }}
        >
          <DialogContent>
            <AddUpdateHowDonorInvite onComplete={onComplete} toggle={toggle} />
          </DialogContent>
        </Dialog>
      )}
    </>
  );
}
