import { Box, Card } from "@mui/material";
import AuthGuard from "Common/AuthGuard/AuthGuard";
import MainLayouts from "Common/Layouts/MainLayouts";
import DonorSetupGoal from "./DonorContent/DonorSetupGoal";

export default function DonorDonates() {
  return (
    <AuthGuard>
      <MainLayouts>
        <Box
          sx={{
            margin: "auto",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Card>
            <DonorSetupGoal />
          </Card>
        </Box>
      </MainLayouts>
    </AuthGuard>
  );
}
