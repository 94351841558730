import MainLayouts from "Common/Layouts/MainLayouts";
import React from "react";
import AllEventList from "./AllEventList";
import AuthGuard from "Common/AuthGuard/AuthGuard";

export default function HowEventPage() {
  return (
    <AuthGuard>
      <MainLayouts>
        <AllEventList isAddUpdate={true} />
      </MainLayouts>
    </AuthGuard>
  );
}
