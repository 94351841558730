import { yupResolver } from "@hookform/resolvers/yup";
import { LockOutlined } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Avatar,
  Box,
  CssBaseline,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import ErrorPlaceHolder from "Common/ErrorPlaceHolder";
import jwtDecode from "jwt-decode";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { forgetPasswordImage } from "services/DefaultConfig";
import { getCopyRightFooter } from "services/RenderUtils";
import apiService from "services/http-service/ApiService";
import * as yup from "yup";

const schema = yup.object().shape({
  newPassword: yup
    .string()
    .required("New password is required")
    .min(5, "Password must be at least 5 characters long"), // You can adjust the minimum length as needed

  confirmPassword: yup
    .string()
    .oneOf([yup.ref("newPassword"), null], "Password not match") // Ensure confirmPassword matches newPassword
    .required("Confirm password is required"),
});

export default function ResetHowPassword(props) {
  const navigate = useNavigate();
  const { token: userToken } = useParams();
  const decodeEmail = jwtDecode(userToken);
  const objForm = useForm({
    defaultValues: {
      newPassword: "",
      confirmPassword: "",
    },
    resolver: yupResolver(schema),
  });
  const errors = objForm.formState.errors;

  const resetPasswordOperation = useMutation(async (data) => {

    if (props.isDonor) {
      return await apiService.resetDonorPassword(data);
    }

    if (props.isAdmin) {
      return await apiService.resetAdminPassword(data);
    }else {
      return await apiService.resetHowPassword(data);
    }
  });

  const onResetPassword = async (data) => {
    const io = {
      password: data.newPassword,
      token: userToken,
    };

    const res = await resetPasswordOperation.mutateAsync(io);
    if (res.success) {
      toast.success(res.data);
      navigate("/");
      return null;
    }
  };

  return (
    <form onSubmit={objForm.handleSubmit(onResetPassword)}>
      <Grid container component="main" sx={{ height: "100vh" }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: `url(${forgetPasswordImage})`,
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "light"
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
              <LockOutlined />
            </Avatar>
            <Typography component="h1" variant="h5">
              Reset Password
            </Typography>
            <Box mt={1} sx={{ width: "80%" }}>
              <TextField
                margin="normal"
                fullWidth
                autoFocus
                type="password"
                label="New Password"
                name="newPassword"
                {...objForm.register("newPassword")}
                error={Boolean(errors.newPassword)}
                helperText={
                  Boolean(errors.newPassword) ? errors.newPassword?.message : ""
                }
              />
              <TextField
                margin="normal"
                fullWidth
                autoFocus
                type="password"
                label="Confirm Password"
                name="confirmPassword"
                {...objForm.register("confirmPassword")}
                error={Boolean(errors.confirmPassword)}
                helperText={
                  Boolean(errors.confirmPassword)
                    ? errors.confirmPassword?.message
                    : ""
                }
              />
              {resetPasswordOperation.isError && (
                <Box py={2}>
                  <ErrorPlaceHolder
                    error={resetPasswordOperation.error.error.message}
                  />
                </Box>
              )}
              <LoadingButton
                loading={resetPasswordOperation.isLoading}
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Continue
              </LoadingButton>
              {getCopyRightFooter()}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </form>
  );
}
