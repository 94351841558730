import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Dialog,
  DialogContent,
  Grid,
  Typography,
} from "@mui/material";
import AuthGuard from "Common/AuthGuard/AuthGuard";
import BoxTable from "Common/DataTable/BoxTable";
import MainLayouts from "Common/Layouts/MainLayouts";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import utils from "services/Utils";
import apiService from "services/http-service/ApiService";
import { CommonStore } from "services/store/CommonStore/PullStore";
import AddUpdateHowProfile from "./AddUpdateHowProfile";
export default function HowProfile() {
  const themeMode = CommonStore.useState((s) => s.themeMode);
  const [isEdit, setIsEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [objUser, setObjUser] = useState({});
  const [objHow, setObjHow] = useState({});

  const loadData = async () => {
    try {
      setIsLoading(true);
      const res = await apiService.getHowUserProfile();
      if (res.success) {
        setObjUser(res.data.User);
        setObjHow(res.data);
        return null;
      } else {
        throw new Error(res.message);
      }
    } catch (ex) {
      toast.error(ex.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  const onComplete = () => {
    loadData();
  };

  const toggle = () => {
    setIsEdit(false);
  };

  return (
    <AuthGuard>
      <MainLayouts>
        {isLoading ? (
          <Box
            sx={{
              width: "100%",
              height: "90vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              variant="h6"
              sx={{ color: themeMode ? "white" : "black" }}
            >
              Loading....
            </Typography>
          </Box>
        ) : (
          <BoxTable>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Button
                  sx={{ float: "right", fontWeight: "bold" }}
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setIsEdit(true);
                  }}
                >
                  Edit Profile
                </Button>
              </Grid>
              <Grid item xs={62} md={7}>
                <Card sx={{ minWidth: 275 }}>
                  <CardHeader
                    title={
                      <Typography
                        sx={{
                          fontSize: 20,
                          fontWeight: "bold",
                        }}
                      >
                        User Information
                      </Typography>
                    }
                  />
                  <CardContent sx={{ overflow: "auto", margin: "14px" }}>
                    <Grid container spacing={2}>
                      <Grid item xs={6} md={5}>
                        <Typography variant="body1" fontWeight={"bold"}>
                          How Name :
                        </Typography>
                      </Grid>
                      <Grid item xs={6} md={7}>
                        <Typography variant="body1">
                          {objHow.how_name || "N/A"}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} md={5}>
                        <Typography variant="body1" fontWeight={"bold"}>
                          First Name :
                        </Typography>
                      </Grid>
                      <Grid item xs={6} md={7}>
                        <Typography variant="body1">
                          {utils.firstLetterToCapital(objUser.first_name) ||
                            "N/A"}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} md={5}>
                        <Typography variant="body1" fontWeight={"bold"}>
                          Last Name :
                        </Typography>
                      </Grid>
                      <Grid item xs={6} md={7}>
                        <Typography variant="body1">
                          {utils.firstLetterToCapital(objUser.last_name) ||
                            "N/A"}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} md={5}>
                        <Typography variant="body1" fontWeight={"bold"}>
                          Email :
                        </Typography>
                      </Grid>
                      <Grid item xs={6} md={7}>
                        <Typography variant="body1">
                          {objUser.email || "N/A"}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} md={5}>
                        <Typography variant="body1" fontWeight={"bold"}>
                          Phone Number :
                        </Typography>
                      </Grid>
                      <Grid item xs={6} md={7}>
                        <Typography variant="body1">
                          {objUser.mobile || "N/A"}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} md={5}>
                        <Typography variant="body1" fontWeight={"bold"}>
                          Refer Code :
                        </Typography>
                      </Grid>
                      <Grid item xs={6} md={7}>
                        <Typography variant="body1">
                          {objUser.refercode || "N/A"}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} md={5}>
                        <Typography variant="body1" fontWeight={"bold"}>
                          User Active :
                        </Typography>
                      </Grid>
                      <Grid item xs={6} md={7}>
                        {objUser.isActive ? (
                          <Chip label="Active" color="primary" />
                        ) : (
                          <Chip label="Not Active" color="error" />
                        )}
                      </Grid>
                      <Grid item xs={6} md={5}>
                        <Typography variant="body1" fontWeight={"bold"}>
                          User Availability :
                        </Typography>
                      </Grid>
                      <Grid item xs={6} md={7}>
                        {objUser.isDeleted ? (
                          <Chip label="User has been deleted" color="error" />
                        ) : (
                          <Chip label="Available" color="success" />
                        )}
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={62} md={5}>
                <Card sx={{ minWidth: 275 }}>
                  <CardHeader
                    title={
                      <Typography
                        sx={{
                          fontSize: 20,
                          fontWeight: "bold",
                        }}
                      >
                        Address Information
                      </Typography>
                    }
                  />

                  {/*In this section is responsible for showing address  */}
                  <CardContent>
                    <Grid container spacing={2}>
                      <Grid item xs={6} md={5}>
                        <Typography variant="body1" fontWeight={"bold"}>
                          Address :
                        </Typography>
                      </Grid>
                      <Grid item xs={6} md={7}>
                        <Typography variant="body1">
                          {objHow.address || "N/A"}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} md={5}>
                        <Typography variant="body1" fontWeight={"bold"}>
                          City :
                        </Typography>
                      </Grid>
                      <Grid item xs={6} md={7}>
                        <Typography variant="body1">
                          {objHow.city || "N/A"}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} md={5}>
                        <Typography variant="body1" fontWeight={"bold"}>
                          Country :
                        </Typography>
                      </Grid>
                      <Grid item xs={6} md={7}>
                        <Typography variant="body1">
                          {objHow.country_code || "N/A"}
                        </Typography>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </BoxTable>
        )}
        {isEdit && (
          <Dialog
            open={isEdit}
            onClose={toggle}
            maxWidth={"lg"}
            sx={{
              position: "absolute",
              zIndex: 12000,
            }}
          >
            <DialogContent>
              <AddUpdateHowProfile
                isEdit={isEdit}
                objUser={objUser}
                objHow={objHow}
                onComplete={onComplete}
                toggle={toggle}
              />
            </DialogContent>
          </Dialog>
        )}
      </MainLayouts>
    </AuthGuard>
  );
}
