import { Box } from "@mui/material";
import LandingPage from "LandingPage";
import { Navigate } from "react-router";
import authService from "services/http-service/AuthService";

export default function AuthGuard({ children }) {
  if (authService.isUserLogin() && window.location.href.endsWith("/")) {
    if (authService.getUserRole() === "HowAdmin") {
      console.log("How Admin Panel Return");
      return <Navigate to={"/how-dashboard"} />;
    }

    if (authService.getUserRole() === "Donor") {
      console.log("Donor Panel Return");
      return <Navigate to={"/donor-dashboard"} />;
    }

    if (authService.getUserRole() === "Admin") {
      console.log("Admin Panel Return");
      return <Navigate to={"/dashboard"} />;
    }
  }

  if (!authService.isUserLogin()) {
    return <LandingPage />;
  }

  if (authService.isUserLogin()) {
    return <Box>{children}</Box>;
  }
}
