import { AddBox } from "@mui/icons-material";
import {
  Dialog,
  DialogContent,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import BoxTable from "Common/DataTable/BoxTable";
import DataTable from "Common/DataTable/DataTable";
import { useEffect, useState } from "react";
import apiService from "services/http-service/ApiService";
import {
  ApiCommonStore,
  setIsCreateNewEvent,
} from "services/store/ApiStates/ApiCommonStore";
import mainTheme from "styles/theme";
import AddUpdateEvent from "./AddUpdateEvent";

export default function AllEventList(props) {
  const [isOpen, setIsOpen] = useState(false);
  const isMediumScreen = useMediaQuery(mainTheme.breakpoints.down("md"));
  const isCreateNewEvent = ApiCommonStore.useState((s) => s.isCreateNewEvent);

  async function getAllEventList() {
    return await apiService.getHowEventList();
  }

  const { data, isLoading, isError, error, refetch, isRefetching } = useQuery(
    ["getAllEventList"],
    async () => {
      const result = await getAllEventList();
      if (result.success) {
        return result?.data?.rows;
      }
    }
  );

  function toggle() {
    setIsOpen(!isOpen);
  }

  function onComplete() {
    setIsOpen(false);
    refetch();
  }

  useEffect(() => {
    if (isCreateNewEvent) {
      refetch();
      setIsCreateNewEvent(false);
    }
  }, [isCreateNewEvent]);
  return (
    <>
      <BoxTable>
        <DataTable
          title="All Events"
          isLoading={isLoading || isRefetching}
          columns={[
            { title: "Title", field: "title" },
            { title: "Description", field: "desc" },
            {
              title: "Created Date",
              field: "date",
              render: (rowData) => {
                return (
                  <Typography variant="body1">
                    {new Date(rowData.createdAt).toLocaleDateString() || "N/A"}
                  </Typography>
                );
              },
            },
            { title: "Time", field: "time" },
            {
              title: "Joining Date",
              field: "date",
            },
          ]}
          data={data}
          onReload={() => {
            refetch();
          }}
          actions={[
            props.isAddUpdate
              ? {
                  icon: () => {
                    return <AddBox />;
                  },
                  tooltip: "Create Events",
                  isFreeAction: true,
                  onClick: () => {
                    toggle();
                  },
                }
              : null,
          ]}
        />
      </BoxTable>

      {isOpen && (
        <Dialog
          open={isOpen}
          onClose={toggle}
          fullWidth
          sx={{
            maxWidth: isMediumScreen ? "100%" : "50%",
            display: "block",
            margin: "auto",
          }}
        >
          <DialogContent>
            <AddUpdateEvent onComplete={onComplete} toggle={toggle} />
          </DialogContent>
        </Dialog>
      )}
    </>
  );
}
