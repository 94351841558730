import ChildHowPage from "pages/How/ChildHow/ChildHowPage";
import HowCurrentMonthDailyRevenue from "pages/How/HowDashbord/HowCurrentMonthDailyRevenue";
import HowCurrentMonthLineChart from "pages/How/HowDashbord/HowCurrentMonthLineChart";
import HowDetailedReport from "pages/How/HowDashbord/HowDetailedReport";
import HowDonationCard from "pages/How/HowDashbord/HowDonationCard";
import HowDonationChartBar from "pages/How/HowDashbord/HowDonationChartBar";
import HowDonorRevenue from "pages/How/HowDashbord/HowDonorRevenue";
import { HowDonorRevenueReport } from "pages/How/HowDashbord/HowDonorRevenueReport";
import HowHouseRevenue from "pages/How/HowDashbord/HowHouseRevenue";
import { HowMonthlyRevenueReport } from "pages/How/HowDashbord/HowMonthlyRevenueReport";
import HowSetupPayment from "pages/How/HowDashbord/HowSetupPayment";
import HowTermPolicy from "pages/How/HowDashbord/HowTermPolicy";
import TotalHowDonorsCount from "pages/How/HowDashbord/TotalHowDonorsCount";
import TotalHowCard from "pages/How/HowDashbord/TotalHowsCard";
import HowDonorInvitation from "pages/How/HowDonors/HowDonorInvitation";
import HowDonorsPage from "pages/How/HowDonors/HowDonorsPage";
import HowSuspendedDonor from "pages/How/HowDonors/HowSuspendedDonor";
import AddUpdateEvent from "pages/How/HowEvents/AddUpdateEvent";
import AllEventList from "pages/How/HowEvents/AllEventList";
import HowHelp from "pages/How/HowHelp/HowHelp";
import HowRevenueDonor from "pages/How/HowRevenue/HowRevenueDonor";
import RevenueFromChildHolyHouses from "pages/How/HowRevenue/RevenueFromChildHolyHouses";
import HowInvitationPage from "pages/How/Invitations/HowInvitationPage";
import SuspendedHowPage from "pages/How/SuspendedHow/SuspendedHowPage";
import { Store } from "pullstate";
export const HowDashboardLayoutStore = new Store({
  minimizeWindow: false,
  reStoreWindow: true,
  closeWindow: false,
  selectedCardKey: null,
  isCardLayoutsChanged: false,
  initialDashboardLayouts: {
    xs: [
      {
        w: 4,
        h: 7,
        x: 0,
        y: 0,
        i: "1",
        moved: false,
        static: false,
      },
      {
        w: 2,
        h: 3,
        x: 0,
        y: 7,
        i: "2",
        moved: false,
        static: false,
      },
      {
        w: 2,
        h: 3,
        x: 2,
        y: 7,
        i: "3",
        moved: false,
        static: false,
      },
      {
        w: 2,
        h: 3,
        x: 0,
        y: 10,
        i: "4",
        moved: false,
        static: false,
      },
      {
        w: 2,
        h: 3,
        x: 2,
        y: 10,
        i: "5",
        moved: false,
        static: false,
      },
      {
        w: 4,
        h: 6,
        x: 0,
        y: 13,
        i: "6",
        moved: false,
        static: false,
      },
      {
        w: 4,
        h: 7,
        x: 0,
        y: 19,
        i: "7",
        moved: false,
        static: false,
      },
      {
        w: 4,
        h: 7,
        x: 0,
        y: 26,
        i: "8",
        moved: false,
        static: false,
      },
      {
        w: 4,
        h: 6,
        x: 0,
        y: 33,
        i: "9",
        moved: false,
        static: false,
      },
      {
        w: 4,
        h: 5,
        x: 0,
        y: 39,
        i: "10",
        moved: false,
        static: false,
      },
      {
        w: 4,
        h: 6,
        x: 0,
        y: 44,
        i: "11",
        moved: false,
        static: false,
      },
      {
        w: 4,
        h: 8,
        x: 0,
        y: 50,
        i: "12",
        moved: false,
        static: false,
      },
      {
        w: 4,
        h: 8,
        x: 0,
        y: 58,
        i: "13",
        moved: false,
        static: false,
      },
      {
        w: 4,
        h: 9,
        x: 0,
        y: 66,
        i: "14",
        moved: false,
        static: false,
      },
      {
        w: 4,
        h: 9,
        x: 0,
        y: 75,
        i: "15",
        moved: false,
        static: false,
      },
      {
        w: 4,
        h: 9,
        x: 0,
        y: 84,
        i: "16",
        moved: false,
        static: false,
      },
      {
        w: 4,
        h: 6,
        x: 0,
        y: 93,
        i: "17",
        moved: false,
        static: false,
      },
      {
        w: 4,
        h: 5,
        x: 0,
        y: 99,
        i: "18",
        moved: false,
        static: false,
      },
      {
        w: 4,
        h: 7,
        x: 0,
        y: 104,
        i: "19",
        moved: false,
        static: false,
      },
      {
        w: 4,
        h: 6,
        x: 0,
        y: 111,
        i: "20",
        moved: false,
        static: false,
      },
      {
        w: 4,
        h: 9,
        x: 0,
        y: 117,
        i: "21",
        moved: false,
        static: false,
      },
      {
        w: 4,
        h: 8,
        x: 0,
        y: 126,
        i: "22",
        moved: false,
        static: false,
      },
      {
        w: 4,
        h: 8,
        x: 0,
        y: 134,
        i: "23",
        moved: false,
        static: false,
      },
      {
        w: 4,
        h: 5,
        x: 0,
        y: 142,
        i: "24",
        moved: false,
        static: false,
      },
      {
        w: 4,
        h: 5,
        x: 0,
        y: 147,
        i: "25",
        moved: false,
        static: false,
      },
    ],
    xxs: [
      {
        w: 2,
        h: 5,
        x: 0,
        y: 0,
        i: "1",
        moved: false,
        static: false,
      },
      {
        w: 1,
        h: 3,
        x: 0,
        y: 5,
        i: "2",
        moved: false,
        static: false,
      },
      {
        w: 1,
        h: 3,
        x: 1,
        y: 5,
        i: "3",
        moved: false,
        static: false,
      },
      {
        w: 1,
        h: 3,
        x: 0,
        y: 8,
        i: "4",
        moved: false,
        static: false,
      },
      {
        w: 1,
        h: 3,
        x: 1,
        y: 8,
        i: "5",
        moved: false,
        static: false,
      },
      {
        w: 2,
        h: 5,
        x: 0,
        y: 11,
        i: "6",
        moved: false,
        static: false,
      },
      {
        w: 2,
        h: 6,
        x: 0,
        y: 16,
        i: "7",
        moved: false,
        static: false,
      },
      {
        w: 2,
        h: 5,
        x: 0,
        y: 22,
        i: "8",
        moved: false,
        static: false,
      },
      {
        w: 2,
        h: 6,
        x: 0,
        y: 27,
        i: "9",
        moved: false,
        static: false,
      },
      {
        w: 2,
        h: 5,
        x: 0,
        y: 33,
        i: "10",
        moved: false,
        static: false,
      },
      {
        w: 2,
        h: 5,
        x: 0,
        y: 38,
        i: "11",
        moved: false,
        static: false,
      },
      {
        w: 2,
        h: 7,
        x: 0,
        y: 43,
        i: "12",
        moved: false,
        static: false,
      },
      {
        w: 2,
        h: 9,
        x: 0,
        y: 50,
        i: "13",
        moved: false,
        static: false,
      },
      {
        w: 2,
        h: 5,
        x: 0,
        y: 59,
        i: "14",
        moved: false,
        static: false,
      },
      {
        w: 2,
        h: 6,
        x: 0,
        y: 64,
        i: "15",
        moved: false,
        static: false,
      },
      {
        w: 2,
        h: 7,
        x: 0,
        y: 70,
        i: "16",
        moved: false,
        static: false,
      },
      {
        w: 2,
        h: 7,
        x: 0,
        y: 77,
        i: "17",
        moved: false,
        static: false,
      },
      {
        w: 2,
        h: 6,
        x: 0,
        y: 84,
        i: "18",
        moved: false,
        static: false,
      },
      {
        w: 2,
        h: 6,
        x: 0,
        y: 90,
        i: "19",
        moved: false,
        static: false,
      },
      {
        w: 2,
        h: 5,
        x: 0,
        y: 96,
        i: "20",
        moved: false,
        static: false,
      },
      {
        w: 2,
        h: 9,
        x: 0,
        y: 101,
        i: "21",
        moved: false,
        static: false,
      },
      {
        w: 2,
        h: 8,
        x: 0,
        y: 110,
        i: "22",
        moved: false,
        static: false,
      },
      {
        w: 2,
        h: 7,
        x: 0,
        y: 118,
        i: "23",
        moved: false,
        static: false,
      },
      {
        w: 2,
        h: 7,
        x: 0,
        y: 125,
        i: "24",
        moved: false,
        static: false,
      },
      {
        w: 2,
        h: 6,
        x: 0,
        y: 132,
        i: "25",
        moved: false,
        static: false,
      },
    ],
    sm: [
      {
        w: 4,
        h: 6,
        x: 0,
        y: 6,
        i: "1",
        moved: false,
        static: false,
      },
      {
        w: 2,
        h: 3,
        x: 0,
        y: 0,
        i: "2",
        moved: false,
        static: false,
      },
      {
        w: 2,
        h: 3,
        x: 2,
        y: 0,
        i: "3",
        moved: false,
        static: false,
      },
      {
        w: 2,
        h: 3,
        x: 0,
        y: 3,
        i: "4",
        moved: false,
        static: false,
      },
      {
        w: 2,
        h: 3,
        x: 2,
        y: 3,
        i: "5",
        moved: false,
        static: false,
      },
      {
        w: 4,
        h: 6,
        x: 0,
        y: 12,
        i: "6",
        moved: false,
        static: false,
      },
      {
        w: 4,
        h: 7,
        x: 0,
        y: 18,
        i: "7",
        moved: false,
        static: false,
      },
      {
        w: 4,
        h: 7,
        x: 0,
        y: 25,
        i: "8",
        moved: false,
        static: false,
      },
      {
        w: 4,
        h: 6,
        x: 0,
        y: 32,
        i: "9",
        moved: false,
        static: false,
      },
      {
        w: 4,
        h: 5,
        x: 0,
        y: 38,
        i: "10",
        moved: false,
        static: false,
      },
      {
        w: 4,
        h: 6,
        x: 0,
        y: 43,
        i: "11",
        moved: false,
        static: false,
      },
      {
        w: 4,
        h: 8,
        x: 0,
        y: 49,
        i: "12",
        moved: false,
        static: false,
      },
      {
        w: 4,
        h: 8,
        x: 0,
        y: 57,
        i: "13",
        moved: false,
        static: false,
      },
      {
        w: 4,
        h: 9,
        x: 0,
        y: 65,
        i: "14",
        moved: false,
        static: false,
      },
      {
        w: 4,
        h: 9,
        x: 0,
        y: 74,
        i: "15",
        moved: false,
        static: false,
      },
      {
        w: 4,
        h: 9,
        x: 0,
        y: 83,
        i: "16",
        moved: false,
        static: false,
      },
      {
        w: 4,
        h: 6,
        x: 0,
        y: 92,
        i: "17",
        moved: false,
        static: false,
      },
      {
        w: 4,
        h: 5,
        x: 0,
        y: 98,
        i: "18",
        moved: false,
        static: false,
      },
      {
        w: 4,
        h: 7,
        x: 0,
        y: 103,
        i: "19",
        moved: false,
        static: false,
      },
      {
        w: 4,
        h: 6,
        x: 0,
        y: 110,
        i: "20",
        moved: false,
        static: false,
      },
      {
        w: 4,
        h: 9,
        x: 0,
        y: 116,
        i: "21",
        moved: false,
        static: false,
      },
      {
        w: 4,
        h: 8,
        x: 0,
        y: 125,
        i: "22",
        moved: false,
        static: false,
      },
      {
        w: 4,
        h: 8,
        x: 0,
        y: 133,
        i: "23",
        moved: false,
        static: false,
      },
      {
        w: 4,
        h: 5,
        x: 0,
        y: 141,
        i: "24",
        moved: false,
        static: false,
      },
      {
        w: 4,
        h: 5,
        x: 0,
        y: 146,
        i: "25",
        moved: false,
        static: false,
      },
    ],
    md: [
      {
        w: 6,
        h: 6,
        x: 4,
        y: 0,
        i: "1",
        moved: false,
        static: false,
      },
      {
        w: 2,
        h: 3,
        x: 0,
        y: 0,
        i: "2",
        moved: false,
        static: false,
      },
      {
        w: 2,
        h: 3,
        x: 2,
        y: 0,
        i: "3",
        moved: false,
        static: false,
      },
      {
        w: 2,
        h: 3,
        x: 0,
        y: 3,
        i: "4",
        moved: false,
        static: false,
      },
      {
        w: 2,
        h: 3,
        x: 2,
        y: 3,
        i: "5",
        moved: false,
        static: false,
      },
      {
        w: 5,
        h: 5,
        x: 0,
        y: 6,
        i: "6",
        moved: false,
        static: false,
      },
      {
        w: 5,
        h: 5,
        x: 5,
        y: 6,
        i: "7",
        moved: false,
        static: false,
      },
      {
        w: 5,
        h: 5,
        x: 0,
        y: 11,
        i: "8",
        moved: false,
        static: false,
      },
      {
        w: 5,
        h: 5,
        x: 5,
        y: 11,
        i: "9",
        moved: false,
        static: false,
      },
      {
        w: 5,
        h: 4,
        x: 0,
        y: 16,
        i: "10",
        moved: false,
        static: false,
      },
      {
        w: 5,
        h: 4,
        x: 5,
        y: 16,
        i: "11",
        moved: false,
        static: false,
      },
      {
        w: 5,
        h: 8,
        x: 0,
        y: 20,
        i: "12",
        moved: false,
        static: false,
      },
      {
        w: 5,
        h: 8,
        x: 5,
        y: 20,
        i: "13",
        moved: false,
        static: false,
      },
      {
        w: 10,
        h: 6,
        x: 0,
        y: 28,
        i: "14",
        moved: false,
        static: false,
      },
      {
        w: 10,
        h: 8,
        x: 0,
        y: 34,
        i: "15",
        moved: false,
        static: false,
      },
      {
        w: 10,
        h: 8,
        x: 0,
        y: 42,
        i: "16",
        moved: false,
        static: false,
      },
      {
        w: 10,
        h: 5,
        x: 0,
        y: 50,
        i: "17",
        moved: false,
        static: false,
      },
      {
        w: 10,
        h: 5,
        x: 0,
        y: 55,
        i: "18",
        moved: false,
        static: false,
      },
      {
        w: 10,
        h: 7,
        x: 0,
        y: 60,
        i: "19",
        moved: false,
        static: false,
      },
      {
        w: 10,
        h: 5,
        x: 0,
        y: 67,
        i: "20",
        moved: false,
        static: false,
      },
      {
        w: 4,
        h: 10,
        x: 0,
        y: 72,
        i: "21",
        moved: false,
        static: false,
      },
      {
        w: 6,
        h: 10,
        x: 4,
        y: 72,
        i: "22",
        moved: false,
        static: false,
      },
      {
        w: 10,
        h: 8,
        x: 0,
        y: 82,
        i: "23",
        moved: false,
        static: false,
      },
      {
        w: 10,
        h: 5,
        x: 0,
        y: 90,
        i: "24",
        moved: false,
        static: false,
      },
      {
        w: 10,
        h: 5,
        x: 0,
        y: 95,
        i: "25",
        moved: false,
        static: false,
      },
    ],
    lg: [
      {
        w: 4,
        h: 6,
        x: 8,
        y: 0,
        i: "1",
        moved: false,
        static: false,
      },
      {
        w: 2,
        h: 3,
        x: 0,
        y: 0,
        i: "2",
        moved: false,
        static: false,
      },
      {
        w: 2,
        h: 3,
        x: 2,
        y: 0,
        i: "3",
        moved: false,
        static: false,
      },
      {
        w: 2,
        h: 3,
        x: 0,
        y: 3,
        i: "4",
        moved: false,
        static: false,
      },
      {
        w: 2,
        h: 3,
        x: 2,
        y: 3,
        i: "5",
        moved: false,
        static: false,
      },
      {
        w: 4,
        h: 5,
        x: 0,
        y: 6,
        i: "6",
        moved: false,
        static: false,
      },
      {
        w: 4,
        h: 5,
        x: 4,
        y: 6,
        i: "7",
        moved: false,
        static: false,
      },
      {
        w: 4,
        h: 5,
        x: 8,
        y: 6,
        i: "8",
        moved: false,
        static: false,
      },
      {
        w: 4,
        h: 6,
        x: 4,
        y: 0,
        i: "9",
        moved: false,
        static: false,
      },
      {
        w: 3,
        h: 6,
        x: 4,
        y: 11,
        i: "10",
        moved: false,
        static: false,
      },
      {
        w: 4,
        h: 6,
        x: 0,
        y: 11,
        i: "11",
        moved: false,
        static: false,
      },
      {
        w: 5,
        h: 6,
        x: 7,
        y: 11,
        i: "12",
        moved: false,
        static: false,
      },
      {
        w: 5,
        h: 8,
        x: 7,
        y: 17,
        i: "13",
        moved: false,
        static: false,
      },
      {
        w: 7,
        h: 8,
        x: 0,
        y: 17,
        i: "14",
        moved: false,
        static: false,
      },
      {
        w: 6,
        h: 8,
        x: 6,
        y: 25,
        i: "15",
        moved: false,
        static: false,
      },
      {
        w: 6,
        h: 8,
        x: 0,
        y: 25,
        i: "16",
        moved: false,
        static: false,
      },
      {
        w: 6,
        h: 7,
        x: 6,
        y: 33,
        i: "17",
        moved: false,
        static: false,
      },
      {
        w: 6,
        h: 7,
        x: 0,
        y: 33,
        i: "18",
        moved: false,
        static: false,
      },
      {
        w: 6,
        h: 6,
        x: 6,
        y: 40,
        i: "19",
        moved: false,
        static: false,
      },
      {
        w: 6,
        h: 6,
        x: 0,
        y: 40,
        i: "20",
        moved: false,
        static: false,
      },
      {
        w: 4,
        h: 10,
        x: 8,
        y: 46,
        i: "21",
        moved: false,
        static: false,
      },
      {
        w: 8,
        h: 10,
        x: 0,
        y: 46,
        i: "22",
        moved: false,
        static: false,
      },
      {
        w: 12,
        h: 8,
        x: 0,
        y: 61,
        i: "23",
        moved: false,
        static: false,
      },
      {
        w: 6,
        h: 5,
        x: 0,
        y: 56,
        i: "24",
        moved: false,
        static: false,
      },
      {
        w: 6,
        h: 5,
        x: 6,
        y: 56,
        i: "25",
        moved: false,
        static: false,
      },
    ],
  },
  lstHowDashboardElement: [
    {
      key: "1",
      element: <HowDonationChartBar />,
      isVisible: true,
      isClosed: false,
      isCard: false,
      name: "Donations",
    },
    {
      key: "2",
      element: <TotalHowCard />,
      isVisible: true,
      isClosed: false,
      isDonationCard: true,
      name: "Total Holy Houses",
    },
    {
      key: "3",
      element: <HowDonationCard title="This Month's Donation" amount="3.4k" />,
      isVisible: true,
      isClosed: false,
      isDonationCard: true,
      name: "This Month's Donation",
    },
    {
      key: "4",
      element: <TotalHowDonorsCount />,
      isVisible: true,
      isClosed: false,
      isDonationCard: true,
      name: "Total Donors",
    },
    {
      key: "5",
      element: <HowDonationCard title="Total Donation" amount="23.4K" />,
      isVisible: true,
      isClosed: false,
      isDonationCard: true,
      name: "Total Donation",
    },
    {
      key: "6",
      element: <HowCurrentMonthLineChart />,
      isVisible: true,
      isClosed: false,
      isCard: false,
      name: "Current Month Donation's",
    },
    {
      key: "7",
      element: <HowCurrentMonthDailyRevenue />,
      isVisible: true,
      isClosed: false,
      isCard: true,
      overFlowNone: true,
      name: "Current Month Daily Revenue",
    },
    {
      key: "8",
      element: <HowMonthlyRevenueReport />,
      isVisible: true,
      isClosed: false,
      isCard: true,
      name: "Monthly Revenue Report",
    },
    {
      key: "9",
      element: <HowDonorRevenueReport />,
      isVisible: true,
      isClosed: false,
      isCard: true,
      name: "Yearly Revenue Report",
    },
    {
      key: "10",
      element: <HowDonorRevenue />,
      isVisible: true,
      isClosed: false,
      isCard: true,
      name: "Donor Revenue",
    },
    {
      key: "11",
      element: <HowHouseRevenue />,
      isVisible: true,
      isClosed: false,
      isCard: true,
      name: "House Revenue",
    },
    {
      key: "12",
      element: <HowDetailedReport />,
      isVisible: true,
      isClosed: false,
      isCard: false,
      name: "Detailed reports",
    },
    {
      key: "13",
      element: <AddUpdateEvent />,
      isVisible: true,
      isClosed: false,
      isCard: false,
      name: "Create Event",
    },
    {
      key: "14",
      element: <AllEventList />,
      isVisible: true,
      isClosed: false,
      isCard: false,
      name: "All Events List",
    },
    {
      key: "15",
      element: <ChildHowPage />,
      isVisible: true,
      isClosed: false,
      isCard: false,
      name: "Child How",
    },
    {
      key: "16",
      element: <HowInvitationPage />,
      isVisible: true,
      isClosed: false,
      isCard: false,
      name: "Child How Invitations",
    },
    {
      key: "17",
      element: <SuspendedHowPage />,
      isVisible: true,
      isClosed: false,
      isCard: false,
      name: "Suspended Hows",
    },
    {
      key: "18",
      element: <HowDonorsPage />,
      isCard: false,
      isVisible: true,
      isClosed: false,
      name: "Donors",
    },
    {
      key: "19",
      element: <HowDonorInvitation />,
      isVisible: true,
      isClosed: false,
      isCard: false,
      name: "Donor Invitations",
    },
    {
      key: "20",
      element: <HowSuspendedDonor />,
      isVisible: true,
      isClosed: false,
      isCard: false,
      name: "Suspended Donor",
    },
    {
      key: "21",
      element: <HowSetupPayment />,
      isCard: false,
      isVisible: true,
      isClosed: false,
      name: "Setup  Payment",
    },
    {
      key: "22",
      element: <HowHelp />,
      isCard: false,
      isVisible: true,
      isClosed: false,
      name: "Help",
    },
    {
      key: "23",
      element: <HowTermPolicy />,
      isCard: false,
      isVisible: true,
      isClosed: false,
      name: "Term Policy",
    },
    {
      key: "24",
      element: <HowRevenueDonor />,
      isCard: false,
      isVisible: true,
      isClosed: false,
      name: "Revenue From Donor",
    },
    {
      key: "25",
      element: <RevenueFromChildHolyHouses />,
      isVisible: true,
      isClosed: false,
      isCard: false,
      name: "Revenue From Child Holy Houses",
    },
  ],
  removedDashboardLayouts: [],
});

export const setIsCardLayoutChanged = (isChanged) => {
  HowDashboardLayoutStore.update((s) => {
    s.isCardLayoutsChanged = isChanged;
  });
};

export const handleMinimize = (cardID) => {
  HowDashboardLayoutStore.update((s) => {
    s.lstHowDashboardElement = s.lstHowDashboardElement.map((item) => {
      if (parseInt(item.key) === parseInt(cardID)) {
        return {
          ...item,
          isVisible: !item.isVisible,
        };
      }
      return item;
    });
  });

  setIsCardLayoutChanged(true);
};

export const handleCardClose = (cardID) => {
  HowDashboardLayoutStore.update((s) => {
    s.lstHowDashboardElement = s.lstHowDashboardElement.map((item) => {
      if (parseInt(item.key) === parseInt(cardID)) {
        return {
          ...item,
          isClosed: !item.isClosed,
        };
      } else {
        return item;
      }
    });
  });
  getDashboardLayoutElement();
  setIsCardLayoutChanged(true);
};


export const getDashboardLayoutElement = () =>
  HowDashboardLayoutStore.getRawState().lstHowDashboardElement;
