import { Box, InputLabel } from "@mui/material";
import { CommonStore } from "services/store/CommonStore/PullStore";

export default function GetRequiredLabel(props) {
  const isWhiteTheme = CommonStore.useState((s) => s.themeMode);
  return (
    <Box
      style={{
        paddingLeft: "8px",
      }}
    >
      {props.isRequired ? (
        <InputLabel
          sx={{
            fontWeight: "bold",
          }}
        >
          {props.name} <span style={{ color: "red" }}>*</span>
        </InputLabel>
      ) : (
        <InputLabel
          sx={{
            fontWeight: "bold",
          }}
        >
          {props.name}
        </InputLabel>
      )}
    </Box>
  );
}
