import { Card } from "@mui/material";
import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";
import faker from "faker";
import { useEffect } from "react";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  indexAxis: "y",
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
      text: "Dontaions By State",
    },
  },
};

const labels = [
  "NY",
  "OH",
  "ID",
  "TX",
  "FL",
  "AK",
  "LA",
  "NJ",
  "AZ",
  "MN",
  "NC",
  "AL",
  "MA",
  "MI",
  "CO",
  "CT",
  "UT",
  "SC",
];

export const data = {
  labels,
  datasets: [
    {
      label: "Amount",
      data: labels.map(() => faker.datatype.number({ min: 100, max: 1000 })),
      //   backgroundColor: 'rgba(155, 99, 132, 0.5)',
      backgroundColor: "#f6bd60",
      borderColor: "rgba(155, 99, 132, 0.5)",
    },
  ],
};

export function AdminDonationByState() {
  useEffect(() => {
    console.log("merge test: delete once passed");
  }, []);

  return <Bar options={options} data={data} />;
}
