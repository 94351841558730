import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import utils from "services/Utils";

const HowDetailedReport = () => {
  return (
    <Box py={2} pl={2} pr={1}>
      <Typography variant="h5" sx={{ color: "inherit !important" }}>
        Detailed reports
      </Typography>
      <Box
        className="carousel slide detailed-report-carousel position-static pt-2"
        data-ride="carousel"
      >
        <Box className="carousel-inner">
          <Box className="carousel-item active">
            <Box className="row">
              <Box className="col-md-12 col-xl-4 d-flex flex-column justify-content-start">
                <Box className="ml-xl-4 mt-3">
                  <Typography variant="h4" component={"h1"} color="primary">
                    {utils.prettyPrice(34034)}
                  </Typography>
                  <Typography variant="h5" component={"h3"} color="primary">
                    North America
                  </Typography>
                  <Typography variant="body1" sx={{ mt: 2 }}>
                    The total number of donation within the date range. It is
                    the period time a user is actively engaged with your
                    website, page or app, etc
                  </Typography>
                </Box>
              </Box>
              <Box className="col-md-12 col-xl-8">
                <Box className="row">
                  <Box className="col-md-12 border-right">
                    <Box className="table-responsive mb-3 mb-md-0 mt-3">
                      <Table className="table table-borderless report-table">
                        <TableBody>
                          <TableRow>
                            <TableCell>
                              <Typography
                                variant="body1"
                                sx={{ color: "inherit !important" }}
                              >
                                Illinois
                              </Typography>
                            </TableCell>
                            <TableCell className="w-100 px-0">
                              <Box className="progress progress-md mx-4">
                                <Box
                                  className="progress-bar"
                                  sx={{
                                    backgroundColor: "#9999cd",
                                  }}
                                  role="progressbar"
                                  style={{ width: "70%" }}
                                  aria-valuenow={30}
                                  aria-valuemin={0}
                                  aria-valuemax={100}
                                />
                              </Box>
                            </TableCell>
                            <TableCell>
                              <Typography variant="body1">713</Typography>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>
                              <Typography
                                variant="body1"
                                sx={{ color: "inherit !important" }}
                              >
                                Washington
                              </Typography>
                            </TableCell>
                            <TableCell className="w-100 px-0">
                              <Box className="progress progress-md mx-4">
                                <Box
                                  sx={{
                                    backgroundColor: "#3398cc",
                                  }}
                                  className="progress-bar"
                                  role="progressbar"
                                  style={{ width: "30%" }}
                                  aria-valuenow={30}
                                  aria-valuemin={0}
                                  aria-valuemax={100}
                                />
                              </Box>
                            </TableCell>
                            <TableCell>
                              <Typography variant="body1">583</Typography>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>
                              <Typography
                                variant="body1"
                                sx={{ color: "inherit !important" }}
                              >
                                Mississippi
                              </Typography>
                            </TableCell>
                            <TableCell className="w-100 px-0">
                              <Box className="progress progress-md mx-4">
                                <Box
                                  className="progress-bar"
                                  sx={{
                                    backgroundColor: "#333367",
                                  }}
                                  role="progressbar"
                                  style={{ width: "95%" }}
                                  aria-valuenow={95}
                                  aria-valuemin={0}
                                  aria-valuemax={100}
                                />
                              </Box>
                            </TableCell>
                            <TableCell>
                              <Typography variant="body1">985</Typography>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>
                              <Typography
                                variant="body1"
                                sx={{ color: "inherit !important" }}
                              >
                                California
                              </Typography>
                            </TableCell>
                            <TableCell className="w-100 px-0">
                              <Box className="progress progress-md mx-4">
                                <Box
                                  className="progress-bar"
                                  sx={{
                                    backgroundColor: "#f04770",
                                  }}
                                  role="progressbar"
                                  style={{ width: "60%" }}
                                  aria-valuenow={60}
                                  aria-valuemin={0}
                                  aria-valuemax={100}
                                />
                              </Box>
                            </TableCell>
                            <TableCell>
                              <Typography variant="body1">664</Typography>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>
                              <Typography
                                variant="body1"
                                sx={{ color: "inherit !important" }}
                              >
                                Maryland
                              </Typography>
                            </TableCell>
                            <TableCell className="w-100 px-0">
                              <Box className="progress progress-md mx-4">
                                <Box
                                  className="progress-bar bg-primary"
                                  role="progressbar"
                                  style={{ width: "40%" }}
                                  aria-valuenow={40}
                                  aria-valuemin={0}
                                  aria-valuemax={100}
                                />
                              </Box>
                            </TableCell>
                            <TableCell>
                              <Typography variant="body1">560</Typography>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>
                              <Typography
                                variant="body1"
                                sx={{ color: "inherit !important" }}
                              >
                                Alaska
                              </Typography>
                            </TableCell>
                            <TableCell className="w-100 px-0">
                              <Box className="progress progress-md mx-4">
                                <Box
                                  className="progress-bar bg-danger"
                                  role="progressbar"
                                  style={{ width: "75%" }}
                                  aria-valuenow={75}
                                  aria-valuemin={0}
                                  aria-valuemax={100}
                                />
                              </Box>
                            </TableCell>
                            <TableCell>
                              <Typography variant="body1">592</Typography>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default HowDetailedReport;
