import { Box, useMediaQuery } from "@mui/material";
import AuthGuard from "Common/AuthGuard/AuthGuard";
import MainLayouts from "Common/Layouts/MainLayouts";
import authService from "services/http-service/AuthService";
import mainTheme from "styles/theme";
import AdminContentPage from "../AdminContent/AdminContentPage";

export default function AdminDashboardPage() {
  const downSmall = useMediaQuery(mainTheme.breakpoints.down("sm"));
  const userRole = authService.getUserRole();
  if (userRole === "Admin") {
    document.title = "Admin";
  }
  return (
    <AuthGuard>
      <MainLayouts>
        <Box pl={downSmall ? 0 : 4} pr={downSmall ? 0 : 4}>
          <AdminContentPage />
        </Box>
      </MainLayouts>
    </AuthGuard>
  );
}
