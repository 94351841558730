import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";

const AdminWrapper = ({ children }) => {
  // const jwt = localStorage.getItem('jwt');
  const [redirect, setRedirect] = useState(false);
  const [url, setURL] = useState("/");

  const configuration = (role) => {
    if (role) {
      if (role === "Donor") {
        setURL("/donordashboard");
      }

      if (role !== "Admin") {
        setRedirect(true);
      }
    }
  };
  useEffect(() => {
    const { role } = JSON.parse(localStorage.getItem("user"));
    configuration(role);
  }, []);
  return (
    <React.Fragment>
      {redirect && <Navigate to={url} />}
      <Box>{children}</Box>
    </React.Fragment>
  );
};

export default AdminWrapper;
