import { Box, Button, Typography } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import FormHelperText from "@mui/material/FormHelperText";
import Switch from "@mui/material/Switch";
import CardTable from "Common/DataTable/CardTable";
import { useState } from "react";

const DonorAccountSetting = (props) => {
  const [accountSet, setAccountSet] = useState({});
  const Data = JSON.parse(localStorage.getItem("user"));
  const handleChange = (event) => {
    setAccountSet({
      ...accountSet,
      [event.target.name]: event.target.checked,
    });
  };

  const onSubmit = async () => {
    const requestData = {
      user_id: Data.id,
      value: JSON.stringify(accountSet),
    };
    // const resp = await dispatch(updateNotification(requestData));
  };

  return (
    <>
      <>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              py: 4,
            }}
          >
            <Typography variant="h4">Account Setting</Typography>
          </Box>
          <FormControl>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={accountSet?.notification}
                    onChange={handleChange}
                    name="notification"
                  />
                }
                label=<h5>Notification</h5>
              />
              <FormHelperText>
                <span>Receive HolyPennies notifications via email.</span>
              </FormHelperText>
              <hr />
              <FormControlLabel
                control={
                  <Switch
                    checked={accountSet?.email}
                    onChange={handleChange}
                    name="email"
                  />
                }
                label=<h5>Email</h5>
              />
              <FormHelperText>
                <span>Receive email regarding our next updates</span>
              </FormHelperText>
              <hr />
              <FormControlLabel
                control={
                  <Switch
                    checked={accountSet?.autoDebit}
                    onChange={handleChange}
                    name="autoDebit"
                  />
                }
                label=<h5>Auto Debit</h5>
              />
              <FormHelperText>
                <span>Stop your transaction tracking</span>
              </FormHelperText>
              <hr />
              <FormControlLabel
                control={
                  <Switch
                    checked={accountSet?.subscribe}
                    onChange={handleChange}
                    name="subscribe"
                  />
                }
                label=<h5>Subscribe</h5>
              />
              <FormHelperText>
                <span>Subscribe to our news letter</span>
              </FormHelperText>
              <hr />
            </FormGroup>
            <Button
              className="btn btn-primary "
              onClick={onSubmit}
              type="submit"
              color="primary"
              variant="contained"
            >
              Submit
            </Button>
          </FormControl>
        </Box>
      </>
    </>
  );
};

export default DonorAccountSetting;
