import { Delete } from "@mui/icons-material";
import { useQuery } from "@tanstack/react-query";
import DataTable from "Common/DataTable/DataTable";
import { format } from "date-fns";
import { toast } from "react-toastify";
import utils from "services/Utils";
import apiService from "services/http-service/ApiService";

export default function AdminDonorInvitation() {
  const q = useQuery(["getAdminDonorInvitations"], async () => {
    return await apiService.getAdminDonorInvitations();
  });

  const deleteDonorInvitationRecord = async (inviteID) => {
    try {
      const isValid = await utils.showWarning("Do you want to delete?");
      if (isValid.isConfirmed) {
        const res = await apiService.deleteDonorInvitationRecord(inviteID);
        if (!res.success) {
          toast.success(res.message);
          q.refetch();
          return null;
        }
        
        throw new Error();
      }
    } catch (ex) {
      toast.error(ex.message);
    }
  };

  return (
    <DataTable
      title="Donor Invitations"
      isLoading={q.isLoading}
      isError={q.isError}
      errorMessage={
        q.isError
          ? (q.error && q.error.error && q.error.error.message) ||
            "Error occurred please contact admin!"
          : null
      }
      columns={[
        {
          title: "Created Date",
          field: "createdAt",
          render: (rowData) => {
            return format(new Date(rowData.createdAt), "dd/MM/yyyy");
          },
        },
        {
          title: "Name",
          field: "firstname",
        },
        { title: "Invited By", field: "invitedby.role" },
        {
          title: "Updated Date",
          field: "updatedAt",
          render: (rowData) => {
            return format(new Date(rowData.updatedAt), "dd/MM/yyyy");
          },
        },
        {
          title: "Expires Date",
          field: "expireIn",
          render: (rowData) => {
            return utils.getDDMMYYYY(rowData.expireIn);
          },
        },
      ]}
      data={(q.data && q.data.data && q.data.data.rows) || []}
      actions={[
        {
          icon: () => {
            return <Delete color="error" />;
          },
          tooltip: "Delete donor invitation",
          onClick: (e, rowData) => {
            deleteDonorInvitationRecord(rowData.id);
          },
        },
      ]}
    />
  );
}
